import React, { useRef } from "react";
import {
  Typography,
  Container,
  IconButton,
  Card,
  Box,
  Grid,
  Stack,
  Paper,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
// components
import Page from "../../components/Page.js";
import { grey } from "@mui/material/colors";
import ConditionalRender from "../../components/ConditionalRender.js";
import useUser from "../../hooks/useUser";
import { useDispatch } from "react-redux";
import { uploadProfileThunk } from "../../store/slices/user/user.slice.js";
import AddressLoader from "../../components/Skeletons/AddressLoader";
import Address2Loader from "../../components/Skeletons/Address2Loader";
import { useStyles } from "./BusinessDetails/MyProfile.theme";

export default function PersonalDetails({ handleprofileEditMode }) {
  const classes = useStyles();
  const user = useUser();
  const dispatch = useDispatch();

  const inputRef = useRef();

  function handleFileChange(e) {
    var formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_type", "profile");
    console.log("formData", formData);
    dispatch(uploadProfileThunk(formData));
  }

  return (
    <Page title="My Profile">
      <input
        type="file"
        style={{ display: "none" }}
        ref={inputRef}
        onChange={handleFileChange}
      />
      <Box>
        <Grid container>
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="flex-start"
            spacing={2}
            mb={2}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "30px",
                marginBottom: "0.5rem",
                width: "75%",
              }}
            >
              <Paper
                sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <Typography variant="body1" sx={{ fontWeight: "600" }}>
                  Full Name :{" "}
                  <span style={{ fontWeight: "500" }}> {user.fullName}</span>
                </Typography>

                <Typography variant="body1" sx={{ fontWeight: "600" }}>
                  Email :{" "}
                  <span style={{ fontWeight: "500" }}>{user.email}</span>
                </Typography>

                <Typography variant="body1" sx={{ fontWeight: "600" }}>
                  Phone :{" "}
                  <span style={{ fontWeight: "500" }}>
                    {user.mobile ? user.mobile : "-"}
                  </span>
                </Typography>

                <Typography variant="body1" sx={{ fontWeight: "600" }}>
                  Designation :{" "}
                  <span style={{ fontWeight: "500" }}>
                    {user.designation ? user.designation : "-"}
                  </span>
                </Typography>
              </Paper>
              <Paper sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="body1" sx={{ fontWeight: "600" }}>
                  Personal Address
                </Typography>
                <Typography variant="body1">
                  {user.address ? user.address : "-"}
                </Typography>
              </Paper>
            </div>
            <div>
              {/* <Box sx={{ textAlign: "center" }}>
                <IconButton
                  aria-label="upload picture"
                  onClick={() => handleprofileEditMode(true)}
                  sx={{
                    backgroundColor: "#F4F5F8",
                    borderRadius: "1rem",
                    fontSize: "0.9rem",
                  }}
                >
                  <ModeEditOutlinedIcon
                    sx={{
                      color: "#202B36",
                      fontSize: "0.9rem",
                      marginRight: "10px",
                    }}
                  />
                  Edit profile
                </IconButton>
              </Box> */}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <IconButton
                  color="primary"
                  variant="outlined"
                  aria-label="upload picture"
                  onClick={() => handleprofileEditMode(true)}
                  sx={{ border: "1px solid #757575" }}
                >
                  <ModeEditOutlinedIcon
                    fontSize="small"
                    sx={{ color: grey[600] }}
                  />
                </IconButton>
              </Box>
            </div>
          </Stack>
        </Grid>
      </Box>
    </Page>
  );
}
