import React, { useMemo, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { v4 as uuidv4 } from "uuid";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";

import { useStyles } from "../Section.theme";
import {
  Button,
  Card,
  Divider,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Badge,
} from "@mui/material";
import { chatterType } from "../../../../utils/enum";
import "react-datepicker/dist/react-datepicker.css";
import { Box } from "@mui/system";
import Scrollbar from "../../../Scrollbar";
import { getFormattedTime } from "../../../../utils/time.util";
import CustomDrawer from "../Drawer";

function Section({
  rows,
  rowIds,
  columnIds,
  columns,
  sectionName,
  sectionIdx,
}) {
  const [showDrawer, setShowDrawer] = useState(false);
  const [timelineState, setTimelineState] = useState(null);

  function handleRowClick(rowId) {
    setTimelineState({
      sectionIdx,
      rowIdx: rowId,
    });
    setShowDrawer(true);
  }

  const taskColumnId = useMemo(() => {
    let colId;
    Object.keys(columns).forEach((key) => {
      if (colId) return;
      if (columns[key].key === "task") {
        colId = key;
      }
    });
    return colId;
  }, []);

  return (
    <>
      {timelineState && (
        <CustomDrawer
          showDrawer={showDrawer}
          setShowDrawer={() => {
            setShowDrawer(false);
            setTimelineState(null);
          }}
          sectionIdx={timelineState?.sectionIdx}
          rowIdx={timelineState?.rowIdx}
        />
      )}
      <div style={{ marginBottom: "100px" }}>
        <Box
          sx={{
            backgroundColor: "#f1f4f9",
            backgroundImage:
              "linear-gradient(270deg, #ffffff 10%, #f1f4f9 74%)",
            borderLeft: "5px solid #b8c6d3",
            padding: "5px 12px 0 12px",
            margin: "30px 0px",
            width: "300px",
          }}
        >
          <Typography variant="h5" gutterBottom>
            {sectionName}
          </Typography>
        </Box>
        <Card>
          <Scrollbar>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead sx={{ backgroundColor: "rgb(243, 246, 248)" }}>
                  <TableRow>
                    {columnIds.map((colIdx) => (
                      <TableCell
                        key={colIdx}
                        align={"left"}
                        style={{ top: 57, minWidth: 100 }}
                      >
                        {columns[colIdx]?.value}
                      </TableCell>
                    ))}
                    <TableCell
                      align={"left"}
                      style={{ top: 57, minWidth: 100 }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowIds.map((rowId, idx) => (
                    <TableRow
                      tabIndex={-1}
                      role="checkbox"
                      sx={{ cursor: "pointer" }}
                    >
                      {columnIds.map((colId) => (
                        <TableCell align="left">
                          {rows[rowId][colId].type === chatterType.date &&
                            getFormattedTime(
                              rows[rowId][colId]?.value,
                              "dd/MM/yyyy"
                            )}
                          {rows[rowId][colId].type === chatterType.text &&
                            rows[rowId][colId]?.value}

                          {rows[rowId][colId].type === chatterType.status &&
                            rows[rowId][colId]?.value}
                        </TableCell>
                      ))}
                      <TableCell
                        align="left"
                        onClick={() => handleRowClick(rowId)}
                      >
                        <Badge
                          badgeContent={
                            rows[rowId][taskColumnId]?.comments_count
                          }
                          color="primary"
                        >
                          <CommentOutlinedIcon />
                        </Badge>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </div>
    </>
  );
}

export default Section;
