import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createSupportTicketHandler,
  createGlobalSupportTicketCommentHandler,
  fetchAllSupportTicketsHandler,
  fetchAllGlobalTicketsDataByTicketIdHanldler,
  updateTicketStatusHandler,
  createReportedIssue,
} from "./support.thunk";

const INIT_STATE = {
  tickets: [],
  selectedTicket: null,
  comments: {
    comments_data: [],
  },
};

export const addReportIssueThunk = createAsyncThunk(
  "createReportIssue",
  createReportedIssue
);

export const fetchAllSupportTicketsThunk = createAsyncThunk(
  "fetchAllSupportTickets",
  fetchAllSupportTicketsHandler
);

export const createSupportTicketThunk = createAsyncThunk(
  "createSupportTicket",
  createSupportTicketHandler
);

export const createGlobalSupportTicketCommentThunk = createAsyncThunk(
  "createSupportTicketComment",
  createGlobalSupportTicketCommentHandler
);

export const fetchAllGlobalTicketsDataByTicketIdThunk = createAsyncThunk(
  "fetchAllGlobalTicketsDataByTicketId",
  fetchAllGlobalTicketsDataByTicketIdHanldler
);

export const updateTicketStatusThunkNew = createAsyncThunk(
  "updateTicketStatus",
  updateTicketStatusHandler
);

const supportSlice = createSlice({
  name: "support",
  initialState: INIT_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createSupportTicketThunk.fulfilled, (state, action) => {
        state.tickets.unshift(action.payload);
        return state;
      })
      .addCase(fetchAllSupportTicketsThunk.fulfilled, (state, action) => {
        state.tickets = action.payload;
      })
      .addCase(
        fetchAllGlobalTicketsDataByTicketIdThunk.fulfilled,
        (state, action) => {
          state.comments = action.payload;
          return state;
        }
      )
      .addCase(
        createGlobalSupportTicketCommentThunk.fulfilled,
        (state, action) => {
          state.comments.comments_data.push(action.payload);
          return state;
        }
      )
      .addCase(addReportIssueThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addReportIssueThunk.fulfilled, (state, action) => {
        debugger;
        state.isLoading = false;
        state.tickets.push(action.payload);
        return state;
      })
      .addCase(addReportIssueThunk.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default supportSlice.reducer;
