import React from "react";
import Chat from "./Chat";
import MobileChatList from "./MobileChatList";
import MobileChatView from "./MobileChatView";

export default function MainChat() {
  return (
    <div>
      <Chat />
      <MobileChatList />
      <MobileChatView />
    </div>
  );
}
