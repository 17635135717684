import _ from "lodash";

export const getVirtualElement = function (args) {
  var { name, handleChange, handleBlur, type } = args;

  function getEvent() {
    let event = {};
    event.target = {
      type,
      name,
    };
    return event;
  }

  function setValue(value) {
    const event = getEvent();
    event.target.value = value;
    handleChange(event);
  }

  function blurElement() {
    const event = getEvent();
    if (handleBlur) {
      handleBlur(event);
    }
  }

  function resetValue() {
    const event = getEvent();
    event.target.value = "";
    handleChange(event, "");
  }

  return {
    setValue,
    blurElement,
    resetValue,
  };
};
export const debounce = (time) => {
  return _.debounce((func) => {
    func();
  }, time);
};

export const formattedAddress = (
  address_line1,
  address_line2,
  city,
  country,
  state,
  zip_code
) => {
  let address = "";
  if (address_line1) {
    address += address_line1;
  }
  if (address_line2) {
    address += ", " + address_line2;
  }
  if (city) {
    address += " " + city;
  }
  if (state) {
    address += ", " + state;
  }
  if (country) {
    address += " " + country;
  }
  if (zip_code) {
    address += ", " + zip_code;
  }
  return address;
};

export const createQueryString = (data) => {
  return "?" + new URLSearchParams(data).toString();
};

export const PERMISSION = {
  PROJECT_VIEW: "PROJECT_VIEW",
  PROJECT_DELETE: "PROJECT_DELETE",
  PROFILE_VIEW: "PROFILE_VIEW",
  PROFILE_EDIT: "PROFILE_EDIT",
  BUSINESS_PROFILE_VIEW: "BUSINESS_PROFILE_VIEW",
  BUSINESS_PROFILE_EDIT: "BUSINESS_PROFILE_EDIT",
  BUSINESS_ADDRESS_VIEW: "BUSINESS_ADDRESS_VIEW",
  BUSINESS_ADDRESS_EDIT: "BUSINESS_ADDRESS_EDIT",
  CHANGE_EMAIL: "CHANGE_EMAIL",
  CHANGE_MOBILE: "CHANGE_MOBILE",
  USERS_VIEW: "USERS_VIEW",
  USER_CREATE: "USER_CREATE",
  USER_EDIT: "USER_EDIT",
  USER_DELETE: "USER_DELETE",
  ROLE_CREATE: "ROLE_CREATE",
  ROLE_EDIT: "ROLE_EDIT",
  ROLE_DELETE: "ROLE_DELETE",
  ROLE_VIEW: "ROLE_VIEW",
  GROUP_CREATE: "GROUP_CREATE",
  GROUP_EDIT: "GROUP_EDIT",
  GROUP_DELETE: "GROUP_DELETE",
  GROUP_VIEW: "GROUP_VIEW",
  OVERVIEW_VIEW: "OVERVIEW_VIEW",
  PROJECT_ATTACHMENT_ADD: "PROJECT_ATTACHMENT_ADD",
  PROJECT_ATTACHMENT_DELETE: "PROJECT_ATTACHMENT_DELETE",
  PROPOSAL_VIEW: "PROPOSAL_VIEW",
  PROPOSAL_DOWNLOAD: "PROPOSAL_DOWNLOAD",
  PROJECT_TABLE_VIEW: "PROJECT_TABLE_VIEW",
  SCOPE_VIEW: "SCOPE_VIEW",
  SCOPE_DOWNLOAD: "SCOPE_DOWNLOAD",
  CHART_VIEW: "CHART_VIEW",
  CHART_EDIT: "CHART_EDIT",
  KANBAN_VIEW: "KANBAN_VIEW",
  KANBAN_EDIT: "KANBAN_EDIT",
  SUPPORT_VIEW: "SUPPORT_VIEW",
  SUPPORT_ADD: "SUPPORT_ADD",
  SUPPORT_EDIT: "SUPPORT_EDIT",
  SUPPORT_DELETE: "SUPPORT_DELETE",
  RATING_ADD: "RATING_ADD",
  WALLET_VIEW: "WALLET_VIEW",
  CONTACT_SUPPORT_VIEW: "CONTACT_SUPPORT_VIEW",
  CONTACT_SUPPORT_CREATE: "CONTACT_SUPPORT_CREATE",
  CONTACT_SUPPORT_EDIT: "CONTACT_SUPPORT_EDIT",
  CONTACT_SUPPORT_DELETE: "CONTACT_SUPPORT_DELETE",
  NOTIFICATION_VIEW: "NOTIFICATION_VIEW",
};

export const getStartEndDateForProject = (tasks, projectId) => {
  const projectTasks = tasks.filter((t) => t.project === projectId);
  let start = projectTasks[0].start;
  let end = projectTasks[0].end;

  for (let i = 0; i < projectTasks.length; i++) {
    const task = projectTasks[i];
    if (start.getTime() > task.start.getTime()) {
      start = task.start;
    }
    if (end.getTime() < task.end.getTime()) {
      end = task.end;
    }
  }
  return [start, end];
};

export const getGanttChartData = (data) => {
  return data
    .map((section) => {
      const {
        id,
        sectionHeaderValues = [],
        sectionMatrix,
        headerIds,
      } = section;
      if (!sectionHeaderValues[1]?.value || !sectionHeaderValues[2]?.value)
        return undefined;
      const currentGanttSection = [
        {
          id: id,
          name: sectionHeaderValues[0].value,
          start: new Date(sectionHeaderValues[1].value),
          end: new Date(sectionHeaderValues[2].value),
          type: "project",
        },
      ];
      const childSection = sectionMatrix.map((row, idx) => {
        if (!row[1]?.value || !row[2]?.value) return undefined;
        return {
          id: headerIds[idx],
          name: row[0].value,
          start: new Date(row[1].value),
          end: new Date(row[2].value),
          project: id,
        };
      });
      return currentGanttSection.concat(childSection.slice(1).filter(Boolean));
    })
    .filter(Boolean);
};
