import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  badge_blue: {
    color: "#15ac58",
    background: "rgb(239 248 241)",
    borderColor: "transparent",
    display: "inline-block",
    fontSize: "75%",
    fontWeight: "600",
    lineHeight: 1,
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "baseline",
  },

  badge: {
    lineHeight: 1.7,
    padding: "0.25em 0.7em",
    borderRadius: "10rem",
  },
  attachFile: {
    border: "1px solid #edf2f9",
    borderRadius: "4px",
    padding: "10px",
    marginRight: "15px",
    marginBottom: "15px",
    display: "inline-block",
    width: "270px",
  },

  attachMedia: {
    display: "flex",
    alignItems: "center",
  },

  attachIcon: {
    textAlign: "center",
    width: "40px",
    height: "40px",
    lineHeight: "50px",
    color: "#00c9a7",
    background: "rgba(0,201,167,0.1)",
  },

  attachFileHeading: {
    width: "130px",
    height: "25px",
    // overflow: "hidden",
    color: "#212B36",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    marginRight: "10px",
  },
  closeFile: {
    position: "absolute",
    top: "-20px",
    right: "-75px",
    color: "#d4dadf",
    cursor: "pointer",
    height: "16px",
    background: "#fff",
  },
  AvtarImg: {
    position: " absolute",
    right: "-60px",
    top: 0,
    width: "30px",
    height: "30px",
    borderRadius: "25px",
  },

  svgIconSize: {
    fontSize: "14px",
  },
  "@media (max-width:900px)": {
    dateInMobile: {
      display: "block",
    },
  },
});
