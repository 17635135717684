import React, { useMemo } from "react";
import Avatar from "@mui/material/Avatar";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
// material
import {
  Typography,
  Container,
  Box,
  Card,
  Stack,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  Chip,
} from "@mui/material";
import { css } from "@emotion/css";

// components
import Page from "../../../components/Page";
import Scrollbar from "../../../components/Scrollbar";
import { UserMoreMenu } from "../../../components/_dashboard/user";
//
import { useDispatch, useSelector } from "react-redux";
import ListSearchbar from "../../../components/Common/ListSearchbar";
import Modal from "./../../../components/Modal/Modal";
import TicketModal from "./../../../components/Modal/TicketModal";
import {
  addReportIssueThunk,
  getReportedIssueThunk,
} from "../../../store/slices/project/project.slice";
import ListHead from "./../../../components/Common/ListHead";
import SearchNotFound from "../../../components/NotFound/SearchNotFound";
import { capitalCase } from "../../../utils/change-case";
import { DateTime } from "luxon";
import useSearch from "../../../hooks/useSearch";

const TABLE_HEAD = [
  { id: "title", label: "Title", alignRight: false },
  { id: "state", label: "Status", alignRight: false },
  { id: "createdOn", label: "Created On", alignRight: false },
];

export default function Ticket() {
  const params = useParams();
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReportedIssueThunk(params.id));
  }, [dispatch, params.id]);

  const tickets = useSelector(({ project }) => project.selectedProject.tickets);

  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const [search, setSearch] = useState("");
  const { handleTextChange } = useSearch(tickets, "title");

  function getInitials(project_name) {
    return capitalCase(project_name.substring(0, 2));
  }

  function handleSubmit(data) {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("description", data.description);
    if (data.files.length) {
      data.files.map((file) => {
        formData.append("file", file);
      });
    }
    formData.append("project_id", params.id);
    dispatch(addReportIssueThunk(formData)).unwrap().then(handleClose);
  }

  const serachedTickets = useMemo(() => {
    if (!search) return tickets;
    return handleTextChange(search);
  }, [search, tickets]);

  return (
    <div>
      <Container>
        <Box mt={5}>
          <Card>
            <Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignContent="center"
                alignItems="center"
              >
                <Paper>
                  <ListSearchbar
                    placeholder="Search tickets"
                    handleSearchChange={(e) => setSearch(e.target.value)}
                  />
                </Paper>
                <Paper>
                  <Button onClick={handleOpen} sx={{ mr: "1.75rem" }}>
                    <AddIcon />
                    Raise Ticket
                  </Button>
                </Paper>
              </Stack>
            </Box>

            {serachedTickets.length > 0 ? (
              <Card sx={{ boxShadow: "none", borderRadius: "0px!important" }}>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <ListHead
                        headLabel={TABLE_HEAD}
                        rowCount={tickets?.length}
                        styles={{ backgroundColor: "rgb(243, 246, 248)" }}
                      />

                      <TableBody>
                        {serachedTickets?.map((row) => {
                          const { id, ticket_id, title, state, created_at } =
                            row;

                          return (
                            <TableRow key={id} tabIndex={-1} role="checkbox">
                              <TableCell align="left">
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  {/* <Avatar
                                    sx={{
                                      fontSize: ".8rem",
                                      fontWeight: "600",
                                      backgroundColor: "#f3f6f8",
                                      color: "#637281",
                                    }}
                                  >
                                    {getInitials(title)}
                                  </Avatar> */}
                                  <Typography
                                    variant="body2"
                                    noWrap
                                    sx={{ fontWeight: "700" }}
                                  >
                                    <Link
                                      to={`${id}`}
                                      style={{
                                        textDecoration: "none",
                                        color: "black",
                                      }}
                                    >
                                      {capitalCase(title)}
                                    </Link>
                                  </Typography>
                                </Stack>
                              </TableCell>
                              {/* <TableCell align="left">
                                  {description}
                                </TableCell> */}
                              <TableCell
                                align="left"
                                sx={{ paddingLeft: "8px!important" }}
                              >
                                <Chip
                                  size="small"
                                  variant="outlined"
                                  label={state}
                                  sx={{
                                    fontSize: "12px",
                                    border: "none",
                                    textTransform: "capitalize",
                                    paddingLeft: "0px!important",
                                    color:
                                      state === "open" ? "#11A54A" : "#909090",
                                    // backgroundColor:
                                    //   state === "open"
                                    //     ? "#eff8f1"
                                    //     : "#fdd8de",
                                  }}
                                />
                              </TableCell>

                              <TableCell align="left">
                                {DateTime.fromISO(created_at).toFormat("DD")}
                              </TableCell>

                              {/* <TableCell>
                                    <Box sx={{ marginLeft: "-70px" }}>
                                      <UserMoreMenu />
                                    </Box>
                                  </TableCell> */}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
              </Card>
            ) : (
              <SearchNotFound
                src="/static/No-found/no_tickets.png"
                subtitle="No tickets raise yet."
                title=" "
              />
            )}
          </Card>
        </Box>
      </Container>
      <Modal
        isOpen={showModal}
        closeModal={handleClose}
        title="Add ticket"
        content={
          <TicketModal handleClose={handleClose} onSubmit={handleSubmit} />
        }
        contentStyle={{ width: "400px" }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
      />
    </div>
  );
}
