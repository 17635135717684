import React, { useEffect } from "react";
import {
  Typography,
  Container,
  IconButton,
  Card,
  Box,
  Grid,
  Stack,
  Button,
  Paper,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import AddIcon from "@mui/icons-material/Add";
// components
import { green, grey } from "@mui/material/colors";
import Page from "./../../components/Page";
import { useDispatch, useSelector } from "react-redux";
import { getAddressesAsyncThunk } from "../../store/slices/user/user.slice";
import CustomVactor from "../../components/NotFound/CustomVactor";
import { formattedAddress } from "../../utils/global/global";
import { useStyles } from "./BusinessDetails/MyProfile.theme";
import { borderRadius } from "@mui/system";
export default function BussinessAddressDetails({
  handleAddressEditMode,
  setAddressEditBtn,
  setEditAddressId,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAddressesAsyncThunk());
  }, [dispatch]);

  const addressData = useSelector((data) => data.user.userData.addresses);
  const classes = useStyles();
  return (
    <Page title="My profile">
      <Box>
        <Grid container>
          <Grid item md={12}>
            {addressData.length ? (
              <>
                {addressData.map((address, idx) => {
                  return (
                    <>
                      <Paper elevation={3}>
                        <Stack
                          justifyContent="space-between"
                          direction="row"
                          alignItems="flex-start"
                          spacing={2}
                          p={2}
                          mb={2}
                        >
                          <Box sx={{ width: "75%" }}>
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "600" }}
                            >
                              Business Address {idx + 1}
                            </Typography>
                            <Box sx={{}}>
                              <Typography variant="inherit" mt={1.5} mb={1.5}>
                                {" "}
                                {formattedAddress(
                                  address.address_line1,
                                  address.address_line2,
                                  address.city,
                                  address.country,
                                  address.state,
                                  address.zip_code
                                )}
                              </Typography>
                            </Box>
                          </Box>

                          <Box
                            sx={{
                              width: "25%",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              color="primary"
                              variant="outlined"
                              aria-label="upload picture"
                              onClick={() => {
                                handleAddressEditMode(true);
                                setAddressEditBtn("edit");
                                setEditAddressId(address.id);
                              }}
                              sx={{ border: "1px solid #757575" }}
                            >
                              <ModeEditOutlinedIcon
                                fontSize="small"
                                sx={{ color: grey[600] }}
                              />
                            </IconButton>
                          </Box>
                        </Stack>
                      </Paper>
                    </>
                  );
                })}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "right",
                    justifyContent: "center",
                    mt: 5,
                    px: 3,
                  }}
                  onClick={() => {
                    setAddressEditBtn("add");
                    handleAddressEditMode(true);
                  }}
                >
                  <Button variant="contained" startIcon={<AddIcon />}>
                    {" "}
                    Add Address
                  </Button>
                </Box>
              </>
            ) : (
              <Grid>
                <Grid item md={12} sm={12}>
                  <CustomVactor />
                </Grid>
                <Grid item md={12} sm={12} textAlign="center" mt={4}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleAddressEditMode(true);
                      setAddressEditBtn("add");
                    }}
                  >
                    Add Address
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
}
