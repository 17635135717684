// material
import {
  Box,
  Button,
  Typography,
  Container,
  Stack,
  Card,
  TextField,
  Link,
} from "@mui/material";
// components
import { MotionContainer } from "../../components/animate";
import { styled } from "@mui/material/styles";
import Page from "../../components/Page";
import useModal from "../../hooks/useModal";
import OTPModal from "../../components/Modal/OtpModal";
import Modal from "../../components/Modal/Modal";
import { MHidden } from "../../components/@material-extend";
import AuthLayout from "../../layouts/AuthLayout";
import { forgotPasswordAsycnThunk } from "../../store/slices/auth/auth.slice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import withAuthLoader from "../../hoc/withAuthLoader";
import { forgotPasswordValidation } from "../../utils/validation";
import Element from "../../components/Form/Element";
import { inputType } from "../../utils/enum";
import { useFormik, Form, FormikProvider } from "formik";

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  paddingBottom: "190px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));
const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

function ForgotPassword() {
  const { isModalOpen, hideModal } = useModal();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: forgotPasswordValidation,
    onSubmit: (values) => {
      dispatch(
        forgotPasswordAsycnThunk({
          ...values,
        })
      );
    },
  });
  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        closeModal={hideModal}
        content={<OTPModal />}
      />

      <RootStyle title="forgot password | EMB">
        {/* <AuthLayout sx={{ mt: 10 }}>
          Don’t have an account? &nbsp;
          <Link underline="none" variant="subtitle2" to="/register">
            Get started
          </Link>
        </AuthLayout> */}

        <MHidden width="mdDown">
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 20 }}>
              Generate, New Password
            </Typography>
            <img
              src="/static/vectors/forgot-password.svg"
              alt="forgot password"
              style={{ padding: "40px", paddingBottom: "0px" }}
            />
          </SectionStyle>
        </MHidden>

        <Container maxWidth="sm">
          <ContentStyle>
            <MotionContainer initial="initial" open>
              <Box
                sx={{
                  maxWidth: 500,
                  margin: "auto",
                  textAlign: "left",
                  mb: 5,
                }}
              >
                <Typography variant="h3" paragraph>
                  Forgot your password?
                </Typography>

                <Typography sx={{ color: "text.secondary", fontSize: "1rem" }}>
                  Please enter the email address associated with your account
                  and we will email you a link to reset your password.
                </Typography>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Box sx={{ margin: "20px 0" }}>
                      <Stack
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                        spacing={2}
                      >
                        {/* <TextField
                      fullWidth
                      value={email}
                      type="email"
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      placeholder="Please enter your email"
                      onChange={(e) => setEmail(e.target.value)}
                    /> */}

                        <Element
                          fullWidth
                          // autoComplete="username"
                          type="email"
                          label="Email address"
                          inputProps={{ ...getFieldProps("email") }}
                          error={Boolean(touched.email && errors.email)}
                          errorText={touched.email && errors.email}
                          eletype={inputType.input}
                        />
                      </Stack>
                    </Box>

                    <Button
                      size="large"
                      sx={{ mt: 3 }}
                      variant="contained"
                      fullWidth={true}
                      type="submit"
                      // onClick={() => handleResetClick()}
                    >
                      Reset Password
                    </Button>
                  </Form>
                </FormikProvider>
                {/* <Button to="/" size="large" sx={{ mt: 2 }} fullWidth={true}>
                Back
              </Button> */}
              </Box>
            </MotionContainer>
          </ContentStyle>
        </Container>
      </RootStyle>
    </>
  );
}

export default withAuthLoader(ForgotPassword);
