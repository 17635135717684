import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  wallet_card: {
    height: "230px",
    background: "#4a4b54",
    position: "relative",
  },

  "@media (max-width:600px)": {
    amount: {
      position: "absolute",
      top: "65px",
    },
    company: {
      position: "absolute",
      top: "110px",
    },
    transection: {
      position: "absolute",
      top: "150px",
    },
  },
});
