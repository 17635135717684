import React from "react";
import { useStyles } from "./Form.theme";

const Input = ({
  label,
  errorText,
  inputProps,
  value,
  styles,
  icon,
  placeholder,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.formGroup}>
      {label ? <label className={classes.formLabel}>{label}</label> : null}
      <input
        className={classes.formControl}
        style={styles}
        {...inputProps}
        value={value}
        placeholder={placeholder}
        autoComplete="off"
      />
      {icon}
      {errorText && <span className={classes.formErrorStyle}>{errorText}</span>}
    </div>
  );
};

export default Input;
