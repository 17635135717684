import GoogleLogin from "react-google-login";
// material
import { Divider, Stack, Typography } from "@mui/material";
import { useStyles } from "./AuthSocial.theme";
import { useDispatch } from "react-redux";
import { loginUserByGoogleAsyncThunk } from "../../store/slices/auth/auth.slice";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------
const CLINT_KEY = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export default function AuthSocial({ divider = false }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const responseGoogle = (response) => {
    const tokenId = response.tokenId;
    const email = response?.profileObj.email;
    dispatch(
      loginUserByGoogleAsyncThunk({
        token: tokenId,
        email,
      })
    )
      .unwrap()
      .then(() => {
        navigate({
          pathname: "/",
        });
      });
  };

  const failureResponse = (response) => {};

  return (
    <>
      <Stack direction="row" spacing={2} mb={2}>
        <GoogleLogin
          clientId={CLINT_KEY}
          buttonText="Login With Google"
          onSuccess={responseGoogle}
          onFailure={failureResponse}
          cookiePolicy={"single_host_origin"}
          className={classes.googleLoginButton}
        />
      </Stack>
      {divider && (
        <Divider sx={{ my: 3 }}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Or Sign in with Email
          </Typography>
        </Divider>
      )}
    </>
  );
}
