import React from "react";
import {
  Typography,
  Container,
  Box,
  Card,
  Stack,
  InputBase,
  Grid,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { useStyles } from "./Faq.theme";
import SearchIcon from "@mui/icons-material/Search";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useAccordion from "../../hooks/useAccordian";
import useDebounceSearch from "../../hooks/useDebounceSearch";
import Page from "../../components/Page";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "600px",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",

      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function Faq() {
  const classes = useStyles();
  const { handleChange, accordianState } = useAccordion(faqs.length, 1);
  const { data, handler } = useDebounceSearch({
    data: faqs,
    debounceTime: 500,
    predicate: (data, value) =>
      data.question.toLowerCase()?.includes(value.toLowerCase()),
  });

  return (
    <Page title="FAQ | EMB">
      <Container sx={{ margin: 0 }}>
        <Typography variant="h4">FAQ's</Typography>
        {/* <Box>
          <Card className={classes.faq_card}>
            <Stack alignItems="center" justifyContent="center">
              <Typography
                className={classes.d_none}
                variant="h4"
                sx={{ color: "#11a54a" }}
              >
                Let's answer some questions
              </Typography>
              <Typography className={classes.d_none}>
                or choose a category to quickly find the help you need
              </Typography>

              <Box>
                <Search className={classes.search}>
                  <SearchIconWrapper>
                    <SearchIcon className={classes.search_icon} />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search a question"
                    inputProps={{ "aria-label": "search" }}
                    className={classes.search_input}
                    onChange={(e) => handler(e.target.value)}
                  />
                </Search>
              </Box>
            </Stack>
          </Card>
        </Box> */}

        <Grid container spacing={2}>
          <Grid item md={12}>
            <Box pt={5}>
              <Card>
                <Box p={3}>
                  <Typography variant="h5">Frequently Asked Questions</Typography>
                  <Box pt={4}>
                    {data.map((item) => (
                      <Accordion
                        expanded={accordianState[item.id]}
                        onChange={() => handleChange(item.id)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                        >
                          <Typography>
                            <Stack direction="row" alignItems="center">
                              <Box
                                sx={{
                                  marginLeft: "-20px",
                                  marginRight: "10px",
                                }}
                                // className={classes.faq_img_mob}
                              >
                                <Box className={classes.faq_img_mob}>
                                  <img
                                    src={"/static/icons/faq.svg"}
                                    width={30}
                                    height={30}
                                    alt=""
                                    className={classes.faq_img}
                                  />
                                </Box>
                              </Box>
                              <Box className={classes.faq_qus}>
                                {item.question}
                              </Box>
                            </Stack>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            className={classes.faq_ans}
                            sx={{ marginLeft: "20px" }}
                          >
                            {item.answer}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Box>
                </Box>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

var faqs = [
  {
    id: 0,
    question: "How does EMB help in the project delivery process?",
    answer:
      "Every project is assigned to a key account manager or consultant who ensures that the project is delivered on time and that all projects expectations have been met. Also, they will check whether or not the vendor has adhered to all terms of agreement specified in the contract.",
  },
  {
    id: 1,
    question: "How can I track the progress of my project?",
    answer:
      "You can track your project’s timeline, progress, and updates from your client dashboard. Apart from that, your dedicated project manager will update you on your project’s status regularly.",
  },
  {
    id: 2,
    question: "What is your refund policy?",
    answer:
      "You are eligible for a refund if there is any contract breach from the vendor’s side during any stage of the project. We take refund cases seriously, and our legal team will personally scrutinize all the details before taking required actions. The exact details of our refund guidelines vary from contract to contract, as every project is unique and has distinct terms of agreement, decided upon mutually by the vendor and client. You can refer to your contract or reach out to your assigned project manager in case of any confusion. **Please note that as soon as a milestone is achieved and the project deliveries have gained client approval, we release that milestone’s payments to the vendors. Once released, payments cannot be refunded.",
  },
  {
    id: 3,
    question: "What is Escrow?",
    answer:
      "Under an Escrow agreement, financial assets or funds are held by a third party on behalf of two other parties that have entered into an agreement, and are in the process of a contract or a transaction. Payments are released only when all contractual obligations have been met and the paying party approves. In our scenario, Expand My Business secures vendor-client payments by holding the entire sum in an Escrow account. Payments are released when vendors achieve milestones stipulated in the contract, and the delivered projects have been approved by the client.",
  },
  {
    id: 4,
    question: "What will be the timeline of project-related meetings?",
    answer:
      "From our side, the consultant assigned to a particular project will conduct weekly vendor-client meetings to give updates on the project’s current status and the future plan of action. The timings of these meetings will vary according to the client’s schedule and can easily be coordinated with concerned project manager/consultant.",
  },
  {
    id: 5,
    question: "How does your process work?",
    answer:
      "We have a simple 5-step process. Clients need to sign up and fill in their project requirements. Our algorithm then shortlists quality vendors that can fulfill client needs, and a call is scheduled with top vendors. Once the client finalizes a vendor, a business contract is drawn that both parties need to approve. After a deal has been finalized, we further act as fulfillment partners for clients and ensure that all projects meet client expectations and are delivered under the stipulated deadlines. We also provide payment security to clients by following a milestone-based payment system.",
  },
];
