import { Block } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  profileSection: {
    "@media (max-width: 1000px) ": {
      marginBottom: "20px",
    },
  },
  profileDiv: {
    display: "flex",
    // padding: "15px 10px",

    "@media (max-width: 700px) ": {
      padding: "15px 10px",
    },
    "@media (max-width:600px)": {
      padding: "15px 10px",
    },

    "@media (max-width: 500px) ": {
      padding: "15px 10px",
    },

    "@media (max-width: 400px) ": {
      padding: "15px 10px",
    },
    "@media (max-width: 350px) ": {
      padding: "15px 10px",
    },
    "@media (max-width: 300px) ": {
      padding: "15px 10px",
    },
  },
  viewProfileBtn: {
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "600",
    color: "#397EF5",
    display: "none",
    "@media (max-width: 1000px) ": {
      display: "block",
    },
  },
});
