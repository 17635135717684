import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Stack,
  Typography,
  Card,
  CardActions,
  Grid,
} from "@mui/material";
import { useParams } from "react-router-dom";
//Component
import Page from "../../../components/Page";
import Review from "../../../components/Review/Review";
import { useDispatch, useSelector } from "react-redux";
import {
  getRatingQuestionsThunk,
  submitReviewQuestionsThunk,
} from "../../../store/slices/project/project.slice";
import SearchNotFound from "../../../components/NotFound/SearchNotFound";
import { projectStatus } from "../../../utils/enum";

export default function ProjectFeedback() {
  const { questions } = useSelector(({ project }) => project.reviewQuestions);
  const { isReviewGiven } = useSelector(
    ({ project }) => project.reviewQuestions
  );
  const [current, setCurrent] = useState(0);
  const [answers, setAnswers] = useState({});

  const currentStatus = useSelector(
    (user) => user.project.selectedProject.overview.project_status
  );

  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(getRatingQuestionsThunk(params.id));
  }, [dispatch, params.id]);

  function handleNext() {
    if (current === questions.length - 1) return;
    setCurrent((current) => current + 1);
  }

  function handlePrev() {
    if (current === 0) return;
    setCurrent((current) => current - 1);
  }

  function handleSubmit() {
    const modifiedAnswers = Object.keys(answers).map((key) => ({
      ques_id: key,
      review: answers[key],
    }));
    dispatch(
      submitReviewQuestionsThunk({
        data: modifiedAnswers,
        project_id: params.id,
      })
    );
  }

  const handleChange = useCallback((ques_id, response) => {
    setAnswers((answers) => ({
      ...answers,
      [ques_id]: response,
    }));
  }, []);

  const total = questions.length;

  const isLast = current === total - 1;

  if (!(currentStatus === projectStatus.closed))
    return (
      <SearchNotFound
        src="/static/rating.png"
        title="Your Project Is Not Completed Yet!"
        subtitle="There is currently no Rating to show."
      />
    );

  if (questions.length === 0)
    return (
      <SearchNotFound
        src="/static/rating.png"
        title="No Questions Present For Feedback."
        subtitle="There is currently no Rating to show."
      />
    );

  if (isReviewGiven)
    return (
      <SearchNotFound
        src="/static/rating.png"
        title="Review Received"
        subtitle="We have received your review."
      />
    );

  return (
    <Page title="Proposal | EMB">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Project Feedback
          </Typography>
        </Stack>
        <Card>
          <div
            style={{
              width: "200px",
              height: "200px",
              background: "#eff8f1",
              position: "absolute",
              top: "-50px",
              left: "-60px",
              zIndex: "-9",
              borderRadius: "50%",
              transform: "rotate(20deg)",
            }}
          ></div>
          <div
            style={{
              width: "150px",
              height: "150px",
              background: "#f3f6f8",
              position: "absolute",
              bottom: "-80px",
              right: "-60px",
              zIndex: "-9",
              borderRadius: "50%",
              transform: "rotate(20deg)",
            }}
          ></div>
          <Review
            current={current}
            total={total}
            questions={questions}
            onChange={handleChange}
            answers={answers}
            handleNext={handleNext}
            handlePrev={handlePrev}
            handleSubmit={handleSubmit}
            isLast={isLast}
          />
          <CardActions>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  spacing={2}
                  mr={2}
                >
                  {/* <Button
                    size="small"
                    variant="contained"
                    sx={{ opacity: isLast ? 1 : 0.3 }}
                    onClick={handleSubmit}
                  >
                    Submit Review
                  </Button> */}
                </Stack>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Container>
    </Page>
  );
}
