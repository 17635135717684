import React from "react";
import {
  Paper,
  Card,
  Box,
  Container,
  Grid,
  Button,
  Stack,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  Chip,
  IconButton,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  Divider,
} from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import WorkIcon from "@mui/icons-material/Work";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import ListSearchbar from "../../components/Common/ListSearchbar";
import { capitalCase } from "../../utils/change-case";
import { styled } from "@mui/material/styles";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import { makeStyles } from "@mui/styles";
import Element from "../../components/Form/Element";
import { inputType } from "../../utils/enum";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import "./Chat.css";
import { useSelector } from "react-redux";
import MobileChatList from "./MobileChatList";

export default function Chat() {
  function getInitials(project_name) {
    return capitalCase(project_name.substring(0, 2));
  }
  const { header, sidebar } = useSelector(({ uiStyle }) => uiStyle);
  console.log("-------", header, sidebar);

  const Input = styled("input")({
    display: "none",
  });

  const useStyles = makeStyles({
    button: {
      color: "#fff",
      backgroundColor: "#0044E3",
      "&:hover": {
        backgroundColor: "#0044E3",
        boxShadow: "0 8px 16px 0 rgb(0 68 226 / 25%)",
      },
      height: "42px",
    },
  });
  const classes = useStyles();

  const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 320,
    transition: theme.transitions.create(["box-shadow", "width"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    // "&.Mui-focused": { width: 320, boxShadow: theme.customShadows.z8 },
    "& fieldset": {
      borderWidth: `1px !important`,
      borderColor: `${theme.palette.grey[500_32]} !important`,
    },
  }));

  return (
    <>
      <div className="wrapper" style={{ zIndex: "999" }}>
        <Paper className="container">
          <div className="left-container">
            <Paper className="left-box">
              <div className="action-container">
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  sx={{ padding: "1rem 2rem 1rem 1rem" }}
                >
                  <IconButton aria-label="search">
                    <ArrowCircleLeftIcon sx={{ color: "#343434" }} />
                  </IconButton>

                  <SearchStyle
                    placeholder="Search projects"
                    size="small"
                    startAdornment={
                      <InputAdornment
                        position="left"
                        sx={{ marginRight: "0.75rem" }}
                      >
                        <Box
                          component={Icon}
                          icon={searchFill}
                          sx={{ color: "text.disabled" }}
                        />
                      </InputAdornment>
                    }
                  />
                </Stack>
              </div>
              <div className="list-group-container">
                <SimpleBar style={{ maxHeight: "100%" }}>
                  <div style={{ paddingBottom: "1.5rem" }}>
                    <List
                      sx={{
                        width: "100%",
                        height: "auto",
                        bgcolor: "background.paper",
                      }}
                    >
                      <ListItem
                        sx={{
                          borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              fontSize: ".8rem",
                              fontWeight: "600",
                              backgroundColor: "rgb(243, 246, 248)",
                              color: "#556080",
                              marginLeft: "5px",
                              width: "35px",
                              height: "35px",
                            }}
                          >
                            {getInitials("Hivefy Front-end Project")}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              sx={{
                                fontSize: "0.9rem",
                                marginBottom: "0.35rem",
                                fontWeight: "600",
                              }}
                            >
                              Hivefy Front-end Project
                            </Typography>
                          }
                          secondary={
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#8F9499",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  gap: "10px",
                                  width: "60%",
                                }}
                              >
                                <GroupsIcon /> 3
                              </Typography>
                              <div style={{ width: "40%", margin: "0 auto" }}>
                                <Chip
                                  label="Active"
                                  variant="outlined"
                                  sx={{
                                    fontSize: "0.7rem",
                                    color: "#27A853",
                                    background: "#eef7f0",
                                    border: "0",
                                    float: "right",
                                    height: "26px",
                                  }}
                                />
                              </div>
                            </Stack>
                          }
                        />
                      </ListItem>
                      <ListItem
                        sx={{
                          borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              fontSize: ".8rem",
                              fontWeight: "600",
                              backgroundColor: "rgb(243, 246, 248)",
                              color: "#556080",
                              marginLeft: "5px",
                              width: "35px",
                              height: "35px",
                            }}
                          >
                            {getInitials("Zaniaah E Commerce")}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "0.9rem",
                                marginBottom: "0.35rem",
                                fontWeight: "600",
                              }}
                            >
                              Zaniaah E Commerce
                            </Typography>
                          }
                          secondary={
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#8F9499",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  gap: "10px",
                                  width: "60%",
                                }}
                              >
                                <GroupsIcon /> 5
                              </Typography>
                              <div style={{ width: "40%", margin: "0 auto" }}>
                                <Chip
                                  label="Active"
                                  variant="outlined"
                                  sx={{
                                    fontSize: "0.7rem",
                                    color: "#27A853",
                                    background: "#eef7f0",
                                    border: "0",
                                    float: "right",
                                    height: "26px",
                                  }}
                                />
                              </div>
                            </Stack>
                          }
                        />
                      </ListItem>
                      <ListItem
                        sx={{
                          borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              fontSize: ".8rem",
                              fontWeight: "600",
                              backgroundColor: "rgb(243, 246, 248)",
                              color: "#556080",
                              marginLeft: "5px",
                              width: "35px",
                              height: "35px",
                            }}
                          >
                            {getInitials("Wine Quality Test Project")}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "0.9rem",
                                marginBottom: "0.35rem",
                                fontWeight: "600",
                              }}
                            >
                              Wine Quality Test Project
                            </Typography>
                          }
                          secondary={
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#8F9499",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  gap: "10px",
                                  width: "60%",
                                }}
                              >
                                <GroupsIcon /> 2
                              </Typography>
                              <div style={{ width: "40%", margin: "0 auto" }}>
                                <Chip
                                  label="Active"
                                  variant="outlined"
                                  sx={{
                                    fontSize: "0.7rem",
                                    color: "#27A853",
                                    background: "#eef7f0",
                                    border: "0",
                                    float: "right",
                                    height: "26px",
                                  }}
                                />
                              </div>
                            </Stack>
                          }
                        />
                      </ListItem>
                      <ListItem
                        sx={{
                          borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              fontSize: ".8rem",
                              fontWeight: "600",
                              backgroundColor: "rgb(243, 246, 248)",
                              color: "#556080",
                              marginLeft: "5px",
                              width: "35px",
                              height: "35px",
                            }}
                          >
                            {getInitials("Handwritten Character Recognition")}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "0.9rem",
                                marginBottom: "0.35rem",
                                fontWeight: "600",
                              }}
                            >
                              Handwritten Character Recognition
                            </Typography>
                          }
                          secondary={
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#8F9499",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  gap: "10px",
                                  width: "60%",
                                }}
                              >
                                <GroupsIcon /> 4
                              </Typography>
                              <div style={{ width: "40%", margin: "0 auto" }}>
                                <Chip
                                  label="Active"
                                  variant="outlined"
                                  sx={{
                                    fontSize: "0.7rem",
                                    color: "#27A853",
                                    background: "#eef7f0",
                                    border: "0",
                                    float: "right",
                                    height: "26px",
                                  }}
                                />
                              </div>
                            </Stack>
                          }
                        />
                      </ListItem>
                      <ListItem
                        sx={{
                          borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              fontSize: ".8rem",
                              fontWeight: "600",
                              backgroundColor: "rgb(243, 246, 248)",
                              color: "#556080",
                              marginLeft: "5px",
                              width: "35px",
                              height: "35px",
                            }}
                          >
                            {getInitials("Ghost Grubs")}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "0.9rem",
                                marginBottom: "0.35rem",
                                fontWeight: "600",
                              }}
                            >
                              Ghost Grubs
                            </Typography>
                          }
                          secondary={
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#8F9499",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  gap: "10px",
                                  width: "60%",
                                }}
                              >
                                <GroupsIcon /> 5
                              </Typography>
                              <div style={{ width: "40%", margin: "0 auto" }}>
                                <Chip
                                  label="Active"
                                  variant="outlined"
                                  sx={{
                                    fontSize: "0.7rem",
                                    color: "#27A853",
                                    background: "#eef7f0",
                                    border: "0",
                                    float: "right",
                                    height: "26px",
                                  }}
                                />
                              </div>
                            </Stack>
                          }
                        />
                      </ListItem>
                      <ListItem
                        sx={{
                          borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              fontSize: ".8rem",
                              fontWeight: "600",
                              backgroundColor: "rgb(243, 246, 248)",
                              color: "#556080",
                              marginLeft: "5px",
                              width: "35px",
                              height: "35px",
                            }}
                          >
                            {getInitials("Hivefy Front-end Project")}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "0.9rem",
                                marginBottom: "0.35rem",
                                fontWeight: "600",
                              }}
                            >
                              Hivefy Front-end Project
                            </Typography>
                          }
                          secondary={
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#8F9499",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  gap: "10px",
                                  width: "60%",
                                }}
                              >
                                <GroupsIcon /> 3
                              </Typography>
                              <div style={{ width: "40%", margin: "0 auto" }}>
                                <Chip
                                  label="Active"
                                  variant="outlined"
                                  sx={{
                                    fontSize: "0.7rem",
                                    color: "#27A853",
                                    background: "#eef7f0",
                                    border: "0",
                                    float: "right",
                                    height: "26px",
                                  }}
                                />
                              </div>
                            </Stack>
                          }
                        />
                      </ListItem>
                      <ListItem
                        sx={{
                          borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              fontSize: ".8rem",
                              fontWeight: "600",
                              backgroundColor: "rgb(243, 246, 248)",
                              color: "#556080",
                              marginLeft: "5px",
                              width: "35px",
                              height: "35px",
                            }}
                          >
                            {getInitials("Zaniaah E Commerce")}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "0.9rem",
                                marginBottom: "0.35rem",
                                fontWeight: "600",
                              }}
                            >
                              Zaniaah E Commerce
                            </Typography>
                          }
                          secondary={
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#8F9499",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  gap: "10px",
                                  width: "60%",
                                }}
                              >
                                <GroupsIcon /> 5
                              </Typography>
                              <div style={{ width: "40%", margin: "0 auto" }}>
                                <Chip
                                  label="Active"
                                  variant="outlined"
                                  sx={{
                                    fontSize: "0.7rem",
                                    color: "#27A853",
                                    background: "#eef7f0",
                                    border: "0",
                                    float: "right",
                                    height: "26px",
                                  }}
                                />
                              </div>
                            </Stack>
                          }
                        />
                      </ListItem>
                      <ListItem
                        sx={{
                          borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              fontSize: ".8rem",
                              fontWeight: "600",
                              backgroundColor: "rgb(243, 246, 248)",
                              color: "#556080",
                              marginLeft: "5px",
                              width: "35px",
                              height: "35px",
                            }}
                          >
                            {getInitials("Wine Quality Test Project")}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "0.9rem",
                                marginBottom: "0.35rem",
                                fontWeight: "600",
                              }}
                            >
                              Wine Quality Test Project
                            </Typography>
                          }
                          secondary={
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#8F9499",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  gap: "10px",
                                  width: "60%",
                                }}
                              >
                                <GroupsIcon /> 2
                              </Typography>
                              <div style={{ width: "40%", margin: "0 auto" }}>
                                <Chip
                                  label="Active"
                                  variant="outlined"
                                  sx={{
                                    fontSize: "0.7rem",
                                    color: "#27A853",
                                    background: "#eef7f0",
                                    border: "0",
                                    float: "right",
                                    height: "26px",
                                  }}
                                />
                              </div>
                            </Stack>
                          }
                        />
                      </ListItem>
                      <ListItem
                        sx={{
                          borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              fontSize: ".8rem",
                              fontWeight: "600",
                              backgroundColor: "rgb(243, 246, 248)",
                              color: "#556080",
                              marginLeft: "5px",
                              width: "35px",
                              height: "35px",
                            }}
                          >
                            {getInitials("Handwritten Character Recognition")}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "0.9rem",
                                marginBottom: "0.35rem",
                                fontWeight: "600",
                              }}
                            >
                              Handwritten Character Recognition
                            </Typography>
                          }
                          secondary={
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#8F9499",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  gap: "10px",
                                  width: "60%",
                                }}
                              >
                                <GroupsIcon /> 4
                              </Typography>
                              <div style={{ width: "40%", margin: "0 auto" }}>
                                <Chip
                                  label="Active"
                                  variant="outlined"
                                  sx={{
                                    fontSize: "0.7rem",
                                    color: "#27A853",
                                    background: "#eef7f0",
                                    border: "0",
                                    float: "right",
                                    height: "26px",
                                  }}
                                />
                              </div>
                            </Stack>
                          }
                        />
                      </ListItem>
                      <ListItem
                        sx={{
                          borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              fontSize: ".8rem",
                              fontWeight: "600",
                              backgroundColor: "rgb(243, 246, 248)",
                              color: "#556080",
                              marginLeft: "5px",
                              width: "35px",
                              height: "35px",
                            }}
                          >
                            {getInitials("Ghost Grubs")}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "0.9rem",
                                marginBottom: "0.35rem",
                                fontWeight: "600",
                              }}
                            >
                              Ghost Grubs Last
                            </Typography>
                          }
                          secondary={
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#8F9499",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  gap: "10px",
                                  width: "60%",
                                }}
                              >
                                <GroupsIcon /> 5
                              </Typography>
                              <div style={{ width: "40%", margin: "0 auto" }}>
                                <Chip
                                  label="Active"
                                  variant="outlined"
                                  sx={{
                                    fontSize: "0.7rem",
                                    color: "#27A853",
                                    background: "#eef7f0",
                                    border: "0",
                                    float: "right",
                                    height: "26px",
                                  }}
                                />
                              </div>
                            </Stack>
                          }
                        />
                      </ListItem>
                    </List>
                  </div>
                </SimpleBar>
              </div>
            </Paper>
          </div>
          <div className="right-container">
            <div className="chat-title-wrapper">
              <Paper
                sx={{
                  padding: "0",
                  borderRadius: "0",
                  borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
                  borderTop: "1px solid rgba(145, 158, 171, 0.24)",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Stack
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                  sx={{ padding: "1rem 2rem" }}
                >
                  <Typography variant="h6" sx={{ color: "#222" }}>
                    Hivefy Front-end Project
                  </Typography>
                  <Typography variant="body1" sx={{ color: "#637183" }}>
                    Prakash,Rohan Raj, You
                  </Typography>
                </Stack>
              </Paper>
            </div>
            <Box className="chatting-body">
              <Box className="chatting-body-wrapper">
                <div className="chatting-area">
                  <SimpleBar style={{ maxHeight: "100%" }}>
                    <Grid container p={2}>
                      <Grid item md={12} sx={{ textAlign: "right" }}>
                        <Stack
                          justifyContent="flex-end"
                          direction="row"
                          alignItems="center"
                          mb={2}
                        >
                          <Box>
                            <Typography
                              sx={{
                                backgroundColor: "#fff",
                                padding: 2,
                                borderRadius: "10px",
                                boxShadow: "0px 0px 8px #90909029",
                                marginRight: 2,
                              }}
                            >
                              Hello!
                            </Typography>
                            <span
                              style={{
                                color: "#a5a5a5",
                                fontSize: "12px",
                                marginRight: "22px",
                              }}
                            >
                              Today, 3:19 PM
                            </span>
                          </Box>
                          <Avatar
                            src="/static/default-user.png"
                            alt="client image"
                          />
                        </Stack>

                        <Stack
                          justifyContent="flex-end"
                          direction="row"
                          alignItems="center"
                        >
                          <Box>
                            <Typography
                              sx={{
                                backgroundColor: "#fff",
                                padding: 2,
                                borderRadius: "10px",
                                boxShadow: "0px 0px 8px #90909029",
                                marginRight: 2,
                              }}
                            >
                              Babu Rao Ghar par hai?
                            </Typography>
                            <span
                              style={{
                                color: "#a5a5a5",
                                fontSize: "12px",
                                marginRight: "22px",
                              }}
                            >
                              Today, 3:19 PM
                            </span>
                          </Box>
                          <Avatar
                            src="/static/default-user.png"
                            alt="client image"
                          />
                        </Stack>
                      </Grid>
                      <Grid item md={12} sx={{ textAlign: "left" }}>
                        <Stack
                          justifyContent="flex-start"
                          direction="row"
                          alignItems="center"
                          mb={2}
                        >
                          <Avatar
                            src="/static/default-user.png"
                            alt="client image"
                          />
                          <Box>
                            <Typography
                              sx={{
                                backgroundColor: "#556080",
                                padding: 2,
                                borderRadius: "10px",
                                boxShadow: "0px 0px 8px #90909029",
                                marginLeft: 2,
                                color: "#fff",
                              }}
                            >
                              Abhi ghar par hai!!!!
                            </Typography>
                            <Stack
                              justifyContent="space-between"
                              direction="row"
                              alignItems="center"
                            >
                              <span
                                style={{
                                  color: "#a5a5a5",
                                  fontSize: "12px",
                                  marginLeft: "22px",
                                }}
                              >
                                Today, 3:20 PM
                              </span>
                              <span
                                style={{
                                  color: "#3E3E3E",
                                  fontSize: "12px",
                                  marginLeft: "22px",
                                }}
                              >
                                Prakash
                              </span>
                            </Stack>
                          </Box>
                        </Stack>
                        <Stack
                          justifyContent="flex-start"
                          direction="row"
                          alignItems="center"
                        >
                          <Avatar
                            src="/static/default-user.png"
                            alt="client image"
                          />
                          <Box>
                            <Typography
                              sx={{
                                backgroundColor: "#556080",
                                padding: 2,
                                borderRadius: "10px",
                                boxShadow: "0px 0px 8px #90909029",
                                marginLeft: 2,
                                color: "#fff",
                              }}
                            >
                              KAL RRR dekhne Chale.....
                            </Typography>
                            <Stack
                              justifyContent="space-between"
                              direction="row"
                              alignItems="center"
                            >
                              <span
                                style={{
                                  color: "#a5a5a5",
                                  fontSize: "12px",
                                  marginLeft: "22px",
                                }}
                              >
                                Today, 3:20 PM
                              </span>
                              <span
                                style={{
                                  color: "#3E3E3E",
                                  fontSize: "12px",
                                  marginLeft: "22px",
                                }}
                              >
                                Exmyb
                              </span>
                            </Stack>
                          </Box>
                        </Stack>
                      </Grid>
                      <Grid item md={12} sx={{ textAlign: "right" }}>
                        <Stack
                          justifyContent="flex-end"
                          direction="row"
                          alignItems="center"
                          mb={2}
                        >
                          <Typography
                            sx={{
                              backgroundColor: "#fff",
                              padding: 2,
                              borderRadius: "10px",
                              boxShadow: "0px 0px 8px #90909029",
                              marginRight: 2,
                            }}
                          >
                            Hello!
                          </Typography>
                          <Avatar
                            src="/static/default-user.png"
                            alt="client image"
                          />
                        </Stack>
                        <Stack
                          justifyContent="flex-end"
                          direction="row"
                          alignItems="center"
                        >
                          <Typography
                            sx={{
                              backgroundColor: "#fff",
                              padding: 2,
                              borderRadius: "10px",
                              boxShadow: "0px 0px 8px #90909029",
                              marginRight: 2,
                            }}
                          >
                            Babu Rao Ghar par hai?
                          </Typography>
                          <Avatar
                            src="/static/default-user.png"
                            alt="client image"
                          />
                        </Stack>
                      </Grid>
                      <Grid item md={12} sx={{ textAlign: "left" }}>
                        <Stack
                          justifyContent="flex-start"
                          direction="row"
                          alignItems="center"
                          mb={2}
                        >
                          <Avatar
                            src="/static/default-user.png"
                            alt="client image"
                          />

                          <Typography
                            sx={{
                              backgroundColor: "#556080",
                              padding: 2,
                              borderRadius: "10px",
                              boxShadow: "0px 0px 8px #90909029",
                              marginLeft: 2,
                              color: "#fff",
                            }}
                          >
                            Abhi ghar par hai!!!!
                          </Typography>
                        </Stack>
                        <Stack
                          justifyContent="flex-start"
                          direction="row"
                          alignItems="center"
                        >
                          <Avatar
                            src="/static/default-user.png"
                            alt="client image"
                          />

                          <Typography
                            sx={{
                              backgroundColor: "#556080",
                              padding: 2,
                              borderRadius: "10px",
                              boxShadow: "0px 0px 8px #90909029",
                              marginLeft: 2,
                              color: "#fff",
                            }}
                          >
                            KAL RRR dekhne Chale.....
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </SimpleBar>
                </div>
                <Divider />
                <Box
                  sx={{
                    maxHeight: "100%",
                    display: "flex",
                    position: "relative",
                    alignItems: "center",
                    padding: "1rem",
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    spacing={2}
                    sx={{ width: "100%" }}
                  >
                    <label htmlFor="icon-button-file">
                      <Input
                        accept="image/*"
                        id="icon-button-file"
                        type="file"
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <AttachFileIcon sx={{ transform: "rotate(45deg)" }} />
                      </IconButton>
                    </label>
                    <Element
                      placeholder="Enter your message here..."
                      eletype={inputType.input}
                      styles={{
                        boxShadow: "0px 0px 6px #71717129",
                        borderRadius: "12px",
                        opacity: "0.75",
                        marginBottom: "10px",
                      }}
                    />
                    <Button
                      variant="contained"
                      endIcon={<SendIcon />}
                      className={classes.button}
                    >
                      Send
                    </Button>
                  </Stack>
                </Box>
              </Box>
            </Box>
            <Divider />
          </div>
        </Paper>
      </div>
    </>
  );
}
