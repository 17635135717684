import React from "react";
import {
  Typography,
  Container,
  Card,
  Box,
  Grid,
  Stack,
  Button,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

import Avatar from "@mui/material/Avatar";

// components
import { green } from "@mui/material/colors";
import Page from "./../../components/Page";
import Element from "./../../components/Form/Element";
import { inputType } from "../../utils/enum";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import {
  createBusinessAddressThunk,
  updateBusinessAddressThunk,
} from "./../../store/slices/user/user.slice";
import { addressValidation } from "../../utils/validation";

export default function EditBussinessAddressDetails({
  handleAddressEditMode,
  addressEditBtn,
  editAddressId,
}) {
  const addressData = useSelector(({ user }) => user.userData.addresses);

  const res = addressData.filter((address) => address.id === editAddressId);

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    if (addressEditBtn === "add") {
      dispatch(createBusinessAddressThunk({ ...values, org_profile_id: 2 }))
        .unwrap()
        .then(() => {
          handleAddressEditMode(false);
        });
    } else {
      dispatch(updateBusinessAddressThunk({ ...values, id: editAddressId }))
        .unwrap()
        .then(() => {
          handleAddressEditMode(false);
        });
    }
  };

  return (
    <Page title="My Profile | EMB">
      <Box>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Formik
              initialValues={{
                address_line1:
                  addressEditBtn === "edit" ? res[0]?.address_line1 : "",
                address_line2:
                  addressEditBtn === "edit" ? res[0]?.address_line2 : undefined,
                city: addressEditBtn === "edit" ? res[0]?.city : undefined,
                state: addressEditBtn === "edit" ? res[0]?.state : undefined,
                zip_code:
                  addressEditBtn === "edit" ? res[0]?.zip_code : undefined,
                country:
                  addressEditBtn === "edit" ? res[0]?.country : undefined,
              }}
              onSubmit={handleSubmit}
              validationSchema={addressValidation}
            >
              {({
                values,
                handleChange,
                handleBlur,
                touched,
                handleSubmit,
                errors,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box>
                    <Element
                      eletype={inputType.input}
                      label="Address 1"
                      value={values.address_line1}
                      inputProps={{
                        name: "address_line1",
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      errorText={touched.address_line1 && errors.address_line1}
                    />
                  </Box>
                  <Box>
                    <Element
                      eletype={inputType.input}
                      label="Address 2"
                      value={values.address_line2}
                      inputProps={{
                        name: "address_line2",
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      errorText={touched.address_line2 && errors.address_line2}
                    />
                  </Box>
                  <Box>
                    <Stack
                      direction="row"
                      justifyContent="space-evenly"
                      spacing={2}
                    >
                      <Element
                        eletype={inputType.input}
                        label="Country"
                        value={values.country}
                        inputProps={{
                          name: "country",
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                        errorText={touched.country && errors.country}
                      />

                      <Element
                        eletype={inputType.input}
                        label="State/Region"
                        value={values.state}
                        inputProps={{
                          name: "state",
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                        errorText={touched.state && errors.state}
                      />
                    </Stack>
                  </Box>
                  <Box>
                    <Stack
                      direction="row"
                      justifyContent="space-evenly"
                      spacing={2}
                    >
                      <Element
                        eletype={inputType.input}
                        label="City"
                        value={values.city}
                        inputProps={{
                          name: "city",
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                        errorText={touched.city && errors.city}
                      />

                      <Element
                        eletype={inputType.number}
                        label="Zip Code"
                        value={values.zip_code}
                        inputProps={{
                          name: "zip_code",
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                        errorText={touched.zip_code && errors.zip_code}
                      />
                    </Stack>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "20px 0",
                    }}
                  >
                    <Stack direction="row" spacing={1}>
                      <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                        sx={{
                          background: green[500],
                          ":hover": { background: green[300] },
                        }}
                      >
                        Save
                      </Button>
                    </Stack>
                  </Box>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
}
