import * as Yup from "yup";
import { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";
// material
import { Button, Stack } from "@mui/material";
import MobileInput from "../../../components/MobileInput";

// ----------------------------------------------------------------------
import Element from "../../Form/Element";
import { inputType } from "../../../utils/enum";
import { useDispatch } from "react-redux";
import { signupWithEmailAsyncThunk } from "../../../store/slices/auth/auth.slice";

export default function RegisterForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const RegisterSchema = Yup.object({
    first_name: Yup.string()
      .required("First name is required")
      .min(2, "Too Short!")
      .max(50, "Too Long!"),
    last_name: Yup.string()
      .required("Last name is required")
      .min(2, "Too Short!")
      .max(50, "Too Long!"),
    email: Yup.string()
      .required("Email is required")
      .email("Email must be a valid email address"),
    password: Yup.string().required("Password is required"),
    mobile: Yup.object({
      mobile_number: Yup.string()
        .required("Mobile is Required")
        .max(10, "Please Enter a Valid Mobile Number")
        .min(10, "Please Enter a Valid Mobile Number"),
    }),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      mobile: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: (data) => {
      dispatch(signupWithEmailAsyncThunk(data));
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    values,
    handleChange,
    handleBlur,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <Element
              fullWidth
              label="First name"
              inputProps={{ ...getFieldProps("first_name") }}
              error={Boolean(touched.first_name && errors.first_name)}
              errorText={touched.first_name && errors.first_name}
              eletype={inputType.input}
            />

            <Element
              fullWidth
              label="Last name"
              inputProps={{ ...getFieldProps("last_name") }}
              error={Boolean(touched.last_name && errors.last_name)}
              errorText={touched.last_name && errors.last_name}
              eletype={inputType.input}
            />
          </Stack>

          <Element
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            inputProps={{ ...getFieldProps("email") }}
            error={Boolean(touched.email && errors.email)}
            errorText={touched.email && errors.email}
            eletype={inputType.input}
          />

          <MobileInput
            label="Mobile Number"
            name="mobile"
            value={values.mobile}
            onChange={handleChange}
            onBlur={handleBlur}
            errorText={touched.mobile && errors.mobile?.mobile_number}
          />

          <Element
            fullWidth
            autoComplete="current-password"
            inputProps={{
              type: showPassword ? "text" : "password",
              ...getFieldProps("password"),
            }}
            label="Password"
            error={Boolean(touched.password && errors.password)}
            errorText={touched.password && errors.password}
            eletype={inputType.input}
          />
          <Button fullWidth size="large" type="submit" variant="contained">
            Register
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
