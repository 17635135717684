import * as React from "react";
import DialogContentText from "@mui/material/DialogContentText";
import { Box, Typography, Button } from "@mui/material";
import OtpInput from "react-otp-input";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  inputSize: {
    "& input": {
      width: "4em !important",
      height: "4em",
      margin: "14px 10px",
      background: "#00ab5514",
      border: "none",
      borderRadius: "8px",
      color: " #0cab57",
      outline: "none",
      fontWeight: "700",
    },
    "& span": {
      display: "none",
    },
  },
});

export default function OTPModal({ validateOTP, resetOTP, digitsInOTP = 4 }) {
  const [otp, setOtp] = React.useState("");

  const handleChange = (otp) => setOtp(otp);
  const classes = useStyles();
  return (
    <DialogContentText align="center" p={4}>
      <Box
        component="img"
        sx={{
          margin: "auto",
          marginBottom: "10px",
        }}
        alt="The house from the offer."
        src="/static/vectors/otp.svg"
      />
      <Box
        component="span"
        display="block"
        sx={{ fontSize: "16px", fontWeight: "600", m: 0.5 }}
      >
        Please Enter Your OTP
      </Box>

      <Box
        component="div"
        className={classes.inputSize}
        sx={{ display: "table", margin: "auto" }}
      >
        <OtpInput
          value={otp}
          onChange={handleChange}
          numInputs={digitsInOTP}
          separator={<span style={{ margin: "0 8px" }}> </span>}
          align="center"
          sx={{ justifyContent: "center" }}
          inputStyle={{ padding: "2px", fontSize: "16px" }}
        />
      </Box>
      <Box sx={{ margin: "20px 0 20px 0" }}>
        <Button variant="contained" onClick={() => validateOTP(otp)}>
          Submit
        </Button>
      </Box>
      <Typography component="body1">
        Didn't receive the OTP?{" "}
        <Button
          sx={{ mr: "10px" }}
          onClick={(e) => {
            e.preventDefault();
            resetOTP();
          }}
        >
          Resend
        </Button>{" "}
      </Typography>
    </DialogContentText>
  );
}
