import React from "react";
import {
  Box,
  Button,
  Stack,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Avatar,
  Card,
  Typography,
  TableContainer,
  Chip,
} from "@mui/material";
import Scrollbar from "../../Scrollbar";
import ListHead from "../../Common/ListHead";
import { Icon } from "@iconify/react";
import { css } from "@emotion/css";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

const TABLE_HEAD = [
  { id: "tasks", label: "Tasks", alignRight: false },
  { id: "start_date", label: "Start Date", alignRight: false },
  { id: "due_date", label: "Due Date", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
];

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 300 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));
function SelectedTable() {
  return (
    <div>
      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ListHead
                // order={order}
                // orderBy={orderBy}
                headLabel={TABLE_HEAD}
                styles={{ backgroundColor: "rgb(243, 246, 248)" }}

                // numSelected={selected.length}
                // onRequestSort={handleRequestSort}
                // onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                <TableRow
                  key={1}
                  tabIndex={-1}
                  role="checkbox"
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell align="left">
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box>it's project only for testing</Box>
                      <Box sx={{ fontSize: "18px", color: "rgb(156 160 163)" }}>
                        <Icon icon="bx:message-detail" />
                      </Box>
                    </Stack>
                  </TableCell>

                  <TableCell align="left">May 12, 2020</TableCell>

                  <TableCell align="left">July 10,2020</TableCell>

                  <TableCell align="left">
                    <Chip
                      className={css`
                        font-size: 12px;
                        border: none;
                        text-transform: capitalize;
                      `}
                      size="small"
                      variant="outlined"
                      label="In Progress"
                      sx={{
                        color:
                          "In Progress" == "active" ? "#11a54a" : "#fe4842",
                        backgroundColor:
                          "In Progress" == "active" ? "#eff8f1" : "#fdd8de",
                      }}
                    />
                  </TableCell>
                </TableRow>

                <TableRow
                  key={1}
                  tabIndex={-1}
                  role="checkbox"
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell align="left">
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box>it's project only for testing</Box>
                      <Box sx={{ fontSize: "18px", color: "rgb(156 160 163)" }}>
                        <Icon icon="bx:message-detail" />
                      </Box>
                    </Stack>
                  </TableCell>

                  <TableCell align="left">May 12, 2020</TableCell>

                  <TableCell align="left">July 10,2020</TableCell>

                  <TableCell align="left">
                    {" "}
                    <Chip
                      className={css`
                        font-size: 12px;
                        border: none;
                        text-transform: capitalize;
                      `}
                      size="small"
                      variant="outlined"
                      label="Completed"
                      sx={{
                        color: "#11a54a",
                        backgroundColor: "#eff8f1",
                      }}
                    />
                  </TableCell>
                </TableRow>

                <TableRow
                  key={1}
                  tabIndex={-1}
                  role="checkbox"
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell align="left">
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box>it's project only for testing</Box>
                      <Box sx={{ fontSize: "18px", color: "rgb(156 160 163)" }}>
                        <Icon icon="bx:message-detail" />
                      </Box>
                    </Stack>
                  </TableCell>

                  <TableCell align="left">May 12, 2020</TableCell>

                  <TableCell align="left">July 10,2020</TableCell>

                  <TableCell align="left">
                    {" "}
                    <Chip
                      className={css`
                        font-size: 12px;
                        border: none;
                        text-transform: capitalize;
                      `}
                      size="small"
                      variant="outlined"
                      label="Completed"
                      sx={{
                        color: "#11a54a",
                        backgroundColor: "#eff8f1",
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </div>
  );
}

export default SelectedTable;
