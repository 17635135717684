import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  Stack,
  Avatar,
  Typography,
  Box,
  Container,
  Button,
  Grid,
  IconButton,
  Paper,
  Divider,
} from "@mui/material";
import Page from "../../components/Page";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllGlobalTicketsDataByTicketIdThunk,
  createGlobalSupportTicketCommentThunk,
  updateTicketStatusThunkNew,
} from "../../store/slices/support/support.slice";
import account from "../../mock-data/account";
import { styled } from "@mui/material/styles";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import { makeStyles } from "@mui/styles";
import useUser from "../../hooks/useUser";
import { DateTime } from "luxon";
import Element from "../../components/Form/Element";
import { inputType } from "../../utils/enum";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import ChatLoader from "../../components/Skeletons/ChatLoader";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DeleteAction from "../../components/Common/DeleteAction";
import "../../pages/Contact/contact.theme.css";

export default function ContactChatTicket() {
  const navigate = useNavigate();
  const [selectedTicket, setSelectedTicket] = useState();
  const [selectedTicketStatus, setselectedTicketStatus] = useState(true);
  const [comment, setComment] = useState("");
  const params = useParams();
  const dispatch = useDispatch();
  const {
    comments_data,
    ticket_created_at,
    ticket_id,
    title,
    description,
    attachments,
  } = useSelector(({ support }) => support.comments);
  console.log(attachments);

  function onSubmit() {
    if (!comment) return;
    dispatch(
      createGlobalSupportTicketCommentThunk({
        support_id: params.ticketId,
        description: comment,
      })
    );
    setComment("");
  }

  const user = useUser();
  const userId = user.id;

  function handleDelete() {
    dispatch(
      updateTicketStatusThunkNew({
        support_id: params.ticketId,
        state: "closed",
      })
    )
      .unwrap()
      .then((d) => {
        setselectedTicketStatus(d.state == "open" ? true : false);
      });
  }

  // function fncMarkAsResolved() {
  //   dispatch(
  //     updateTicketStatusThunkNew({
  //       support_id: params.ticketId,
  //       state: "closed",
  //     })
  //   )
  //     .unwrap()
  //     .then((d) => {
  //       setselectedTicketStatus(d.state == "open" ? true : false);
  //     });
  // }

  useEffect(() => {
    dispatch(
      fetchAllGlobalTicketsDataByTicketIdThunk({
        support_id: params.ticketId,
        project_id: params.id,
      })
    )
      .unwrap()
      .then((d) => {
        setselectedTicketStatus(d.state == "open" ? true : false);
        setSelectedTicket(d.title);
      });
  }, [params, dispatch]);

  const Input = styled("input")({
    display: "none",
  });

  const useStyles = makeStyles({
    button: {
      color: "#fff",
      backgroundColor: "#0044E3",
      "&:hover": {
        backgroundColor: "#0044E3",
        boxShadow: "0 8px 16px 0 rgb(0 68 226 / 25%)",
      },
      height: "42px",
    },
    inputDiv: {
      "& div": {
        marginBottom: "8px !important",
      },
    },
  });
  const classes = useStyles();

  return (
    <Page title="Tickets Detail | EMB">
      <Container sx={{ margin: 0 }}>
        <Typography
          variant="h4"
          pb={1}
          className="contactChatTicket-Desktop-Header"
        >
          {selectedTicket}
        </Typography>
        <Grid container className="contactChatTicket-Desktop-Back">
          <Grid item xs={6}>
            <Button
              variant="text"
              sx={{ color: "black", cursor: "pointer" }}
              startIcon={<ArrowBackIosIcon />}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>

        <Grid container className="contactChatTicket-Mobile-Back">
          <Grid xs={12}>
            <Button
              variant="text"
              sx={{ color: "black", cursor: "pointer" }}
              startIcon={<ArrowBackIosIcon />}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <p style={{ marginTop: "10px" }}>{selectedTicket}</p>
          </Grid>
          <Grid xs={6}></Grid>
        </Grid>

        <div className="message-view">
          <Grid container spacing={2} mt={0}>
            <Grid item md={12}>
              <Card sx={{ backgroundColor: "#f5f6f8", boxShadow: "none" }}>
                <Box
                  className="paper-grid"
                  // p={2}
                  sx={{
                    backgroundColor: "#fff",
                    height: "500px",
                    overflow: "auto",
                  }}
                >
                  <Grid container>
                    <Grid item md={12}>
                      <Paper sx={{ background: "transparent" }}>
                        <Stack
                          justifyContent="space-between"
                          direction="row"
                          alignItems="flex-start"
                          spacing={2}
                          mb={2}
                          className="main_content"
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "5px",
                              marginBottom: "0.5rem",
                              width: "70%",
                            }}
                          >
                            <Typography
                              variant="button"
                              sx={{ fontWeight: "600" }}
                            >
                              Ticket Id :{" "}
                              <span
                                className="contactchatticket-ticket"
                                style={{ fontWeight: "500" }}
                              >
                                {ticket_id}
                              </span>
                            </Typography>

                            <Typography
                              variant="button"
                              sx={{ fontWeight: "600" }}
                            >
                              Date Created :{" "}
                              <span style={{ fontWeight: "500" }}>
                                {DateTime.fromISO(ticket_created_at).toFormat(
                                  "DD"
                                )}
                              </span>
                            </Typography>
                          </div>
                          <div
                            className="contactchatticket-file-attachment-Header"
                            style={{ width: "30%" }}
                          >
                            <div className="contactchatticket-file-attachment">
                              <Typography
                                variant="button"
                                sx={{ fontWeight: "600" }}
                              >
                                Files Attached
                              </Typography>
                            </div>
                            <div className="contactchatticket-file-attachment-data">
                              {attachments?.length == 0
                                ? ""
                                : attachments?.map((data) => (
                                    <a
                                      href={data.attachment}
                                      target="_blank"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <Paper
                                        sx={{
                                          display: "flex",
                                          flexWrap: "wrap",
                                          gap: "5px",
                                          color: "#212B36",
                                          opacity: "0.6",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <InsertDriveFileOutlinedIcon
                                            sx={{ fontSize: "1rem" }}
                                          />
                                        </div>
                                        <div>
                                          <Typography
                                            variant="body1"
                                            style={{
                                              textOverflow: "ellipsis",
                                              overflow: "hidden",
                                              whiteSpace: "nowrap",
                                              width: "100px",
                                            }}
                                          >
                                            {data.filename}
                                          </Typography>
                                        </div>
                                      </Paper>
                                    </a>
                                  ))}
                            </div>
                          </div>
                        </Stack>

                        {description == null ? (
                          ""
                        ) : (
                          <div
                            className="description-wrapper"
                            style={{ marginBottom: "1rem" }}
                          >
                            <Typography
                              variant="button"
                              sx={{ fontWeight: "600" }}
                            >
                              Description :
                            </Typography>
                            <Typography variant="body2">
                              {description}
                            </Typography>
                          </div>
                        )}
                        {selectedTicketStatus && (
                          <div className="btnmarkasresolved">
                            {/* <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                        className="markasresolved"
                        onClick={fncMarkAsResolved}
                      >
                        Mark as resolved
                      </Button> */}
                            <DeleteAction
                              handleDelete={() => handleDelete()}
                              fromScreen="Mark as Resolved"
                            />
                          </div>
                        )}
                        <Divider sx={{ mb: "2rem" }} />
                      </Paper>
                    </Grid>
                    {comments_data?.map((data) =>
                      data.comment_by === userId ? (
                        <Grid item md={12} mb={3} sx={{ textAlign: "right" }}>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Avatar src={user.profileUrl} alt="client image" />
                            <Typography
                              variant="body1"
                              className="chatusername"
                              sx={{
                                color: "#237DD8",
                                font: "normal normal 600 24px/33px",
                              }}
                            >
                              You
                            </Typography>
                            <Typography
                              className="chatrepliedon"
                              variant="body2"
                              sx={{ paddingRight: "1.5rem", color: "#AAAAAA" }}
                            >
                              replied on{" "}
                              <span
                                className="chatrepliedon"
                                style={{
                                  color: "#212b36",
                                  font: "normal normal 600 24px/33px",
                                  paddingLeft: "5px",
                                }}
                              >
                                {DateTime.fromISO(data.created_at).toFormat(
                                  "DD"
                                )}
                              </span>
                            </Typography>
                          </div>

                          <div
                            className="mob_chat"
                            style={{
                              color: "#454e57",
                              backgroundColor: "#F4F6F8",
                              padding: "10px 1rem",
                              borderRadius: "10px",
                              marginRight: "1rem",
                              marginLeft: "3rem",
                              textAlign: "left",
                              // width: "100%",
                            }}
                          >
                            <Typography className="chat-description" sx={{}}>
                              {data.description}
                            </Typography>
                            <Typography
                              variant="body2"
                              className="chatTime"
                              sx={{
                                paddingRight: "1.5rem",
                                color: "#707880",
                                textAlign: "right",
                              }}
                            >
                              {DateTime.fromISO(data.created_at).toFormat(
                                "HH:mm a"
                              )}
                            </Typography>
                          </div>
                        </Grid>
                      ) : (
                        <Grid item md={12} mb={3} sx={{ textAlign: "right" }}>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Avatar src={account.photoURL} alt="client image" />
                            <Typography
                              variant="body1"
                              className="chatusername"
                              sx={{
                                color: "#237DD8",
                                font: "normal normal 600 24px/33px",
                              }}
                            >
                              {data.username}
                            </Typography>
                            <Typography
                              variant="body2"
                              className="chatrepliedon"
                              sx={{ paddingRight: "1.5rem", color: "#707880" }}
                            >
                              replied on{" "}
                              <span
                                className="chatrepliedon"
                                style={{
                                  color: "#222",
                                  font: "normal normal 600 24px/33px",
                                }}
                              >
                                {DateTime.fromISO(data.created_at).toFormat(
                                  "DD"
                                )}
                              </span>
                            </Typography>
                          </div>
                          <div
                            style={{
                              color: "#454e57",
                              backgroundColor: "#F5FAFF",
                              padding: "1rem",
                              borderRadius: "10px",
                              marginRight: "1rem",
                              marginLeft: "3rem",
                              textAlign: "left",
                            }}
                          >
                            <Typography className="chat-description" sx={{}}>
                              {data.description}
                            </Typography>
                            <Typography
                              className="chatTime"
                              variant="body2"
                              sx={{
                                paddingRight: "1.5rem",
                                color: "#707880",
                                textAlign: "right",
                              }}
                            >
                              {DateTime.fromISO(data.created_at).toFormat(
                                "HH:mm a"
                              )}
                            </Typography>
                          </div>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Box>
              </Card>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Card
              className="clsSendMessage"
              sx={{
                backgroundColor: "",
                padding: 1,
                width: "100%",
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={5}
                sx={{ paddingBottom: "0px !important ", width: "100%" }}
                className={classes.inputDiv}
              >
                <Element
                  placeholder="Enter your message here..."
                  eletype={inputType.input}
                  inputProps={{
                    onChange: (e) => setComment(e.target.value),
                    name: "message",
                  }}
                  value={comment}
                  styles={{
                    background: "#fff",
                    boxShadow: "0px 0px 6px #71717129",
                    borderRadius: "12px",
                    opacity: "0.75",
                    width: "100%",
                    textAlign: "left",
                    marginLeft: "1rem",
                  }}
                />
                <Button
                  variant="contained"
                  endIcon={<SendIcon />}
                  className={classes.button}
                  onClick={onSubmit}
                  className="send-button"
                >
                  Send
                </Button>
              </Stack>
            </Card>
          </Grid>
        </div>
      </Container>
    </Page>
  );
}
