import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Button } from "@mui/material";
import ConfirmationModal from "../Modal/ConfirmationModal";

function DeleteAction({ handleDelete, fromScreen }) {
  const [isActionAllowed, setIsActionAllowed] = useState(false);
  const [showModal, setShowModal] = useState(false);

  function _handleDelete() {
    setShowModal(true);
  }

  function userAction(feedback) {
    if (feedback) setIsActionAllowed(true);
    setShowModal(false);
  }

  useEffect(() => {
    if (isActionAllowed) {
      handleDelete();
    }
  }, [isActionAllowed]);

  return (
    <>
      {showModal && (
        <ConfirmationModal
          successCallback={() => userAction(true)}
          closeModal={() => setShowModal(false)}
          isOpen={showModal}
          fromScreen={fromScreen}
        />
      )}
      {fromScreen ? (
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          className="markasresolved"
          onClick={_handleDelete}
        >
          Mark as resolved
        </Button>
      ) : (
        <IconButton color="error" component="span" onClick={_handleDelete}>
          <DeleteIcon
            color="error"
            sx={{
              cursor: "pointer",
              zIndex: "999",
            }}
          />
        </IconButton>
      )}
    </>
  );
}

export default DeleteAction;
