import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  faq_card: {
    padding: "6rem",
    backgroundColor: "#f3f6f8",
    backgroundImage:
      // "url(https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/banner.c2d23a97.png)",
      "url(/static/faq-01.svg)",

    backgroundPosition: "center",
    backgroundSize: "cover",
    boxShadow: "0 4px 24px 0 rgb(34 41 47 / 10%)",
    marginTop: "40px",
  },
  search: {
    marginTop: "20px",
    background: "#fff",

    "&:hover": {
      background: "#fff",
    },
    borderRadius: "50px",
    boxShadow:
      "0 2px 6px 0 rgb(136 148 171 / 20%), 0 24px 20px -24px rgb(71 82 107 / 10%)",
  },
  search_input: {
    width: "100%",
    height: "60px",
  },

  "@media (max-width:600px)": {
    faq_card: {
      //   display: "none",
      padding: "0rem!important",
      backgroundColor: "#fff",
      borderRadius: "8px",
      backgroundImage:
        // "url(https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/banner.c2d23a97.png)",
        "none",
      // backgroundPosition: "center",
      // backgroundSize: "cover",
      boxShadow: "none",
    },
    d_none: {
      display: "none",
    },
    search: {
      background: "#F0F0F0",
      "&:hover": {
        background: "#F0F0F0",
      },
      borderRadius: "12px",
      boxShadow: "none",
      marginTop: "0px!important",
      width: "100vw",
    },
    search_icon: {
      marginLeft: "20px",
      color: "#898E93",
    },
    search_input: {
      marginLeft: "40px",
    },
    faq_img: {
      display: "none",
    },
    faq_img_mob: {
      height: "30px",
      width: "30px",
      background: "#EAF5F7",
      borderRadius: "50%",
      //   display: "block",
    },
  },
});
