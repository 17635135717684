import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { sentenceCase } from "change-case";
import { useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink } from "react-router-dom";
import { capitalCase } from "../../utils/change-case";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  CardContent,
  Autocomplete,
  TextField,
  Chip,
  Grid,
} from "@mui/material";

// components
import Page from "../../components/Page";
import Label from "../../components/Label";
import Scrollbar from "../../components/Scrollbar";
import SearchNotFound from "../../components/SearchNotFound";
import { UserMoreMenu } from "../../components/_dashboard/user";
//
// import USERLIST from "../../mock-data/user";
import { useDispatch, useSelector } from "react-redux";
import ListHead from "../../components/Common/ListHead";
import ListSearchbar from "../../components/Common/ListSearchbar";
import Modal from "./../../components/Modal/Modal";
import RoleModal from "./../../components/Modal/RoleModal";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { deleteUser } from "../../store/slices/user/user.slice";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  // { id: "name", label: "Name", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "designation", label: "Designation", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "createdAt", label: "Created At", alignRight: false },
  { id: "" },
];

const TABLE_ROLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  // { id: "privilege", label: "Privilege", alignRight: false },
  { id: "UserAssociated", label: "User Associated", alignRight: false },
  { id: "designation", label: "Designation", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "createdAt", label: "Created At", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
function getInitials(project_name) {
  return capitalCase(project_name.substring(0, 2));
}

export default function User() {
  // const userData = useSelector((state) => state.user);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showModal, setShowModal] = useState(false);

  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(
  //     loginUserAsyncThunk({
  //       username: "abc",
  //       password: "123",
  //     })
  //   );
  // }, []);

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  // };
  const USERLIST = useSelector(({ user }) => user.clientUsers);
  const ROLELIST = useSelector(({ user }) => user.clientUsers);
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // const handleFilterByName = (event) => {
  //   setFilterName(event.target.value);
  // };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(
    USERLIST,
    getComparator(order, orderBy),
    filterName
  );

  const selectValue = [
    { title: "Amadeus", year: 1984 },
    { title: "To Kill a Mockingbird", year: 1962 },
    { title: "Toy Story 3", year: 2010 },
    { title: "Logan", year: 2017 },
    { title: "Full Metal Jacket", year: 1987 },
    { title: "Dangal", year: 2016 },
    { title: "The Sting", year: 1973 },
    { title: "2001: A Space Odyssey", year: 1968 },
    { title: "Singin' in the Rain", year: 1952 },
    { title: "Toy Story", year: 1995 },
    { title: "Bicycle Thieves", year: 1948 },
    { title: "The Kid", year: 1921 },
    { title: "Inglourious Basterds", year: 2009 },
    { title: "Snatch", year: 2000 },
    { title: "3 Idiots", year: 2009 },
    { title: "Monty Python and the Holy Grail", year: 1975 },
  ];

  const dropValue = {
    options: selectValue,
    getOptionLabel: (option) => option.title,
  };
  const isUserNotFound = filteredUsers.length === 0;

  const handleDelete = () => {};
  return (
    <Page title="User | Minimal-UI">
      <Container>
        <Stack mb={2}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          <Breadcrumb />
        </Stack>

        <Card>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              display: { lg: "flex", md: "flex", sm: "block", xs: "block" },
            }}
          >
            <ListSearchbar placeholder="Search users" />
            <Stack
              mr={3}
              direction="row"
              spacing={2}
              sx={{ marginLeft: { sm: "23px", xs: "23px" } }}
            >
              <Button
                variant="contained"
                component={RouterLink}
                to="/dashboard/user/createUser"
                startIcon={<Icon icon={plusFill} />}
              >
                New User
              </Button>
              <Button
                variant="contained"
                onClick={handleOpen}
                startIcon={<Icon icon={plusFill} />}
              >
                Add Role
              </Button>
            </Stack>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead
                  // order={order}
                  // orderBy={orderBy}
                  // headCheckBox
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  // onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        id,
                        name,
                        role,
                        status,
                        company,
                        avatarUrl,
                        isVerified,
                      } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, name)}
                            />
                          </TableCell> */}
                          <TableCell component="th" scope="row">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Avatar
                                sx={{
                                  // bgcolor: "#eff8f1",
                                  // color: "#00aa55",
                                  fontSize: ".8rem",
                                  fontWeight: "600",
                                  backgroundColor: "#f3f6f8",
                                  color: "#637281",
                                }}
                              >
                                {getInitials(name)}
                              </Avatar>
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{company}</TableCell>
                          <TableCell align="left">{role}</TableCell>
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={
                                (status === "banned" && "error") || "success"
                              }
                            >
                              {sentenceCase(status)}
                            </Label>
                          </TableCell>
                          <TableCell align="left">
                            {isVerified ? "Yes" : "No"}
                          </TableCell>

                          <TableCell align="right">
                            <UserMoreMenu
                              handleDelete={() => dispatch(deleteUser(row.id))}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>

        {/* div between the table */}

        <Card sx={{ marginTop: "50px", padding: "50px;" }}>
          <CardContent>
            <Grid item xs={12} sm={12} md={8}>
              <Autocomplete
                {...dropValue}
                id="select-on-focus"
                selectOnFocus
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="selectOnFocus"
                    variant="standard"
                  />
                )}
              />

              <Stack direction="row" spacing={1} mt={3}>
                <Chip
                  label="Clickable Deletable"
                  onClick={handleClick}
                  onDelete={handleDelete}
                />
                <Chip
                  label="Clickable Deletable"
                  variant="outlined"
                  onClick={handleClick}
                  onDelete={handleDelete}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={8} mt={5}>
              <Autocomplete
                {...dropValue}
                id="select-on-focus"
                selectOnFocus
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="selectOnFocus"
                    variant="standard"
                  />
                )}
              />

              <Stack direction="row" spacing={1} mt={3}>
                <Chip
                  label="Clickable Deletable"
                  onClick={handleClick}
                  onDelete={handleDelete}
                />
                <Chip
                  label="Clickable Deletable"
                  variant="outlined"
                  onClick={handleClick}
                  onDelete={handleDelete}
                />
              </Stack>
            </Grid>
          </CardContent>
        </Card>

        {/* update roles */}
        <Stack mt={10} mb={2}>
          <Typography variant="h4" gutterBottom>
            Roles
          </Typography>
          <Breadcrumb />
        </Stack>
        <Card>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              display: { lg: "flex", md: "flex", sm: "block", xs: "block" },
            }}
          >
            <ListSearchbar placeholder="Search users" />
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead
                  // order={order}
                  // orderBy={orderBy}
                  // headCheckBox
                  headLabel={TABLE_ROLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  // onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        id,
                        name,
                        role,
                        status,
                        company,
                        avatarUrl,
                        isVerified,
                      } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, name)}
                            />
                          </TableCell> */}
                          <TableCell component="th" scope="row">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar
                                sx={{
                                  // bgcolor: "#eff8f1",
                                  // color: "#00aa55",
                                  fontSize: ".8rem",
                                  fontWeight: "600",
                                  backgroundColor: "#f3f6f8",
                                  color: "#637281",
                                }}
                              >
                                {getInitials(name)}
                              </Avatar>
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{company}</TableCell>
                          <TableCell align="left">{role}</TableCell>
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={
                                (status === "banned" && "error") || "success"
                              }
                            >
                              {sentenceCase(status)}
                            </Label>
                          </TableCell>
                          <TableCell align="left">
                            {isVerified ? "Yes" : "No"}
                          </TableCell>

                          <TableCell align="right">
                            <UserMoreMenu
                              handleDelete={() => dispatch(deleteUser(row.id))}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
      <Modal
        isOpen={showModal}
        closeModal={handleClose}
        title="Add role"
        content={<RoleModal />}
        dialogProps={{ fullWidth: true }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{ justifyContent: "center", marginBottom: 2 }}
        action={
          <Button size="large" type="submit" variant="contained">
            Add role
          </Button>
        }
      />
    </Page>
  );
}
