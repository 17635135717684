import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  "@media (max-width:1100px)": {
    projectHeading: {
      fontSize: "1.1rem",
      marginBottom: "0px",
    },
    projectContainer: {
      margin: "20px",
    },
    tableDiv: {
      margin: "15px",
    },
  },
});
