import { Link as RouterLink, Navigate } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Link, Container, Typography } from "@mui/material";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import { MHidden } from "../../components/@material-extend";
import { LoginForm } from "../../components/authentication/login";
import AuthSocial from "../../components/authentication/AuthSocial";
import AuthMobile from "../../components/authentication/AuthMobile";
import withAuthLoader from "../../hoc/withAuthLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  closeOTPModal,
  resendEmailOTPAsyncThunk,
  validateOTPAsyncThunk,
} from "../../store/slices/auth/auth.slice";
import { useEffect } from "react";
import Modal from "../../components/Modal/Modal";
import OTPModal from "../../components/Modal/OtpModal";
import { otpType } from "../../utils/enum";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

function Login() {
  const { showOTPModal, digitsInOTP } = useSelector(({ auth }) => auth);
  const { profile } = useSelector(({ user }) => user.userData);
  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn);
  const dispatch = useDispatch();

  function closeModal() {
    dispatch(closeOTPModal());
  }

  useEffect(() => {
    return function () {
      dispatch(closeOTPModal());
    };
  }, []);

  function validateOTP(otp) {
    let type = otpType.email;
    if (digitsInOTP === 6) type = otpType.mobile;
    dispatch(
      validateOTPAsyncThunk({
        otp,
        type,
        ...profile,
      })
    );
  }

  function resetOTP() {
    dispatch(resendEmailOTPAsyncThunk(profile));
  }

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Modal
        isOpen={showOTPModal}
        closeModal={closeModal}
        content={
          <OTPModal
            validateOTP={validateOTP}
            resetOTP={resetOTP}
            digitsInOTP={digitsInOTP}
          />
        }
      />
      <RootStyle title="Login | EMB">
        {/*<AuthLayout>
          Don’t have an account? &nbsp;
          <Link
            underline="none"
            variant="subtitle2"
            component={RouterLink}
            to="/register"
          >
            Get started
          </Link>
        </AuthLayout>*/}

        <MHidden width="mdDown">
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img
              src="/static/vectors/login.svg"
              alt="login"
              style={{ padding: "40px" }}
            />
          </SectionStyle>
        </MHidden>

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack sx={{ mb: 5 }}>
              <Typography variant="h4" gutterBottom>
                Sign In
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                Enter your details below.
              </Typography>
            </Stack>
            {/*<AuthSocial />*/}
            {/*<AuthMobile />*/}

            <LoginForm />

            <MHidden width="smUp">
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?&nbsp;
                <Link variant="subtitle2" component={RouterLink} to="register">
                  Get started
                </Link>
              </Typography>
            </MHidden>
          </ContentStyle>
        </Container>
      </RootStyle>
    </>
  );
}

export default withAuthLoader(Login);
