import React, { useRef, useState } from "react";
import {
  Typography,
  Container,
  Card,
  Box,
  Grid,
  Stack,
  Button,
  Avatar,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
// components
import { green } from "@mui/material/colors";
import Page from "./../../components/Page";
import Element from "./../../components/Form/Element";
import { inputType, otpType } from "../../utils/enum";
import { Formik } from "formik";
import Address from "../../components/Form/Address";
import MobileInput from "../../components/MobileInput";
import { useDispatch, useSelector } from "react-redux";
import {
  uploadProfileInfoThunk,
  uploadProfileThunk,
} from "../../store/slices/user/user.slice";
import Modal from "../../components/Modal/Modal";
import OTPModal from "../../components/Modal/OtpModal";
import {
  closeOTPModal,
  resendEmailOTPAsyncThunk,
  sendEmailVerificationOtpThunk,
  sendMobileVerificationOtpThunk,
  validateOTPVerifyOnlyThunk,
} from "../../store/slices/auth/auth.slice";
import { profileSchema } from "../../utils/validation";
import useUser from "../../hooks/useUser";

export default function EditPersonalDetails({ handleprofileEditMode, data }) {
  const dispatch = useDispatch();
  const { showOTPModal, digitsInOTP, mobileSession } = useSelector(
    ({ auth }) => auth
  );
  const [values, setValues] = useState({});
  const [ref, setRef] = useState(data);

  const handleSubmit = (values) => {
    if (ref.email !== values.email) {
      setValues(values);
      dispatch(sendEmailVerificationOtpThunk(values.email));
      return;
    }

    if (ref.mobile?.mobile_number !== values.mobile?.mobile_number) {
      setValues(values);
      dispatch(sendMobileVerificationOtpThunk(values.mobile));
      return;
    }

    dispatch(uploadProfileInfoThunk(values))
      .unwrap()
      .then(() => {
        handleprofileEditMode(false);
      });
  };

  const user = useUser();

  function handleFileChange(e) {
    var formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_type", "profile");
    dispatch(uploadProfileThunk(formData));
  }

  const validateOTP = (otp) => {
    let type = otpType.email;
    if (digitsInOTP === 6) type = otpType.mobile;
    dispatch(
      validateOTPVerifyOnlyThunk({
        otp,
        type,
        verifyOnly: true,
        email: values.email,
        country_code: values.mobile?.country_code,
        mobile_number: values.mobile?.mobile_number,
        ...mobileSession,
      })
    )
      .unwrap()
      .then(() => {
        setRef((ref) => ({
          ...ref,
          email: digitsInOTP === 4 ? values.email : ref.email,
          mobile:
            digitsInOTP === 6
              ? {
                  country_code: values.mobile?.country_code,
                  mobile_number: values.mobile?.mobile_number,
                }
              : ref.mobile,
        }));
      });
  };

  const inputRef = useRef();

  function resetOTP() {
    dispatch(resendEmailOTPAsyncThunk());
  }

  function closeModal() {
    dispatch(closeOTPModal());
  }

  return (
    <>
      <Modal
        isOpen={showOTPModal}
        closeModal={closeModal}
        content={
          <OTPModal
            validateOTP={validateOTP}
            resetOTP={resetOTP}
            digitsInOTP={digitsInOTP}
          />
        }
      />
      <Page title="My Profile | EMB">
        <input
          type="file"
          style={{ display: "none" }}
          ref={inputRef}
          onChange={handleFileChange}
        />
        <Box>
          <Grid container spacing={2}>
            {/* <Grid item md={4}>
                <Card sx={{ minHeight: "420px" }}>
                  <Box sx={{ padding: "30px 0px" }}>
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{ color: "rgb(99, 115, 129)" }}
                      my={3}
                    >
                      <IconButton
                        aria-label="upload picture"
                        component="span"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          my: "1rem",
                        }}
                        onClick={() => inputRef.current.click()}
                      >
                      </IconButton>
                    </Stack>
                    <Avatar
                      alt="Profile-image"
                      src={
                        user.profileUrl
                          ? user.profileUrl
                          : "https://cdn.worldvectorlogo.com/logos/israel-aircraft-unit-2.svg"
                      }
                      sx={{
                        marginTop: "10px",
                        width: 144,
                        height: 144,
                        mx: "auto",
                        borderRadius: "50%",
                        border: "1px dashed rgba(145, 158, 171, 0.32)",
                        backgroundColor: "rgb(244, 246, 248)",
                        cursor: "pointer",
                      }}
                      onClick={() => inputRef.current.click()}
                    />

                    <Box
                      sx={{
                        width: "60%",
                        textAlign: "center",
                        mx: "auto",
                        pt: "1rem",
                        color: "rgb(99, 115, 129)",
                      }}
                    >
                    </Box>
                  </Box>
                </Card>
              </Grid> */}

            <Grid item md={12}>
              <Formik
                initialValues={{
                  first_name: data.first_name,
                  last_name: data.last_name,
                  email: data.email,
                  mobile: data?.mobile,
                  designation: data.designation,
                  address: {
                    address_line1: data.address_line1,
                    address_line2: data.address_line2,
                    zip_code: data.zip_code,
                    state: data.state,
                    country: data.country,
                    city: data.city,
                  },
                }}
                onSubmit={handleSubmit}
                validationSchema={profileSchema}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  touched,
                  errors,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box>
                      <Stack
                        direction="row"
                        justifyContent="space-evenly"
                        spacing={2}
                      >
                        <Element
                          value={values.first_name}
                          eletype={inputType.input}
                          label="First Name"
                          inputProps={{
                            name: "first_name",
                            onChange: handleChange,
                            onBlur: handleBlur,
                          }}
                          error={Boolean(
                            touched.first_name && errors.first_name
                          )}
                          errorText={touched.first_name && errors.first_name}
                        />
                        <Element
                          value={values.last_name}
                          eletype={inputType.input}
                          label="Last Name"
                          inputProps={{
                            name: "last_name",
                            onChange: handleChange,
                            onBlur: handleBlur,
                          }}
                        />
                      </Stack>
                    </Box>

                    <Box>
                      <Stack
                        direction="row"
                        justifyContent="space-evenly"
                        spacing={2}
                      >
                        <Element
                          value={values.email}
                          eletype={inputType.input}
                          label="Email Address"
                          inputProps={{
                            name: "email",
                            onChange: handleChange,
                            onBlur: handleBlur,
                          }}
                        />

                        <MobileInput
                          name="mobile"
                          label="Mobile Number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.mobile}
                        />
                      </Stack>
                    </Box>

                    <Box>
                      <Stack
                        direction="row"
                        justifyContent="space-evenly"
                        spacing={2}
                      >
                        <Element
                          eletype={inputType.input}
                          label="PAN Number"
                          inputProps={{
                            name: "pan_no_of_account",
                            onChange: handleChange,
                            onBlur: handleBlur,
                          }}
                        />
                      </Stack>
                    </Box>
                    <Box>
                      <Stack
                        direction="row"
                        justifyContent="space-evenly"
                        spacing={2}
                      >
                        <Element
                          value={values.designation}
                          eletype={inputType.input}
                          label="Designation"
                          inputProps={{
                            name: "designation",
                            onChange: handleChange,
                            onBlur: handleBlur,
                          }}
                        />
                      </Stack>
                    </Box>

                    <Address
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      values={values.address}
                    />

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "20px 0",
                      }}
                    >
                      <Stack direction="row" spacing={1}>
                        <Button
                          type="submit"
                          color="secondary"
                          variant="contained"
                          sx={{
                            background: green[500],
                            ":hover": { background: green[300] },
                          }}
                        >
                          Save
                        </Button>
                      </Stack>
                    </Box>
                  </form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Box>
      </Page>
    </>
  );
}
