import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Box, Card, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import { useStyles } from "./AppWeeklySales.theme";

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: "#f3f6f8",
}));

export default function AppWeeklySales() {
  const user = useUser();
  const classes = useStyles();

  return (
    <RootStyle align="left">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={7} className={classes.hero}>
          <Typography className={classes.head} align="left">
            Welcome back! <br />
            {user.fullName}
          </Typography>
          <Typography className={classes.subTitle} align="left">
            Stay connected with us and keep a close watch on the progress of
            your project!
          </Typography>
          <Box textAlign="left">
            <Link
              to="/dashboard/projects"
              underline="none"
              style={{ textDecoration: "none" }}
            >
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                className={classes.Button}
              >
                Track
              </Button>
            </Link>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={5} pt={3}>
          <Box className={classes.head_img}>
            <img
              src="/static/welcome_client.svg"
              width="240"
              height="240"
              alt=""
            />
          </Box>
        </Grid>
      </Grid>
    </RootStyle>
  );
}
