import React, { useEffect, useState } from "react";
// import { vendorAxios } from "../../utils/axios/vendor.axios";

import { useStyles } from "./Form.theme";

function FileInput({ label, onChange, onBlur, name, errorText }) {
  const classes = useStyles();
  const [file, setFile] = useState([]);

  async function handleChange(e) {
    const currentFile = e.target.files[0];
    onChange({
      target: {
        value: [...file, currentFile],
        name,
      },
    });
    setFile((files) => [...files, currentFile]);
  }

  function getFilename() {
    var content = "";
    if (file.filename?.length > 20) {
      content = file?.filename?.substring(0, 20) + "...";
    } else content = file.filename;

    if (file.url) {
      return (
        <p className={classes.filename}>
          <a href={file.url} style={{ textDecoration: "none", color: "grey" }}>
            {content}
          </a>
        </p>
      );
    }
    return <p className={classes.filename}>{content}</p>;
  }

  return (
    <>
      <label className={classes.formLabel}>{label}</label>
      <div class="file-upload">
        <div class="file-select">
          <div class="file-select-button" id="fileName">
            Upload
          </div>
          <div class="file-select-name" id="noFile">
            Browse File...
          </div>
          <input
            type="file"
            name="chooseFile"
            className={classes.formControl}
            onChange={handleChange}
            onBlur={onBlur}
            accept=".jpeg,.jpg,.png,.pdf"
          />
        </div>
      </div>
      {file?.map((val) => {
        return <p className={classes.filename}>{val.name}</p>;
      })}
      {errorText && <span className={classes.formErrorStyle}>{errorText}</span>}
    </>
  );
}

export default FileInput;
