import * as yup from "yup";

export const addRoleFormValidations = yup.object({
  role: yup.string().required("Role is required"),
  permission: yup.string().required("Permission is required"),
});

export const mobileValidation = yup.object({
  mobile: yup.object({
    mobile_number: yup
      .string()
      .required("Mobile is Required")
      .max(10, "Please Enter a Valid Mobile Number")
      .min(10, "Please Enter a Valid Mobile Number"),
  }),
});

export const createProjectScreen1Validation = yup.object({
  project_name: yup.string().required("Project Title is Required"),
  description: yup.string().required("Please Enter Description"),
});

export const createProjectScreen2Validation = yup.object({
  service: yup.array().min(1, "Please Select a Service"),
  sub_service: yup.array().min(1, "Please Select a Sub Service"),
});

export const createProjectScreen3Validation = yup.object({
  expected_timeline: yup.string().required("Timeline is Required"),
  expected_budget: yup.string().required("Budget is Required"),
  industry: yup.string().required("Industry is Required"),
});

export const createProjectScreen4Validation = yup.object({});

export const profileSchema = yup.object({
  first_name: yup.string().required("First Name is Required"),
});

export const ticketValidations = yup.object({
  title: yup.string().required("Title is Required"),
  description: yup.string().required("Description is Required"),
});

export const addressValidation = yup.object({
  address_line1: yup.string().required("Address Line 1 is Required"),
  state: yup.string().required("State is Required"),
  country: yup.string().required("Country is Required"),
  zip_code: yup
    .string()
    .required("Zip code is Required")
    .matches(/^[0-9]{6}$/, "Please Enter a Valid ZIP Code"),
});

export const forgotPasswordValidation = yup.object({
  email: yup
    .string()
    .email("Email must be a valid email address")
    .required("Email is required"),
});
