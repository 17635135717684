import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function Address2Loader() {
  return (
    <SkeletonTheme baseColor="#edf2f9" highlightColor="#dde6f0" duration={0.7}>
      <div
        className="flex"
        style={{
          justifyContent: "space-between",
          borderRadius: "10px",
          padding: "30px 30px",
          background: "#f3f6f8",
          flexDirection: "column",
          height: "250px",
          position: "relative",
          minHeight: "420px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Skeleton
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "20%",
                marginTop: "5px",
              }}
            />

            <Skeleton
              style={{
                width: "20%",
                height: "2.5rem",
                borderRadius: "5px",
                position: "absolute",
                left: 100,
                marginTop: "-12px",
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Skeleton
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "20%",
                marginTop: "5px",
                marginLeft: "-200px",
              }}
            />

            <Skeleton
              style={{
                width: "20%",
                height: "2.5rem",
                borderRadius: "5px",
                position: "absolute",
                marginLeft: "-125px",
                marginTop: "-12px",
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "50px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Skeleton
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "20%",
                marginTop: "5px",
              }}
            />

            <Skeleton
              style={{
                width: "20%",
                height: "2.5rem",
                borderRadius: "5px",
                position: "absolute",
                left: 100,
                marginTop: "-12px",
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Skeleton
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "20%",
                marginTop: "5px",
                marginLeft: "-200px",
              }}
            />

            <Skeleton
              style={{
                width: "15%",
                height: "2.5rem",
                borderRadius: "5px",
                position: "absolute",
                marginLeft: "-125px",
                marginTop: "-12px",
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "50px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Skeleton
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "20%",
                marginTop: "5px",
              }}
            />

            <Skeleton
              style={{
                width: "40%",
                height: "2.5rem",
                borderRadius: "5px",
                position: "absolute",
                left: 100,
                marginTop: "-12px",
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Skeleton
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "20%",
                marginTop: "5px",
                marginLeft: "-200px",
              }}
            />

            <Skeleton
              style={{
                width: "20%",
                height: "2.5rem",
                borderRadius: "5px",
                position: "absolute",
                marginLeft: "-125px",
                marginTop: "-12px",
              }}
            />
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default Address2Loader;
