import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getWalletDetailsHandler } from "./wallet.thunk";

const INIT_STATE = {
  transactions: [],
  wallet_description: {},
};

export const getWalletDetailsThunk = createAsyncThunk(
  "getWalletDetails",
  getWalletDetailsHandler
);

const walletSlice = createSlice({
  name: "wallet",
  initialState: INIT_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWalletDetailsThunk.fulfilled, (state, action) => {
      state.transactions = action.payload?.data;
      state.wallet_description = action.payload?.wallet_description;
      return state;
    });
  },
});

export const {} = walletSlice.actions;

export default walletSlice.reducer;
