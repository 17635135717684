import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function ChatLoader() {
  return (
    <SkeletonTheme baseColor="#edf2f9" highlightColor="#dde6f0" duration={0.7}>
      <div
        className="flex"
        style={{
          justifyContent: "space-between",
          borderRadius: "10px",
          padding: "30px 30px",
          background: "#f3f6f8",
          flexDirection: "column",
          minHeight: "420px",
          position: "relative",
        }}
      >
        <Skeleton
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            position: "absolute",
            right: 20,
          }}
        />

        <Skeleton
          style={{
            width: "20%",
            borderRadius: "5px",
            position: "absolute",
            right: 80,
            top: 40,
            height: "2rem",
          }}
        />

        <Skeleton
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            position: "absolute",
            right: 20,
            top: 100,
          }}
        />

        <Skeleton
          style={{
            width: "30%",
            borderRadius: "5px",
            position: "absolute",
            right: 80,
            top: 110,
            height: "2rem",
          }}
        />

        <Skeleton
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            position: "absolute",
            right: 20,
            top: 180,
          }}
        />

        <Skeleton
          style={{
            width: "10%",
            borderRadius: "5px",
            position: "absolute",
            right: 80,
            top: 190,
            height: "2rem",
          }}
        />

        {/*  */}
        <div style={{ marginTop: "100px" }}>
          <Skeleton
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
            }}
          />

          <Skeleton
            style={{
              width: "20%",
              borderRadius: "5px",

              height: "2rem",
              position: "absolute",
              left: 100,
              marginTop: "-40px",
            }}
          />

          <Skeleton
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
            }}
          />

          <Skeleton
            style={{
              width: "30%",
              borderRadius: "5px",

              height: "2rem",
              position: "absolute",
              left: 100,
              marginTop: "-40px",
            }}
          />

          <Skeleton
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
            }}
          />

          <Skeleton
            style={{
              width: "10%",
              borderRadius: "5px",

              height: "2rem",
              position: "absolute",
              left: 100,
              marginTop: "-40px",
            }}
          />
        </div>
        <Skeleton
          style={{
            width: "100%",
            borderRadius: "5px",

            height: "3.5rem",
          }}
        />
      </div>
    </SkeletonTheme>
  );
}

export default ChatLoader;
