import { combineReducers } from "@reduxjs/toolkit";

import userReducer from "./slices/user/user.slice";
import projectReducer from "./slices/project/project.slice";
import initReducer from "./slices/init/init.slice";
import authReducer from "./slices/auth/auth.slice";
import toastReducer from "./slices/toast/toast.slice";
import walletReducer from "./slices/wallet/wallet.slice";
import dashboardReducer from "./slices/dashboard/dashboard.slice";
import supportReducer from "./slices/support/support.slice";
import uiReducer from "./slices/ui/ui.slice";

const rootReducer = combineReducers({
  user: userReducer,
  project: projectReducer,
  init: initReducer,
  auth: authReducer,
  toast: toastReducer,
  wallet: walletReducer,
  dashboard: dashboardReducer,
  support: supportReducer,
  uiStyle: uiReducer,
});

export default rootReducer;
