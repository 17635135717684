import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  "@media (max-width:600px)": {
    card_head: {
      display: "none",
    },
    projects: {
      width: "100%",
    },

    description: {
      width: "300px",
    },
    icon: {
      background: "#F4F6F8",
      width: "35px",
      height: "35px",
      padding: "6px",
      borderRadius: "50%",
    },
  },
  "@media (max-width:500px)": {
    description: {
      width: "250px",
    },
  },
  "@media (max-width:400px)": {
    project_name: {
      width: "170px",
    },
    description: {
      width: "180px",
    },
  },
});
