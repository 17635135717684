import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { projectApprovalStatus } from "../../../utils/enum";
import { projectDetailsPage } from "../../../utils/fakeData/tabs.data";
import { getSectionMatrix } from "../../../utils/timeline.utils";
import {
  acceptACharterHandler,
  acceptProposalHandler,
  addCommentHandler,
  addFilesHandler,
  chooseAVendorHandler,
  createProjectHandler,
  createReportedIssue,
  createSupportTicketCommentHandler,
  fetchAllCommentsForTimelineHandler,
  fetchAllFilesForTimelineHandler,
  fetchAllProjectsHandler,
  fetchAllTicketsDataByTicketIdHanldler,
  fetchProjectByIdHandler,
  fetchProjectCharterByProjectIdHandler,
  fetchProjectTimelineHandler,
  getAcceptedVendorsListHandler,
  getAllProposalsHandler,
  getRatingQuestions,
  getReportedIssue,
  getVendorsCharterListHandler,
  rejectACharterHandler,
  rejectProposalHandler,
  submitReviewQuestionsHandler,
  updateProjectTimelineHandler,
  uploadProjectAttachmentHandler,
  fetchAllCollectionsByProjectHandler,
} from "./project.thunk";

var timeline = {
  sections: [],
  columnsIds: [],
};

const INIT_STATE = {
  selectedTab: 0,
  isTimelinePublished: false,
  tabs: projectDetailsPage,
  projects: [],
  selectedProject: {
    overview: {},
    proposals: [],
    questions: [],
    tickets: [],
    comments: {
      comments_data: [],
    },
  },
  selectedCharter: null,
  selectedTimeline: {
    timeline: timeline,
    files: [],
    comments: [],
  },
  gantt: [],
  isLoading: false,
  reviewQuestions: {
    questions: [],
    isReviewGiven: false,
  },
  timelineRef: null,
  collections: [],
  isProjectTablePublished: false,
};

export const fetchAllProjectsThunk = createAsyncThunk(
  "fetchAllProjects",
  fetchAllProjectsHandler
);

export const createProjectThunk = createAsyncThunk(
  "createProject",
  createProjectHandler
);

export const getAllProposalsThunk = createAsyncThunk(
  "getAllProposals",
  getAllProposalsHandler
);

export const changeProposalStatusThunk = createAsyncThunk(
  "changeProposalStatus"
);

export const fetchProjectByIdThunk = createAsyncThunk(
  "fetchProjectById",
  fetchProjectByIdHandler
);

export const uploadProjectAttachmentThunk = createAsyncThunk(
  "uploadProjectAttachment",
  uploadProjectAttachmentHandler
);

export const getRatingQuestionsThunk = createAsyncThunk(
  "getRatingQuestions",
  getRatingQuestions
);

export const getReportedIssueThunk = createAsyncThunk(
  "getReportedIssue",
  getReportedIssue
);

export const addReportIssueThunk = createAsyncThunk(
  "createReportIssue",
  createReportedIssue
);

export const acceptProposalThunk = createAsyncThunk(
  "acceptProposal",
  acceptProposalHandler
);

export const rejectProposalThunk = createAsyncThunk(
  "rejectProposal",
  rejectProposalHandler
);

export const fetchProjectCharterByProjectIdThunk = createAsyncThunk(
  "fetchProjectCharterByProjectId",
  fetchProjectCharterByProjectIdHandler
);
export const fetchAllCollectionsByProjectThunk = createAsyncThunk(
  "fetchAllCollectionsByProject",
  fetchAllCollectionsByProjectHandler
);

export const fetchProjectTimelineThunk = createAsyncThunk(
  "fetchProjectTimeline",
  fetchProjectTimelineHandler
);

export const fetchAllCommentsForTimelineThunk = createAsyncThunk(
  "fetchAllCommentsForTimeline",
  fetchAllCommentsForTimelineHandler
);

export const createSupportTicketCommentThunk = createAsyncThunk(
  "createSupportTicketComment",
  createSupportTicketCommentHandler
);

export const fetchAllFilesForTimelineThunk = createAsyncThunk(
  "fetchAllFilesForTimeline",
  fetchAllFilesForTimelineHandler
);

export const addCommentThunk = createAsyncThunk(
  "addComment",
  addCommentHandler
);

export const addFilesThunk = createAsyncThunk("addFiles", addFilesHandler);

export const updateProjectTimelineThunk = createAsyncThunk(
  "updateProjectTimeline",
  updateProjectTimelineHandler
);

export const getVendorsCharterListThunk = createAsyncThunk(
  "getVendorsCharterList",
  getVendorsCharterListHandler
);

export const acceptACharterThunk = createAsyncThunk(
  "acceptACharter",
  acceptACharterHandler
);

export const rejectACharterThunk = createAsyncThunk(
  "rejectACharter",
  rejectACharterHandler
);

export const getAcceptedVendorsListThunk = createAsyncThunk(
  "getAcceptedVendorsList",
  getAcceptedVendorsListHandler
);

export const chooseAVendorThunk = createAsyncThunk(
  "chooseAVendor",
  chooseAVendorHandler
);

export const submitReviewQuestionsThunk = createAsyncThunk(
  "submitReviewQuestions",
  submitReviewQuestionsHandler
);

export const fetchAllTicketsDataByTicketIdThunk = createAsyncThunk(
  "fetchAllTicketsDataByTicketId",
  fetchAllTicketsDataByTicketIdHanldler
);

const projectSlice = createSlice({
  name: "user",
  initialState: INIT_STATE,
  reducers: {
    tabChange: (state, action) => {
      state.selectedTab = action.payload;
      return state;
    },
    deleteProject: (state, action) => {
      var id = action.payload;
      var newProjects = state.projects.filter((project) => project.id !== id);
      state.projects = newProjects;
      return state;
    },
    setSelectedCharter: (state, action) => {
      state.selectedCharter = action.payload;
      return state;
    },
    startProjectLoader: (state) => {
      state.isLoading = true;
    },
    stopProjectLoader: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllProjectsThunk.fulfilled, (state, action) => {
        state.projects = action.payload;
        return state;
      })
      .addCase(fetchAllProjectsThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllProjectsThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAllProposalsThunk.fulfilled, (state, action) => {
        if (action.payload) {
          state.selectedProject.proposals = action.payload;
        }
        return state;
      })
      .addCase(getAllProposalsThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllProposalsThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createProjectThunk.rejected, () => { })
      .addCase(fetchProjectByIdThunk.fulfilled, (state, action) => {
        state.selectedProject.overview = action.payload;
        return state;
      })
      .addCase(fetchProjectByIdThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProjectByIdThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(uploadProjectAttachmentThunk.fulfilled, (state, action) => {
        state.selectedProject.overview.attachments = [...state.selectedProject.overview.attachments, ...action.payload];
        return state;
      })
      .addCase(uploadProjectAttachmentThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadProjectAttachmentThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getRatingQuestionsThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllCollectionsByProjectThunk.fulfilled, (state, action) => {
        state.collections = action.payload;
        return state;
      })
      .addCase(getRatingQuestionsThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reviewQuestions.questions = action.payload;
        return state;
      })
      .addCase(getRatingQuestionsThunk.rejected, (state, action) => {
        if (
          action.error &&
          action.error.message &&
          action.error.message === "Client has already reviewed this project"
        ) {
          state.reviewQuestions.isReviewGiven = true;
        }
        state.reviewQuestions.questions = [];
        state.isLoading = false;
      })
      .addCase(getReportedIssueThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getReportedIssueThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedProject.tickets = action.payload;
        return state;
      })
      .addCase(createSupportTicketCommentThunk.fulfilled, (state, action) => {
        state.selectedProject.comments.comments_data.unshift(action.payload);
        return state;
      })
      .addCase(getReportedIssueThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(addReportIssueThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addReportIssueThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedProject.tickets.unshift(action.payload);
        return state;
      })
      .addCase(addReportIssueThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(acceptProposalThunk.fulfilled, (state, action) => {
        const { lead_id } = action.payload;
        const proposal = state.selectedProject.proposals.find(
          (proposal) => proposal.lead_id === lead_id
        );
        proposal.proposals[0].status_by_client = projectApprovalStatus.accepted;
        return state;
      })
      .addCase(acceptProposalThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(acceptProposalThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(rejectProposalThunk.fulfilled, (state, action) => {
        const { lead_id } = action.payload;
        const proposal = state.selectedProject.proposals.find(
          (proposal) => proposal.lead_id === lead_id
        );
        proposal.proposals[0].status_by_client = projectApprovalStatus.rejected;
        return state;
      })
      .addCase(rejectProposalThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(rejectProposalThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchProjectCharterByProjectIdThunk.fulfilled,
        (state, action) => {
          state.selectedCharter = action.payload;
          return state;
        }
      )
      .addCase(fetchProjectCharterByProjectIdThunk.rejected, (state) => {
        state.selectedCharter = null;
        state.isLoading = false;
      })
      .addCase(fetchProjectCharterByProjectIdThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllCommentsForTimelineThunk.fulfilled, (state, action) => {
        state.selectedTimeline.comments = action.payload;
        return state;
      })
      .addCase(fetchAllCommentsForTimelineThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchAllCommentsForTimelineThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllFilesForTimelineThunk.fulfilled, (state, action) => {
        state.selectedTimeline.files = action.payload;
        return state;
      })
      .addCase(fetchAllFilesForTimelineThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchAllFilesForTimelineThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addCommentThunk.fulfilled, (state, action) => {
        state.selectedTimeline.comments.unshift(action.payload);
        return state;
      })
      .addCase(addCommentThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(addCommentThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addFilesThunk.fulfilled, (state, action) => {
        state.selectedTimeline.files = [...state.selectedTimeline.files, ...action.payload];
        return state;
      })
      .addCase(addFilesThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(addFilesThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProjectTimelineThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isTimelinePublished = true;
        state.selectedTimeline.timeline = getSectionMatrix(action.payload);
        state.timelineRef = action.payload;
        return state;
      })
      .addCase(fetchProjectTimelineThunk.pending, (state) => {
        state.isLoading = true;
        state.isProjectTablePublished = true;
      })
      .addCase(fetchProjectTimelineThunk.rejected, (state) => {
        state.isTimelinePublished = false;
        state.selectedTimeline.timeline = null;
        state.isProjectTablePublished = false;
        state.isLoading = false;
      })
      .addCase(updateProjectTimelineThunk.fulfilled, (state, action) => {
        state.selectedTimeline.timeline = action.payload;
        return state;
      })
      .addCase(updateProjectTimelineThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateProjectTimelineThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVendorsCharterListThunk.fulfilled, (state, action) => {
        state.vendorCharterList = action.payload;
        return state;
      })
      .addCase(getVendorsCharterListThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getVendorsCharterListThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(acceptACharterThunk.fulfilled, (state) => {
        const id = state.selectedCharter._id;
        const current = state.vendorCharterList.find((data) => data._id === id);
        current.client_status = "accepted";
        state.selectedCharter.client_status = "accepted";
        return state;
      })
      .addCase(acceptACharterThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(acceptACharterThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(rejectACharterThunk.fulfilled, (state) => {
        const id = state.selectedCharter._id;
        const current = state.vendorCharterList.find((data) => data._id === id);
        current.client_status = "rejected";
        state.selectedCharter.client_status = "rejected";
        return state;
      })
      .addCase(rejectACharterThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(rejectACharterThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAcceptedVendorsListThunk.fulfilled, (state, action) => {
        state.vendorApprovalList = action.payload;
        return state;
      })
      .addCase(getAcceptedVendorsListThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAcceptedVendorsListThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(chooseAVendorThunk.fulfilled, (state, action) => {
        const id = action.payload;
        const vendor = state.vendorApprovalList.find((data) => data.id === id);
        vendor.choose_by_client = true;
        return state;
      })
      .addCase(chooseAVendorThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(chooseAVendorThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(submitReviewQuestionsThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(submitReviewQuestionsThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reviewQuestions.isReviewGiven = true;
        return state;
      })
      .addCase(submitReviewQuestionsThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(
        fetchAllTicketsDataByTicketIdThunk.fulfilled,
        (state, action) => {
          state.selectedProject.comments = action.payload;
          return state;
        }
      );
  },
});

export const {
  tabChange,
  deleteProject,
  setSelectedCharter,
  startProjectLoader,
  stopProjectLoader,
} = projectSlice.actions;
export default projectSlice.reducer;
