import React, { useState } from "react";
import {
  Stack,
  Card,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  TableHead,
  Chip,
  Box,
} from "@mui/material";
import { css } from "@emotion/css";
import { Link, useNavigate, useParams } from "react-router-dom";
import ListHead from "../../components/Common/ListHead";
import Page from "../../components/Page";
import SearchNotFound from "../../components/NotFound/SearchNotFound";
import Scrollbar from "../../components/Scrollbar";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCollectionsByProjectThunk } from "../../store/slices/project/project.slice";
import { DateTime } from "luxon";

//declaring column heading
const columns = [
  {
    id: "m_name",
    label: "Mil. Name",
    align: "left",
    minWidth: 200,
  },
  {
    id: "m_amount",
    label: "Mil. Amount",
    align: "center",
    minWidth: 150,
  },
  { id: "percent_release", label: "% Release", align: "center", minWidth: 150 },

  {
    id: "delivery_date",
    label: "Delivery Date",
    align: "center",
    minWidth: 150,
  },
  {
    id: "m_completed ",
    label: "Status ",
    align: "center",
    minWidth: 150,
  },
];

export default function Collections() {
  const [showModal, setShowModal] = useState(false);
  const handleOpen = () => {
    setShowModal(true);
  };
  const handleClose = () => setShowModal(false);
  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    dispatch(fetchAllCollectionsByProjectThunk(params.id));
  }, [dispatch, params.id]);

  const collection = useSelector(({ project }) => project.collections);

  if (collection.length === 0)
    return (
      <Box sx={{ marginTop: "100px" }}>
        <SearchNotFound
          src="/static/No-found/milestones.png"
          subtitle="No milestone to display."
          title=" "
        />
      </Box>
    );

  return (
    <>
      <Page title="Collections | EMB">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          m={2}
        >
          <Typography variant="h5" gutterBottom>
            Milestones (Mil)
          </Typography>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead sx={{ backgroundColor: "rgb(243, 246, 248)" }}>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ top: 57, minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {collection.map((row) => {
                    const {
                      milestone_name,
                      milestone_amount,
                      percentage_release,
                      actual_delivery_date,
                      milestone_completed,
                    } = row;

                    return (
                      <TableRow
                        tabIndex={-1}
                        role="checkbox"
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell align="left">{milestone_name}</TableCell>

                        <TableCell align="center">
                          ₹ {milestone_amount}
                        </TableCell>

                        <TableCell align="center">
                          {percentage_release} %
                        </TableCell>

                        <TableCell align="center">
                          {actual_delivery_date
                            ? DateTime.fromISO(actual_delivery_date).toFormat(
                                "DD"
                              )
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          <Chip
                            className={css`
                              font-size: 12px;
                              border: none;
                              background-color: lightcyan;
                              text-transform: capitalize;
                              padding-left: 5px;
                              padding-right: 5px;
                            `}
                            size="small"
                            variant="outlined"
                            label={
                              milestone_completed ? "Completed" : "Pending"
                            }
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          {/* {proposals?.length === 0 && <SearchNotFound />} */}
        </Card>
      </Page>
    </>
  );
}
