import * as React from "react";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Avatar,
  Container,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { markAllNotificationsAsReadThunk } from "../../store/slices/init/init.slice";
import { DateTime } from "luxon";
import Page from "../../components/Page";
import SearchNotFound from "../../components/NotFound/SearchNotFound";

export default function Notification() {
  const notificationData = useSelector(({ init }) => init.notifications);

  const { unread_count, notifications } = notificationData;

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (unread_count > 0) {
      dispatch(markAllNotificationsAsReadThunk());
    }
  }, [dispatch, unread_count]);

  return (
    <Page title="All Notifications | EMB">
      <Container>
        <Typography variant="h4">All Notifications</Typography>
        <Grid container spacing={3} mt={5}>
          <Grid item xs={12} sm={12} md={12}>
            {notifications?.length === 0 ? (
              <SearchNotFound
                src="/static/No-notification.svg"
                title="No Notifications to show!"
                subtitle="There is currently no data to show in notifications."
              />
            ) : (
              notifications?.map((data) => {
                return (
                  <TimelineItem key={data.id}>
                    <TimelineOppositeContent
                      color="text.secondary"
                      sx={{ flex: "0.12" }}
                    >
                      {DateTime.fromISO(data.timestamp).toFormat("DD")}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color="success" />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Card
                        mb={5}
                        sx={{
                          width: "100%",
                          height: "85px",
                          borderRadius: "6px",
                        }}
                      >
                        <CardContent sx={{ display: "flex" }}>
                          <Avatar
                            alt="Profile-image"
                            src="/static/info.png"
                            sx={{
                              display: "inline-block",
                              marginRight: "18px",
                              background: "#f3f6f8",
                            }}
                          />
                          <Typography>
                            {data.message_title}
                            <br />
                            <Box
                              component="span"
                              sx={{ color: "#a2a5b9", fontSize: "0.9rem" }}
                            >
                              {data.notification_text}
                            </Box>
                          </Typography>
                        </CardContent>
                      </Card>
                    </TimelineContent>
                  </TimelineItem>
                );
              })
            )}

            {/* <Box sx={{ textAlign: "center", mt: 5 }}>
          <Button
            variant="contained"
            to="#"
            // sx={{ float: 'left', marginLeft: "25px" }}
          >
            Load More
          </Button>
        </Box> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
