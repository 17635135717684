import React from "react";
import {
  Paper,
  Card,
  Box,
  Container,
  Grid,
  Button,
  Stack,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  Chip,
  IconButton,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  Divider,
} from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import WorkIcon from "@mui/icons-material/Work";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import ListSearchbar from "../../components/Common/ListSearchbar";
import { capitalCase } from "../../utils/change-case";
import { styled } from "@mui/material/styles";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import { makeStyles } from "@mui/styles";
import Element from "../../components/Form/Element";
import { inputType } from "../../utils/enum";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import "./MobileChatList.css";

export default function MobileChatList() {
  function getInitials(project_name) {
    return capitalCase(project_name.substring(0, 2));
  }

  const Input = styled("input")({
    display: "none",
  });

  const useStyles = makeStyles({
    button: {
      color: "#fff",
      backgroundColor: "#0044E3",
      "&:hover": {
        backgroundColor: "#0044E3",
        boxShadow: "0 8px 16px 0 rgb(0 68 226 / 25%)",
      },
      height: "42px",
    },
  });
  const classes = useStyles();

  const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 320,
    transition: theme.transitions.create(["box-shadow", "width"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    // "&.Mui-focused": { width: 320, boxShadow: theme.customShadows.z8 },
    "& fieldset": {
      borderWidth: `1px !important`,
      borderColor: `${theme.palette.grey[500_32]} !important`,
    },
  }));

  return (
    <>
      <div className="mobile-wrapper">
        <Paper className="container">
          <div className="left-container">
            <Paper className="left-box">
              <div className="action-container">
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  p={1}
                >
                  <Grid item md={5}>
                    <Typography variant="h4">Project Chats</Typography>
                  </Grid>
                  <Grid item md={7}>
                    {" "}
                    <SearchStyle
                      placeholder="Search projects"
                      size="small"
                      startAdornment={
                        <InputAdornment
                          position="left"
                          sx={{ marginRight: "0.75rem" }}
                        >
                          <Box
                            component={Icon}
                            icon={searchFill}
                            sx={{ color: "text.disabled" }}
                          />
                        </InputAdornment>
                      }
                    />
                  </Grid>
                </Grid>
              </div>
              <div className="list-group-container">
                <SimpleBar style={{ maxHeight: "100%" }}>
                  <div style={{ paddingBottom: "1.5rem" }}>
                    <List
                      sx={{
                        width: "100%",
                        height: "auto",
                        bgcolor: "background.paper",
                      }}
                    >
                      <ListItem
                        sx={{
                          borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              fontSize: ".8rem",
                              fontWeight: "600",
                              backgroundColor: "rgb(243, 246, 248)",
                              color: "#556080",
                              marginLeft: "5px",
                              width: "35px",
                              height: "35px",
                            }}
                          >
                            {getInitials("Hivefy Front-end Project")}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              sx={{
                                fontSize: "0.9rem",
                                marginBottom: "0.35rem",
                                fontWeight: "600",
                              }}
                            >
                              Hivefy Front-end Project
                            </Typography>
                          }
                          secondary={
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#8F9499",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  gap: "10px",
                                  width: "60%",
                                }}
                              >
                                <GroupsIcon /> 3
                              </Typography>
                              <div style={{ width: "40%", margin: "0 auto" }}>
                                <Chip
                                  label="Active"
                                  variant="outlined"
                                  sx={{
                                    fontSize: "0.7rem",
                                    color: "#27A853",
                                    background: "#eef7f0",
                                    border: "0",
                                    float: "right",
                                    height: "26px",
                                  }}
                                />
                              </div>
                            </Stack>
                          }
                        />
                      </ListItem>
                      <ListItem
                        sx={{
                          borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              fontSize: ".8rem",
                              fontWeight: "600",
                              backgroundColor: "rgb(243, 246, 248)",
                              color: "#556080",
                              marginLeft: "5px",
                              width: "35px",
                              height: "35px",
                            }}
                          >
                            {getInitials("Zaniaah E Commerce")}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "0.9rem",
                                marginBottom: "0.35rem",
                                fontWeight: "600",
                              }}
                            >
                              Zaniaah E Commerce
                            </Typography>
                          }
                          secondary={
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#8F9499",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  gap: "10px",
                                  width: "60%",
                                }}
                              >
                                <GroupsIcon /> 5
                              </Typography>
                              <div style={{ width: "40%", margin: "0 auto" }}>
                                <Chip
                                  label="Active"
                                  variant="outlined"
                                  sx={{
                                    fontSize: "0.7rem",
                                    color: "#27A853",
                                    background: "#eef7f0",
                                    border: "0",
                                    float: "right",
                                    height: "26px",
                                  }}
                                />
                              </div>
                            </Stack>
                          }
                        />
                      </ListItem>
                      <ListItem
                        sx={{
                          borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              fontSize: ".8rem",
                              fontWeight: "600",
                              backgroundColor: "rgb(243, 246, 248)",
                              color: "#556080",
                              marginLeft: "5px",
                              width: "35px",
                              height: "35px",
                            }}
                          >
                            {getInitials("Wine Quality Test Project")}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "0.9rem",
                                marginBottom: "0.35rem",
                                fontWeight: "600",
                              }}
                            >
                              Wine Quality Test Project
                            </Typography>
                          }
                          secondary={
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#8F9499",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  gap: "10px",
                                  width: "60%",
                                }}
                              >
                                <GroupsIcon /> 2
                              </Typography>
                              <div style={{ width: "40%", margin: "0 auto" }}>
                                <Chip
                                  label="Active"
                                  variant="outlined"
                                  sx={{
                                    fontSize: "0.7rem",
                                    color: "#27A853",
                                    background: "#eef7f0",
                                    border: "0",
                                    float: "right",
                                    height: "26px",
                                  }}
                                />
                              </div>
                            </Stack>
                          }
                        />
                      </ListItem>
                      <ListItem
                        sx={{
                          borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              fontSize: ".8rem",
                              fontWeight: "600",
                              backgroundColor: "rgb(243, 246, 248)",
                              color: "#556080",
                              marginLeft: "5px",
                              width: "35px",
                              height: "35px",
                            }}
                          >
                            {getInitials("Handwritten Character Recognition")}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "0.9rem",
                                marginBottom: "0.35rem",
                                fontWeight: "600",
                              }}
                            >
                              Handwritten Character Recognition
                            </Typography>
                          }
                          secondary={
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#8F9499",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  gap: "10px",
                                  width: "60%",
                                }}
                              >
                                <GroupsIcon /> 4
                              </Typography>
                              <div style={{ width: "40%", margin: "0 auto" }}>
                                <Chip
                                  label="Active"
                                  variant="outlined"
                                  sx={{
                                    fontSize: "0.7rem",
                                    color: "#27A853",
                                    background: "#eef7f0",
                                    border: "0",
                                    float: "right",
                                    height: "26px",
                                  }}
                                />
                              </div>
                            </Stack>
                          }
                        />
                      </ListItem>
                      <ListItem
                        sx={{
                          borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              fontSize: ".8rem",
                              fontWeight: "600",
                              backgroundColor: "rgb(243, 246, 248)",
                              color: "#556080",
                              marginLeft: "5px",
                              width: "35px",
                              height: "35px",
                            }}
                          >
                            {getInitials("Ghost Grubs")}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "0.9rem",
                                marginBottom: "0.35rem",
                                fontWeight: "600",
                              }}
                            >
                              Ghost Grubs
                            </Typography>
                          }
                          secondary={
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#8F9499",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  gap: "10px",
                                  width: "60%",
                                }}
                              >
                                <GroupsIcon /> 5
                              </Typography>
                              <div style={{ width: "40%", margin: "0 auto" }}>
                                <Chip
                                  label="Active"
                                  variant="outlined"
                                  sx={{
                                    fontSize: "0.7rem",
                                    color: "#27A853",
                                    background: "#eef7f0",
                                    border: "0",
                                    float: "right",
                                    height: "26px",
                                  }}
                                />
                              </div>
                            </Stack>
                          }
                        />
                      </ListItem>
                      <ListItem
                        sx={{
                          borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              fontSize: ".8rem",
                              fontWeight: "600",
                              backgroundColor: "rgb(243, 246, 248)",
                              color: "#556080",
                              marginLeft: "5px",
                              width: "35px",
                              height: "35px",
                            }}
                          >
                            {getInitials("Hivefy Front-end Project")}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "0.9rem",
                                marginBottom: "0.35rem",
                                fontWeight: "600",
                              }}
                            >
                              Hivefy Front-end Project
                            </Typography>
                          }
                          secondary={
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#8F9499",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  gap: "10px",
                                  width: "60%",
                                }}
                              >
                                <GroupsIcon /> 3
                              </Typography>
                              <div style={{ width: "40%", margin: "0 auto" }}>
                                <Chip
                                  label="Active"
                                  variant="outlined"
                                  sx={{
                                    fontSize: "0.7rem",
                                    color: "#27A853",
                                    background: "#eef7f0",
                                    border: "0",
                                    float: "right",
                                    height: "26px",
                                  }}
                                />
                              </div>
                            </Stack>
                          }
                        />
                      </ListItem>
                      <ListItem
                        sx={{
                          borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              fontSize: ".8rem",
                              fontWeight: "600",
                              backgroundColor: "rgb(243, 246, 248)",
                              color: "#556080",
                              marginLeft: "5px",
                              width: "35px",
                              height: "35px",
                            }}
                          >
                            {getInitials("Zaniaah E Commerce")}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "0.9rem",
                                marginBottom: "0.35rem",
                                fontWeight: "600",
                              }}
                            >
                              Zaniaah E Commerce
                            </Typography>
                          }
                          secondary={
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#8F9499",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  gap: "10px",
                                  width: "60%",
                                }}
                              >
                                <GroupsIcon /> 5
                              </Typography>
                              <div style={{ width: "40%", margin: "0 auto" }}>
                                <Chip
                                  label="Active"
                                  variant="outlined"
                                  sx={{
                                    fontSize: "0.7rem",
                                    color: "#27A853",
                                    background: "#eef7f0",
                                    border: "0",
                                    float: "right",
                                    height: "26px",
                                  }}
                                />
                              </div>
                            </Stack>
                          }
                        />
                      </ListItem>
                      <ListItem
                        sx={{
                          borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              fontSize: ".8rem",
                              fontWeight: "600",
                              backgroundColor: "rgb(243, 246, 248)",
                              color: "#556080",
                              marginLeft: "5px",
                              width: "35px",
                              height: "35px",
                            }}
                          >
                            {getInitials("Wine Quality Test Project")}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "0.9rem",
                                marginBottom: "0.35rem",
                                fontWeight: "600",
                              }}
                            >
                              Wine Quality Test Project
                            </Typography>
                          }
                          secondary={
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#8F9499",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  gap: "10px",
                                  width: "60%",
                                }}
                              >
                                <GroupsIcon /> 2
                              </Typography>
                              <div style={{ width: "40%", margin: "0 auto" }}>
                                <Chip
                                  label="Active"
                                  variant="outlined"
                                  sx={{
                                    fontSize: "0.7rem",
                                    color: "#27A853",
                                    background: "#eef7f0",
                                    border: "0",
                                    float: "right",
                                    height: "26px",
                                  }}
                                />
                              </div>
                            </Stack>
                          }
                        />
                      </ListItem>
                      <ListItem
                        sx={{
                          borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              fontSize: ".8rem",
                              fontWeight: "600",
                              backgroundColor: "rgb(243, 246, 248)",
                              color: "#556080",
                              marginLeft: "5px",
                              width: "35px",
                              height: "35px",
                            }}
                          >
                            {getInitials("Handwritten Character Recognition")}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "0.9rem",
                                marginBottom: "0.35rem",
                                fontWeight: "600",
                              }}
                            >
                              Handwritten Character Recognition
                            </Typography>
                          }
                          secondary={
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#8F9499",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  gap: "10px",
                                  width: "60%",
                                }}
                              >
                                <GroupsIcon /> 4
                              </Typography>
                              <div style={{ width: "40%", margin: "0 auto" }}>
                                <Chip
                                  label="Active"
                                  variant="outlined"
                                  sx={{
                                    fontSize: "0.7rem",
                                    color: "#27A853",
                                    background: "#eef7f0",
                                    border: "0",
                                    float: "right",
                                    height: "26px",
                                  }}
                                />
                              </div>
                            </Stack>
                          }
                        />
                      </ListItem>
                      <ListItem
                        sx={{
                          borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              fontSize: ".8rem",
                              fontWeight: "600",
                              backgroundColor: "rgb(243, 246, 248)",
                              color: "#556080",
                              marginLeft: "5px",
                              width: "35px",
                              height: "35px",
                            }}
                          >
                            {getInitials("Ghost Grubs")}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "0.9rem",
                                marginBottom: "0.35rem",
                                fontWeight: "600",
                              }}
                            >
                              Ghost Grubs Last
                            </Typography>
                          }
                          secondary={
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#8F9499",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  gap: "10px",
                                  width: "60%",
                                }}
                              >
                                <GroupsIcon /> 5
                              </Typography>
                              <div style={{ width: "40%", margin: "0 auto" }}>
                                <Chip
                                  label="Active"
                                  variant="outlined"
                                  sx={{
                                    fontSize: "0.7rem",
                                    color: "#27A853",
                                    background: "#eef7f0",
                                    border: "0",
                                    float: "right",
                                    height: "26px",
                                  }}
                                />
                              </div>
                            </Stack>
                          }
                        />
                      </ListItem>
                    </List>
                  </div>
                </SimpleBar>
              </div>
            </Paper>
          </div>
        </Paper>
      </div>
    </>
  );
}
