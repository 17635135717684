import { Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Page from "../../components/Page";
import { fetchProjectByIdThunk } from "../../store/slices/project/project.slice";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { useParams, Outlet } from "react-router-dom";
import { useEffect } from "react";
import { pdfjs } from "react-pdf";
import { capitalCase } from "../../utils/change-case";
import {
  milestonePath,
  overviewPath,
  proposalPath,
  scopePath,
  supportPath,
  tablePath,
} from "../../utils/constant";
import CustomTabs from "../../components/Common/Tabs";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const labels = [
  {
    label: "Overview",
    value: overviewPath,
  },
  {
    label: "Contract",
    value: proposalPath,
  },
  {
    label: "Project Table",
    value: tablePath,
  },
  {
    label: "Scope",
    value: scopePath,
  },
  {
    label: "Support",
    value: supportPath,
  },
  {
    label: "Milestone",
    value: milestonePath,
  },
];

function ProjectDetails() {
  const dispatch = useDispatch();
  const { id: projectId } = useParams();

  useEffect(() => {
    dispatch(fetchProjectByIdThunk(projectId));
  }, [dispatch, projectId]);

  const { overview } = useSelector(({ project }) => project.selectedProject);

  return (
    <Page title="Dashboard">
      <Container sx={{ margin: 0 }}>
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {capitalCase(overview?.project_name)}
          </Typography>
          <Breadcrumb />
        </Stack>
        <Grid>
          <CustomTabs labels={labels} />
          <div style={{ marginBottom: "20px" }}></div>
          <Outlet />
        </Grid>
      </Container>
    </Page>
  );
}

export default ProjectDetails;
