import React from "react";
import { Grid } from "@mui/material";
import ProjectCard from "./ProjectCard";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  "@media (max-width:1000px)": {
    cardInMobile: {
      paddingLeft: "40px !important",
      paddingRight: "20px",
    },
  },
  "@media (max-width:430px)": {
    cardInMobile: {
      margin: 15,
      padding: 0,
      paddingLeft: "24px !important",
    },
  },
});
const ProjectGrid = ({ projects, ...other }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={3} {...other}>
      {projects.map((project) => (
        <Grid
          key={project.id}
          item
          xs={12}
          sm={6}
          md={4}
          sx={{ padding: "20px" }}
          className={classes.cardInMobile}
        >
          <ProjectCard project={project} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProjectGrid;
