import React, { useEffect } from "react";
import { Typography, Container, Grid, Card, Box, Stack } from "@mui/material";
import { Link } from "@mui/icons-material";

import { useDispatch, useSelector } from "react-redux";
import { getWalletDetailsThunk } from "../../../store/slices/wallet/wallet.slice";
import { useStyles } from "./WalletCard.theme";

export default function WalletCard() {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWalletDetailsThunk());
  }, []);

  const { wallet_description } = useSelector(({ wallet }) => wallet);
  const updateAmount = wallet_description?.amount;
  const companyName = wallet_description?.amount;
  return (
    <Card className={classes.wallet_card}>
      <Box sx={{ textAlign: "center", padding: "1rem 0.5rem" }}>
        <Grid container spacing={2} p={2}>
          <Grid item md={12} sm={12}>
            <Box sx={{ position: "absolute", top: -25, left: 0 }}>
              <img src="/static/ec 3.svg" alt="" />
            </Box>
            <Typography
              // variant="h5"
              sx={{
                color: "#fff",
                fontSize: "12px",
                textTransform: "uppercase",
                textAlign: "left",
                fontWeight: "bold",
                paddingTop: "0.75rem",
              }}
            >
              Total Balance
            </Typography>
          </Grid>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            className={classes.amount}
          >
            <Box>
              <Grid item md={8}>
                <Typography
                  variant="h2"
                  sx={{ color: "#fff", marginLeft: "16px" }}
                >
                  {updateAmount ? updateAmount : 0}
                </Typography>
              </Grid>
            </Box>
            <Box>
              <Grid
                item
                md={4}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  textAlign: "right",
                  paddingLeft: "0",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    color: "#fff",
                  }}
                >
                  INR
                </Typography>
              </Grid>
            </Box>
          </Stack>
          <Grid item md={12} className={classes.company}>
            <Typography
              sx={{
                fontSize: "16px",
                color: "#fff",
                float: "left",
                opacity: "0.75",
              }}
            >
              Expand My Business
            </Typography>
          </Grid>
          <Box sx={{ paddingLeft: "17px" }} className={classes.transection}>
            <a href="/dashboard/wallet">
              <Typography
                sx={{
                  fontSize: "15px",
                  color: "#fff",
                  float: "left",
                  textDecoration: "underline dotted",
                  textUnderlineOffset: "3px",
                }}
              >
                view all transactions
              </Typography>
            </a>
          </Box>
        </Grid>
      </Box>
      <Box sx={{ position: "absolute", bottom: -10, right: 0 }}>
        <img src="/static/ec 1.svg" alt="" />
      </Box>
      <Box sx={{ position: "absolute", bottom: -10, right: -10 }}>
        <img src="/static/ec 2.svg" alt="" />
      </Box>
    </Card>
  );
}
