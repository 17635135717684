import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  head: {
    color: "#212A35",
    fontSize: "32px",
    fontWeight: "bold",
    marginLeft: "40px",
  },
  subTitle: {
    marginTop: "10px",
    marginBottom: "10px",
    fontWeight: 500,
    paddingBottom: "2.25rem",
    fontSize: "20px",
    color: "#212A35",
    marginLeft: "40px",
  },
  Button: {
    background: "#02AB54",
    width: "150px",
    height: "50px",
    fontSize: "20px",
    borderRadius: "12px",
    boxShadow: "none",
    ":hover": { background: "#81c784" },
    textDecoration: "none",
    marginLeft: "40px",
  },
  head_img: {
    // marginLeft: "20px",
  },

  "@media (max-width:600px)": {
    subTitle: {
      display: "none",
    },
    head: {
      fontSize: "22px",
      marginLeft: "24px",
    },
    head_img: {
      display: "none",
    },
    hero: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    Button: {
      width: "100px",
      height: "50px",
      marginRight: "24px",
    },
  },

  "@media (max-width:400px)": {
    head: {
      fontSize: "20px",
    },

    Button: {
      width: "80px",
      height: "45px",
      fontSize: "18px",
    },
  },
});
