import React, { useState, useRef } from "react";
import {
  Container,
  Stack,
  Typography,
  Grid,
  Box,
  Tabs,
  Tab,
  Card,
  Avatar,
  IconButton,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { profileTabChange } from "../../store/slices/user/user.slice";
import PersonalDetails from "./PersonalDetails";
import BussinessAddressDetails from "./BussinessAddressDetails";
import BusinessDetails from "./BusinessDetails/BusinessDetails";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import EditPersonalDetails from "./EditPersonalDetails";
import EditBussinessAddressDetails from "./EditBussinessAddressDetails";
import Page from "../../components/Page";
import EditBusinessDetails from "./BusinessDetails/EditBusinessDetails";
import { green } from "@mui/material/colors";
import useUser from "../../hooks/useUser";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { uploadProfileThunk } from "../../store/slices/user/user.slice.js";
// import cameraFill from "@iconify/icons-eva/camera-fill";
// import { makeStyles } from "@mui/styles";
import { useStyles } from "./BusinessDetails/MyProfile.theme";

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <Container sx={{ my: 4, mx: "auto" }}>
      {value === index ? children : null}
    </Container>
  );
}

const MyProfile = () => {
  const [profileEditMode, setProfileEditMode] = useState(false);
  const [businessEditMode, setBusinessEditMode] = useState(false);
  const [addressEditMode, setAddressEditMode] = useState(false);
  const [businessEditBtn, setBusinessEditBtn] = useState(null);
  const [addressEditBtn, setAddressEditBtn] = useState(null);
  const [editAddressId, setEditAddressId] = useState(null);
  const classes = useStyles();
  const user = useUser();
  const inputRef = useRef();

  function handleFileChange(e) {
    var formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_type", "profile");
    dispatch(uploadProfileThunk(formData));
  }
  console.log("img------------", inputRef.current);

  const { profile } = useSelector(({ user }) => user.userData);
  console.log("------", profile);

  const selectedTab = useSelector(({ user }) => user.selectedTab);
  const dispatch = useDispatch();

  const handleprofileEditMode = (val) => {
    setProfileEditMode(val);
  };

  const handleBusinessEditMode = (val) => {
    setBusinessEditMode(val);
  };

  const handleAddressEditMode = (val) => {
    setAddressEditMode(val);
  };

  const resetEditMode = () => {
    setProfileEditMode(false);
    setBusinessEditMode(false);
    setAddressEditMode(false);
  };

  function handleTabChange(e, value) {
    dispatch(profileTabChange(value));
    resetEditMode();
  }

  return (
    <>
      <input
        type="file"
        accept="image/png, image/gif, image/jpeg, application/pdf"
        style={{ display: "none" }}
        ref={inputRef}
        onChange={handleFileChange}
      />
      <Page title="My profile | EMB">
        <Container sx={{ margin: 0 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h4" gutterBottom>
              My Profile
            </Typography>
            <Breadcrumb />
          </Stack>
        </Container>

        <Container sx={{ margin: 0 }}>
          <Box>
            <Grid container spacing={4} mt={2}>
              <Grid item md={4} sm={12} xs={12}>
                <Card sx={{ minHeight: "350px" }}>
                  <Stack direction="column" spacing={2} sx={{ p: 3, pr: 0 }}>
                    <Box>
                      <Avatar
                        alt="Profile-image"
                        // src="https://cdn.worldvectorlogo.com/logos/israel-aircraft-unit-2.svg"
                        src={
                          user.profileUrl
                            ? user.profileUrl
                            : "https://cdn.worldvectorlogo.com/logos/israel-aircraft-unit-2.svg"
                        }
                        sx={{
                          marginTop: "10px",
                          width: 144,
                          height: 144,
                          mx: "auto",
                          borderRadius: "50%",
                          border: "1px dashed rgba(145, 158, 171, 0.32)",
                          backgroundColor: "rgb(244, 246, 248)",
                          cursor: "pointer",
                        }}
                        onClick={() => inputRef.current.click()}
                      />
                      <Box>
                        <Box className={classes.cameraIcon}>
                          <Icon icon="bxs:camera" />
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ textAlign: "center" }}>
                      {profile?.first_name && (
                        <Typography
                          variant="h4"
                          pb={1}
                          sx={{ textAlign: "center" }}
                        >
                          {user.fullName}
                        </Typography>
                      )}
                      <Typography variant="body1" pb={1}>
                        Expand My Business
                      </Typography>
                    </Box>
                    {/* <Box sx={{ textAlign: "center" }}>
                      <IconButton
                        aria-label="upload picture"
                        onClick={() => handleprofileEditMode(true)}
                        sx={{
                          backgroundColor: "#F4F5F8",
                          borderRadius: "1rem",
                          fontSize: "0.9rem",
                        }}
                      >
                        <ModeEditOutlinedIcon
                          sx={{
                            color: "#202B36",
                            fontSize: "0.9rem",
                            marginRight: "10px",
                          }}
                        />
                        Edit profile
                      </IconButton>
                    </Box> */}
                  </Stack>
                </Card>
              </Grid>

              <Grid item md={8}>
                <Card sx={{ minHeight: "420px", py: 2, px: 1 }}>
                  <Grid>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={selectedTab}
                        onChange={handleTabChange}
                        aria-label="basic tabs example"
                      >
                        <Tab label="Profile" />
                        <Tab label="Business" />
                        <Tab label="Address" />
                      </Tabs>
                    </Box>
                    <TabPanel value={selectedTab} index={0}>
                      {profileEditMode ? (
                        <EditPersonalDetails
                          handleprofileEditMode={handleprofileEditMode}
                          data={profile}
                        />
                      ) : (
                        <PersonalDetails
                          handleprofileEditMode={handleprofileEditMode}
                        />
                      )}
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                      {businessEditMode ? (
                        <EditBusinessDetails
                          handleBusinessEditMode={handleBusinessEditMode}
                          businessEditBtn={businessEditBtn}
                        />
                      ) : (
                        <BusinessDetails
                          handleBusinessEditMode={handleBusinessEditMode}
                          setBusinessEditBtn={setBusinessEditBtn}
                        />
                      )}
                    </TabPanel>
                    <TabPanel value={selectedTab} index={2}>
                      {addressEditMode ? (
                        <EditBussinessAddressDetails
                          handleAddressEditMode={handleAddressEditMode}
                          addressEditBtn={addressEditBtn}
                          editAddressId={editAddressId}
                        />
                      ) : (
                        <BussinessAddressDetails
                          handleAddressEditMode={handleAddressEditMode}
                          setAddressEditBtn={setAddressEditBtn}
                          setEditAddressId={setEditAddressId}
                        />
                      )}
                    </TabPanel>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Page>
    </>
  );
};

export default MyProfile;
