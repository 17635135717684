import React from "react";
import {
  Typography,
  Container,
  Grid,
  Stack,
  Box,
  Card,
  Avatar,
  Button,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import MobileInput from "../../../components/MobileInput";
// components
import { green } from "@mui/material/colors";
import Element from "../../../components/Form/Element";
import { inputType } from "../../../utils/enum";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  createBusinessProfileThunk,
  updateBusinessProfileThunk,
} from "./../../../store/slices/user/user.slice";
import {
  numberOfEmployeesOptions,
  typeOfCompanyOptions,
} from "../../../utils/options";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function EditBusinessDetails({
  handleBusinessEditMode,
  businessEditBtn,
}) {
  const { business } = useSelector(({ user }) => user.userData);

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    if (businessEditBtn === "add") dispatch(createBusinessProfileThunk(values));
    else
      dispatch(updateBusinessProfileThunk({ ...values, ...values.phone }))
        .unwrap()
        .then(() => handleBusinessEditMode(false));
  };

  return (
    <Container>
      <Box>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Formik
              initialValues={{
                brand_name: business?.brand_name,
                registered_business_name: business?.registered_business_name,
                description: business?.description,
                type_of_company: business?.type_of_company,
                no_of_employees: business?.no_of_employees,
                industry: business?.industry,
                website: business?.website,
                phone: business?.mobile_number
                  ? {
                      country_code: "+91",
                      mobile_number: business?.mobile_number,
                    }
                  : "",
                fax: business?.fax,
                sic_code: business?.sic_code,
                annual_revenue: business?.annual_revenue,
                pan_no_of_account: business?.pan_no_of_account,
                gst_no_of_account: business?.gst_no_of_account,
                currency: business?.currency,
              }}
              onSubmit={handleSubmit}
            >
              {({
                values,
                handleChange,
                handleBlur,
                touched,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <Element
                        value={values.brand_name}
                        eletype={inputType.input}
                        label="Brand Name"
                        inputProps={{
                          name: "brand_name",
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Element
                        eletype={inputType.input}
                        label="Registered Business Name"
                        value={values.registered_business_name}
                        inputProps={{
                          name: "registered_business_name",
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Element
                        eletype={inputType.textarea}
                        label="Description"
                        value={values.description}
                        inputProps={{
                          name: "description",
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Element
                        eletype={inputType.select}
                        label="Type of Company"
                        value={values.type_of_company}
                        inputProps={{
                          name: "type_of_company",
                          handleChange: handleChange,
                          handleBlur: handleBlur,
                        }}
                        options={typeOfCompanyOptions}
                        bottom={12}
                        icons={
                          <ArrowDropDownIcon
                            sx={{
                              position: "absolute",
                              right: 20,
                              bottom: 12,
                            }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Element
                        eletype={inputType.select}
                        label="No of Employees"
                        value={values.no_of_employees}
                        inputProps={{
                          name: "no_of_employees",
                          handleChange: handleChange,
                          handleBlur: handleBlur,
                          type: "number",
                        }}
                        options={numberOfEmployeesOptions}
                        bottom={12}
                        icons={
                          <ArrowDropDownIcon
                            sx={{
                              position: "absolute",
                              right: 20,
                              bottom: 12,
                            }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Element
                        eletype={inputType.input}
                        label="Industry"
                        value={values.industry}
                        inputProps={{
                          name: "industry",
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Element
                        eletype={inputType.input}
                        label="Website"
                        value={values.website}
                        inputProps={{
                          name: "website",
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <MobileInput
                        name="phone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                        label="Phone Number"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Element
                        eletype={inputType.input}
                        label="Fax"
                        value={values.fax}
                        inputProps={{
                          name: "fax",
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Element
                        eletype={inputType.input}
                        label="Sic Code"
                        value={values.sic_code}
                        inputProps={{
                          name: "sic_code",
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Element
                        eletype={inputType.input}
                        label="Annual Revenue"
                        value={values.annual_revenue}
                        inputProps={{
                          name: "annual_revenue",
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      {" "}
                      <Element
                        eletype={inputType.input}
                        label="Pan No. of Account"
                        value={values.pan_no_of_account}
                        inputProps={{
                          name: "pan_no_of_account",
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Element
                        eletype={inputType.input}
                        label="Gst No. of Account"
                        value={values.gst_no_of_account}
                        inputProps={{
                          name: "gst_no_of_account",
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Element
                        eletype={inputType.input}
                        label="Currency"
                        value={values.currency}
                        inputProps={{
                          name: "currency",
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "20px 0",
                    }}
                  >
                    <Stack direction="row" spacing={1}>
                      <Button
                        type="submit"
                        color="secondary"
                        startIcon={<SendIcon />}
                        variant="contained"
                        sx={{
                          background: green[500],
                          ":hover": { background: green[300] },
                        }}
                      >
                        Save
                      </Button>
                    </Stack>
                  </Box>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
