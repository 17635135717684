import {
  Box,
  Typography,
  Stack,
  Grid,
  FormControl,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  TableHead,
  Card,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ListHead from "../../components/Common/ListHead";
import ProjectTable from "./ProjectTable";
import Kanban from "./Kanban";
import GanttChart from "./Gantt";
import Dropdown from "../../components/Form/Dropdown";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { timelineType } from "../../utils/enum";
import { capitalCase } from "../../utils/change-case";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjectTimelineThunk } from "../../store/slices/project/project.slice";
import { useParams } from "react-router";
import SearchNotFound from "../NotFound/SearchNotFound";
import Scrollbar from "../../components/Scrollbar";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";

//declaring column heading
const columns = [
  {
    id: "task",
    label: "Tasks",
    align: "left",
    minWidth: 500,
  },
  {
    id: "due_date",
    label: "Due Date",
    align: "center",
    minWidth: 150,
  },
  {
    id: "start_date",
    label: "Start Date",
    align: "center",
    minWidth: 150,
  },

  {
    id: "status",
    label: "Status",
    align: "center",
    minWidth: 150,
  },
  {
    id: "chat",
    align: "center",
    minWidth: 80,
  },
];

const timelineItems = {
  table: {
    key: "table",
    label: "Main Table",
    component: <ProjectTable />,
  },
  gantt: {
    key: "gantt",
    label: "Gantt",
    component: <GanttChart />,
  },
  kanban: {
    key: "kanban",
    label: "Kanban",
    component: <Kanban />,
  },
  calendar: {
    key: "calendar",
    label: "Calendar",
  },
};

function TimelineDashboard() {
  const [status, setStatus] = useState(timelineType.table);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(fetchProjectTimelineThunk(params.id));
  }, [dispatch, params.id]);

  const { isProjectTablePublished } = useSelector(({ project }) => project);

  if (!isProjectTablePublished)
    return (
      <SearchNotFound
        src="/static/No-found/Project_table.png"
        subtitle="No data to show."
        title=" "
      />
    );

  return (
    <>
      <Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Box></Box>

          <Grid item md={4} display="flex" justifyContent="end">
            <FormControl>
              <Dropdown
                styles={{
                  position: "relative",
                  height: "40px",
                  lineHeight: "1.4375em",
                  fontSize: "1rem",
                  fontWeight: 600,
                  color: "#212B36,",
                  borderRadius: "8px",
                  backgroundColor: "#F3F6F8",
                  maxWidth: "150px",
                  margin: "1rem 1rem 0 0",
                  textAlign: "left",
                }}
                options={[
                  { label: "Main Table", value: timelineType.table },
                  { label: "Gantt", value: timelineType.gantt },
                  { label: "Kanban", value: timelineType.kanban },
                ]}
                icons={
                  <ArrowDropDownIcon
                    sx={{ position: "absolute", right: 20, bottom: 24 }}
                  />
                }
                inputProps={{
                  handleChange: (e) => setStatus(e.target.value),
                }}
                value={status}
              />
            </FormControl>
          </Grid>
        </Stack>
      </Box>
      {timelineItems[status].component}
    </>
  );
}

export default TimelineDashboard;
