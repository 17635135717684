import React from "react";
import { Stack, Box, Typography } from "@mui/material";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import GridViewIcon from "@mui/icons-material/GridView";
import UserListToolbar from "../../Common/ListSearchbar";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Dropdown from "../../Form/Dropdown";
import FormControl from "@mui/material/FormControl";
import { projectStatus } from "../../../utils/enum";
import "../../_dashboard/projects/project.theme.css";
import { useStyles } from "./ProjectMenu.theme";

const ProjecMenu = ({
  type,
  setType,
  handleSearchChange,
  setStatus,
  status,
}) => {
  const handleType = (event, newType) => {
    setType(newType);
  };

  const classes = useStyles();

  return (
    <div className={classes.projectContainer}>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Typography variant="h4" mb={2} className={classes.projectHeading}>
            All Projects
          </Typography>
          <Breadcrumb />
        </Box>
        <Stack direction="row" spacing={1} alignItems="center">
          <Box className="projectsearchDesktop">
            <UserListToolbar
              placeholder="Search projects"
              handleSearchChange={handleSearchChange}
            />
          </Box>
          <Box>
            <FormControl fullWidth>
              <Dropdown
                styles={{
                  position: "relative",
                  height: "40px",
                  lineHeight: "1.4375em",
                  fontSize: "1rem",
                  fontWeight: 600,
                  color: "#212B36,",
                  borderRadius: "8px",
                  backgroundColor: "#f3f6f8",
                  maxWidth: "100px",
                  margin: "1rem 1rem 0 0",
                  cursor: "pointer",
                }}
                options={[
                  { label: "All", value: projectStatus.all },
                  { label: "Active", value: projectStatus.active },
                  { label: "Closed", value: projectStatus.closed },
                ]}
                icons
                inputProps={{
                  handleChange: (e) => {
                    setStatus(e.target.value);
                  },
                }}
                value={status}
              />
            </FormControl>
          </Box>
          <Box>
            <ToggleButtonGroup
              value={type}
              size="small"
              exclusive
              onChange={handleType}
              aria-label="view projects"
            >
              <ToggleButton
                value="table"
                aria-label="table"
                sx={{ backgroundColor: "#F3F6F8!important" }}
              >
                <FormatListNumberedIcon />
              </ToggleButton>
              <ToggleButton value="grid" aria-label="grid">
                <GridViewIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Stack>
      </Stack>
      <Stack className="projectsearchMobile">
        <Box>
          <UserListToolbar
            placeholder="Search projects"
            handleSearchChange={handleSearchChange}
          />
        </Box>
      </Stack>
    </div>
  );
};

export default ProjecMenu;
