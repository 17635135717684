import React from "react";
import { Box, Container, Stack, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Element from "./../Form/Element";
import { inputType } from "../../utils/enum";
import Dropdown from "../Form/Dropdown";

const RoleModal = () => {
  return (
    <>
      <Box sx={{ marginTop: "20px" }}>
        <Element eletype={inputType.input} label="Role name" />
      </Box>

      <Box sx={{ margin: "30px 0 10px 0" }}>
        <FormControl fullWidth>
          <Dropdown label="Permission" />
        </FormControl>
      </Box>
    </>
  );
};

export default RoleModal;
