// material
import {
  Grid,
  Container,
  Typography,
  Card,
  CardHeader,
  Stack,
  Box,
} from "@mui/material";
// components
import Page from "../components/Page.js";
import { Outlet } from "react-router-dom";
import { ActiveProject, AppWeeklySales } from "../components/_dashboard/app";
import { Link } from "react-router-dom";

// import SimpleSlider from "../components/_dashboard/app/SlickSlider";
import WalletCard from "../components/_dashboard/app/WalletCard";
import { green } from "@mui/material/colors";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjectMatrixThunk } from "../store/slices/dashboard/dashboard.slice.js";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import WalletLoader from "../components/Skeletons/WalletLoader";
import AppWeeklyLoader from "../components/Skeletons/AppWeeklyLoader";
import TotalProjectLoader from "../components/Skeletons/TotalProjectLoader";
import ActiveProjectLoader from "../components/Skeletons/ActiveProjectLoader";
import { useStyles } from "./DashboardApp.theme";

export default function DashboardApp() {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProjectMatrixThunk());
  }, [dispatch]);

  const projectMatrix = useSelector(({ dashboard }) => dashboard.main);

  return (
    <>
      <Outlet />
      <Page title="Client Dashboard | EMB">
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={8}>
              <AppWeeklySales />
              {/* <AppWeeklyLoader /> */}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              {/* <SimpleSlider /> */}
              <WalletCard />

              {/* <WalletLoader /> */}
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <Link
                  to="/dashboard/projects"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Grid container>
                    <Grid md={3}>
                      <Box>
                        <img
                          src="/static/icons/total_project.png"
                          width="60"
                          height="64"
                          className={classes.img}
                          alt=""
                        />
                      </Box>
                    </Grid>
                    <Grid item md={9}>
                      <Stack
                        direction="row"
                        justifyContent="space-evenly"
                        spacing={2}
                        className={classes.content}
                      >
                        <Box ml={3}>
                          <Box sx={{ marginLeft: "-30px" }}>
                            <CardHeader title="Total Projects" pt={2} />
                          </Box>
                          <Typography
                            variant="h5"
                            sx={{
                              pl: 3,
                              color: "#02AB54",
                              marginLeft: "-30px",
                            }}
                          >
                            {projectMatrix.total_projects}
                          </Typography>
                        </Box>
                        <Box sx={{ paddingTop: "40px" }}>
                          <KeyboardArrowRightIcon className={classes.arrow} />
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Link>
              </Card>
              {/* <TotalProjectLoader /> */}
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <Link
                  to="/dashboard/projects?type=active"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Grid container>
                    <Grid md={3}>
                      <Box>
                        <img
                          src="/static/icons/running_man.svg"
                          width="60"
                          height="64"
                          className={classes.img}
                          alt=""
                        />
                      </Box>
                    </Grid>
                    <Grid item md={9}>
                      <Stack
                        direction="row"
                        justifyContent="space-evenly"
                        spacing={2}
                        className={classes.content}
                      >
                        <Box ml={3}>
                          <Box sx={{ marginLeft: "-30px" }}>
                            <CardHeader title="Active Projects" pt={2} />
                          </Box>
                          <Typography
                            variant="h5"
                            sx={{
                              pl: 3,
                              color: "#02AB54",
                              marginLeft: "-30px",
                            }}
                          >
                            {projectMatrix.active_projects}
                          </Typography>
                        </Box>
                        <Box sx={{ paddingTop: "40px" }}>
                          <KeyboardArrowRightIcon className={classes.arrow} />
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Link>
              </Card>

              {/* <TotalProjectLoader /> */}
            </Grid>

            {/* <Grid item xs={12} sm={6} md={4}>
              <Card>
                <Grid container>
                  <Grid item md={6}>
                    <CardHeader title="Total Balance" pt={2} />
                    <Typography
                      variant="h5"
                      sx={{ p: 2, pl: 3, color: green[800] }}
                    >
                      {projectMatrix.wallet_balance}
                    </Typography>
                  </Grid>
                  <Grid md={6}>
                    <img
                      src="/static/icons/total_credits.svg"
                      width="80"
                      style={{ margin: "0 auto", height: "100%" }}
                      alt=""
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid> */}

            <Grid item xs={12} md={12} lg={12}>
              <ActiveProject />
              {/* <ActiveProjectLoader /> */}
            </Grid>

            {/* <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppItemOrders />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid> */}
          </Grid>
        </Container>
      </Page>
    </>
  );
}
