import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Stack,
  Button,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import { DragDropContext } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";

import Col from "./Col";
import { getInitState, getAllColumnsWithStatusType } from "./helpers.kanban";
import ListSearchbar from "../../Common/ListSearchbar";
import Modal from "../../Modal/Modal";
import KanbanModal from "../../Modal/KanbanModal";
import { userAxios } from "../../../utils/axios/user.axios";
import { changeRowStatusApi } from "../../../utils/apis.utils";
import { fetchProjectTimelineThunk } from "../../../store/slices/project/project.slice";
import { useParams } from "react-router-dom";

export default function Kanban() {
  const { timelineRef } = useSelector(({ project }) => project);
  const [selectedStatusColumn, setSelectedStatusColumn] = useState(
    getAllColumnsWithStatusType(timelineRef)[0]
  );
  const [fullState, setFullState] = useState(getInitState(timelineRef));
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  const params = useParams();

  const columsNames = useMemo(() => {
    const ids = getAllColumnsWithStatusType(timelineRef);
    return ids.map((id) => ({
      id,
      label: timelineRef.columns[id].value,
    }));
  }, [timelineRef]);

  useEffect(() => {
    dispatch(fetchProjectTimelineThunk(params.id));
  }, [dispatch, params.id]);

  useEffect(() => {
    setFullState(getInitState(timelineRef));
  }, [timelineRef]);

  const state = useMemo(() => {
    if (!search) return fullState[selectedStatusColumn];

    const currentState = fullState[selectedStatusColumn];
    return {
      columnOrder: currentState.columnOrder,
      tasks: currentState.tasks,
      columns: Object.keys(currentState.columns).reduce((acc, item) => {
        acc[item] = {
          ...currentState.columns[item],
          taskIds: currentState.columns[item].taskIds.filter((id) =>
            currentState.tasks[id]?.content?.includes(search)
          ),
        };
        return acc;
      }, {}),
    };
  }, [search, fullState, selectedStatusColumn]);

  function onDragEnd(result) {
    const { destination, source, draggableId } = result;
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    const newState = { ...fullState[selectedStatusColumn] };

    if (destination.droppableId === source.droppableId) {
      const column = newState.columns[destination.droppableId];
      const newTaskIds = Array.from(column.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...column,
        taskIds: newTaskIds,
      };

      setFullState({
        ...fullState,
        [selectedStatusColumn]: {
          ...newState,
          columns: {
            ...state.columns,
            [newColumn.id]: newColumn,
          },
        },
      });
    } else {
      const sourceColumn = { ...newState.columns[source.droppableId] };
      const destinationColumn = {
        ...newState.columns[destination.droppableId],
      };

      const newSourceTaskIds = [...sourceColumn.taskIds];
      const newDestinationIds = [...destinationColumn.taskIds];

      newSourceTaskIds.splice(source.index, 1);
      newDestinationIds.splice(destination.index, 1, draggableId);

      sourceColumn.taskIds = newSourceTaskIds;

      destinationColumn.taskIds = newDestinationIds;

      setFullState({
        ...fullState,
        [selectedStatusColumn]: {
          ...newState,
          columns: {
            ...state.columns,
            [sourceColumn.id]: sourceColumn,
            [destinationColumn.id]: destinationColumn,
          },
        },
      });
    }

    userAxios.put(changeRowStatusApi, {
      project_table_id: timelineRef.project_table_id,
      row_id: draggableId,
      column_id: selectedStatusColumn,
      status:
        destination.droppableId === "Empty" ? null : destination.droppableId,
    });
  }

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedStatusColumn(value);
  };

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          pb={1}
        >
          <ListSearchbar
            placeholder="Search..."
            styles={{ paddingLeft: "0px !important" }}
            handleSearchChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          <FormControl sx={{ m: 3, mt: 1, maxWidth: 200, width: "100%" }}>
            <Select
              value={selectedStatusColumn}
              onChange={handleChange}
              sx={{ height: "40px" }}
            >
              {columsNames.map((col) => (
                <MenuItem value={col.id}>{col.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Box sx={{ width: "80vw" }}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            spacing={{ xs: 1, sm: 2, md: 4 }}
            sx={{ overflowX: "auto" }}
            pb={5}
          >
            {state.columnOrder.map((id) => {
              const column = state.columns[id];
              const tasks = column.taskIds.map((taskId) => state.tasks[taskId]);
              return <Col key={column.id} column={column} tasks={tasks} />;
            })}
          </Stack>
        </Box>
      </DragDropContext>

      <Modal
        isOpen={showModal}
        closeModal={handleClose}
        title="Add Section"
        content={<KanbanModal />}
        dialogProps={{ fullWidth: true }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{ justifyContent: "center", marginBottom: 2 }}
        action={
          <Button size="large" type="submit" variant="contained">
            Save
          </Button>
        }
      />
    </>
  );
}
