import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  card: {
    border: "2px solid #E4E8EB",
    borderRadius: "26px",
    boxShadow: "none",
  },
  img: {
    margin: "20px 0 20px 20px",
    background: "#f3f6f8",
    padding: "15px",
    borderRadius: "50%",
  },

  arrow: {
    fontSize: "2rem!important",
  },
  "@media (max-width:1200px)": {
    content: {
      marginLeft: "20px",
      display: "flex",
      justifyContent: "space-evenly",
    },
    arrow: {
      marginLeft: "0px",
    },
  },
  "@media (max-width:1000px)": {
    content: {
      marginLeft: "30px",
      display: "flex",
      justifyContent: "space-evenly",
    },
    arrow: {
      marginLeft: "0px",
    },
  },
  "@media (max-width:900px)": {
    content: {
      marginLeft: "0px",
      display: "flex",
      justifyContent: "space-evenly",
    },
    arrow: {
      marginLeft: "50px",
    },
  },
  "@media (max-width:800px)": {
    content: {
      display: "flex",
      justifyContent: "space-evenly",
    },
    arrow: {
      marginLeft: "10px",
    },
  },
  "@media (max-width:600px)": {
    content: {
      display: "flex",
      justifyContent: "space-between",
    },
    arrow: {
      marginLeft: "120px",
    },
  },
  "@media (max-width:500px)": {
    content: {
      display: "flex",
      justifyContent: "space-between",
    },
    arrow: {
      marginLeft: "50px",
    },
  },
  "@media (max-width:400px)": {
    content: {
      display: "flex",
      justifyContent: "space-between",
    },
    arrow: {
      marginLeft: "10px",
    },
  },
});
