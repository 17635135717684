import React, { useEffect } from "react";
import {
  CardContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import RatingComponent from "./Rating";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useState } from "react";
import { useRef } from "react";
import SendIcon from "@mui/icons-material/Send";

function Review({
  questions,
  current,
  handleNext,
  handlePrev,
  onChange,
  answers,
  handleSubmit,
  isLast,
}) {
  function MultiSelectForm({ options, quesId, value }) {
    return (
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="gender"
          defaultValue="female"
          name="radio-buttons-group"
          onChange={(value) => onChange(quesId, value.target.value)}
          value={value}
        >
          {options?.map((option, i) => (
            <FormControlLabel
              key={i}
              value={option.id}
              control={<Radio />}
              label={option.choice}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }

  function InputText({ quesId, value }) {
    const [currentValue, setValue] = useState(value);
    const inputVal = useRef(value);

    useEffect(() => {
      inputVal.current = currentValue;
    }, [currentValue]);

    useEffect(() => {
      return () => {
        onChange(quesId, inputVal.current);
      };
    }, []);

    return (
      <FormControl
        sx={{
          m: 1,
          width: "500px",
        }}
        variant="standard"
      >
        <TextField
          label="Enter Your Feedback Here...."
          type="text"
          variant="standard"
          multiline
          rows={3}
          value={currentValue}
          onChange={(e) => setValue(e.target.value)}
        />
      </FormControl>
    );
  }

  function Date({ quesId, value = null }) {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Select Date"
          value={value}
          onChange={(newValue) => {
            onChange(quesId, newValue);
          }}
          renderInput={(params) => (
            <TextField
              variant="standard"
              {...params}
              sx={{
                width: "500px",
              }}
            />
          )}
        />
      </LocalizationProvider>
    );
  }

  const currentQuestion = questions[current];
  const selectedAnswer = answers[currentQuestion?.ques_id];

  const theme = useTheme();

  function handleNextValidation() {
    handleNext();
  }

  return (
    <>
      <CardContent sx={{ marginTop: "3rem" }}>
        <Stack justifyContent="center">
          <Typography
            variant="h5"
            component="div"
            sx={{ color: "#222" }}
            gutterBottom
          >
            Q <ArrowForwardIcon sx={{ fontSize: "16px" }} />{" "}
            {currentQuestion?.question}
          </Typography>
        </Stack>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            minHeight: "80px",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          {currentQuestion?.type === "radio" && (
            <MultiSelectForm
              options={currentQuestion?.choices}
              quesId={currentQuestion.ques_id}
              value={selectedAnswer}
            />
          )}
          {currentQuestion?.type === "ratings" && (
            <RatingComponent
              quesId={currentQuestion.ques_id}
              initValue={selectedAnswer}
              onChange={onChange}
            />
          )}
          {currentQuestion?.type === "text" && (
            <InputText
              quesId={currentQuestion.ques_id}
              value={selectedAnswer}
            />
          )}
          {currentQuestion?.type === "date" && (
            <Date quesId={currentQuestion.ques_id} value={selectedAnswer} />
          )}
        </Box>
      </CardContent>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <MobileStepper
          variant="progress"
          steps={questions.length}
          sx={{ width: "30%" }}
          position="static"
          activeStep={current}
          nextButton={
            <Button
              size="small"
              onClick={handleNextValidation}
              disabled={current === questions.length}
            >
              Next
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button size="small" onClick={handlePrev} disabled={current === 0}>
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
        <div>
          <Button
            size="small"
            variant="contained"
            sx={{ marginRight: "1rem" }}
            disabled={isLast ? "" : "disabled"}
            onClick={handleSubmit}
          >
            <SendIcon sx={{ fontSize: "16px", marginRight: "5px" }} />
            Submit
          </Button>
        </div>
      </div>
    </>
  );
}

export default Review;
