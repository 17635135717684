import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  wallet: {
    width: "100%",
  },
  wallet_card: {
    height: "230px",
    background: "#4a4b54",
    position: "relative",
  },
  "@media (max-width:1000px)": {
    amount: {
      position: "absolute",
      top: "65px",
    },
    company: {
      position: "absolute",
      top: "110px",
    },
    transection_table: {
      width: "100%",
    },
  },

  "@media (max-width:800px)": {
    amount: {
      position: "absolute",
      top: "65px",
    },
    company: {
      position: "absolute",
      top: "110px",
    },
    transection_table: {
      width: "100%",
    },
    d_none: {
      display: "none",
    },
  },
});
