import { createSlice } from "@reduxjs/toolkit";

const INIT_STATE = {
  header: {
    width: "calc(90% - 281px)",
  },
  sidebar: {
    zIndex: "10",
  },
};

const uiSlice = createSlice({
  name: "ui",
  initialState: INIT_STATE,
  reducers: {},
});

export default uiSlice.reducer;
