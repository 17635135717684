import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container } from "@mui/material";
import queryString from "query-string";
import ProjectTable from "./ProjectTable";
import ProjectGrid from "./ProjectGrid";
import ProjectMenu from "./ProjectMenu";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import SearchNotFound from "../../NotFound/SearchNotFound";
import { fetchAllProjectsThunk } from "./../../../store/slices/project/project.slice";
import { projectStatus } from "../../../utils/enum";
import AllProjectSkeleton from "../../Skeletons/AllProjectLoader";
import TableLoader from "../../Skeletons/TableLoader";

const useStyles = makeStyles({
  menuMarginTop: {
    marginTop: "24px",
  },
});

const typeEnum = {
  table: "table",
  grid: "grid",
};

const AllProjects = () => {
  const dispatch = useDispatch();
  const [type, setType] = useState(typeEnum.table);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(projectStatus.all);
  const classes = useStyles();
  const location = useLocation();

  const path = queryString.parse(location.search);

  useEffect(() => {
    if (path.type && ["all", "active", "closed"].includes(path.type)) {
      setStatus(path.type);
    }
  }, []);

  useEffect(() => {
    dispatch(fetchAllProjectsThunk());
  }, [dispatch]);

  const { projects } = useSelector(({ project }) => project);

  function getProjectsByStatus(projects) {
    if (status === projectStatus.all) return projects;
    return projects.filter((project) => project.project_status === status);
  }

  const data = useMemo(() => {
    if (!search) return getProjectsByStatus(projects);
    const serachedProjects = projects.filter((product) =>
      product.project_name.toLowerCase().includes(search)
    );
    return getProjectsByStatus(serachedProjects);
  }, [search, projects, status]);

  const handleSearchChange = (e) => {
    const lowercasedValue = e.target.value.toLowerCase();
    setSearch(lowercasedValue);
  };

  function renderData() {
    if (data.length === 0)
      return (
        <SearchNotFound
          src="/static/No-found/Project.png"
          title=""
          subtitle="No projects created yet."
        />
      );

    return type === typeEnum.table ? (
      <ProjectTable data={data} />
    ) : (
      // <TableLoader />
      <ProjectGrid projects={data} />
    );
  }

  return (
    <Container sx={{ margin: 0 }}>
      <ProjectMenu
        type={type}
        setType={setType}
        handleSearchChange={handleSearchChange}
        setStatus={setStatus}
        status={status}
      />
      <div className={classes.menuMarginTop}>{renderData()}</div>
    </Container>
  );
};

export default AllProjects;
