import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Badge from "@mui/material/Badge";
import Table from "./Table";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import Drawer from "./Drawer";
import _ from "lodash";
import SearchNotFound from "../../NotFound/SearchNotFound";
import SelectedTable from "./SelectedTable";

function ProjectTable() {
  const { timeline } = useSelector(({ project }) => project.selectedTimeline);

  return (
    <div>
      <Table {...timeline} />
    </div>
  );
}

export default ProjectTable;
