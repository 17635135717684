import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  cameraIcon: {
    fontSize: "16px",
    position: "absolute",
    top: "135px",
    right: "80px",
    cursor: " pointer",
    background: "#405EDD",
    height: " 25px",
    color: "#fff",
    width: "25px",
    padding: "3.5px",
    borderRadius: "50%",
    textAlign: "center",

    "@media (max-width:600px)": {
      position: "absolute",
      top: "135px",
      right: "102px",
    },

    "@media (max-width:400px)": {
      right: "90px",
    },
    "@media (max-width:360px)": {
      right: "78px",
    },
    "@media (max-width:330px)": {
      right: "82px",
    },
  },

  inputField: {
    width: "200px",
    "@media (max-width: 1000px) ": { width: "300px" },
    "@media (max-width: 700px) ": {
      width: "300px",
    },
    "@media (max-width:600px)": {
      width: "300px",
    },

    "@media (max-width: 500px) ": {
      width: "300px",
    },

    "@media (max-width: 400px) ": {
      width: "300px",
    },
    "@media (max-width: 350px) ": {
      width: "250px",
    },
    "@media (max-width: 300px) ": {
      width: "200px",
    },
  },

  inputFieldAdd: {
    width: "150px",
    "@media (max-width:600px)": {
      width: "270px",
    },
  },
});
