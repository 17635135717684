import React, { useState } from "react";
import ListHead from "../../Common/ListHead";
import Scrollbar from "./../../Scrollbar";

import UserMoreMenu from "./../user/UserMoreMenu";
import { mockImgCover } from "./../../../utils/mockImages";
import { styled } from "@mui/material/styles";
import { useStyles } from "./ProjectMenu.theme";

import {
  Card,
  Box,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Chip,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import { css } from "@emotion/css";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteProject } from "../../../store/slices/project/project.slice";
import { capitalCase } from "../../../utils/change-case";
import { DateTime } from "luxon";
import DeleteAction from "../../Common//DeleteAction";

const TABLE_HEAD = [
  {
    id: "project",
    label: "Project",
    alignRight: false,
  },
  // { id: "timline", label: "Timeline", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "services", label: "Service ", alignRight: false },
  { id: "budget", label: "Project Amount ", alignRight: false },
  { id: "createdAt", label: "Created on", alignRight: false },
];

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 300 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

function getInitials(project_name) {
  return capitalCase(project_name.substring(0, 2));
}

const ProjectTable = ({ data }) => {
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();

  // function handleDelete(id) {
  //   console.log(id);
  // }
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div>
      <Card
        sx={{ borderRadius: "21px", boxShadow: "0px 0px 8px #38383829" }}
        className={classes.tableDiv}
      >
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ListHead
                headLabel={TABLE_HEAD}
                rowCount={data?.length}
                styles={{
                  backgroundColor: "rgb(243, 246, 248)",
                }}
              />
              <TableBody>
                {data.map((row) => {
                  const {
                    id,
                    project_name,
                    project_status,
                    services,
                    created_at,
                    tcv_client,
                  } = row;
                  const isItemSelected = selected.indexOf(project_name) !== -1;

                  return (
                    <TableRow
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/dashboard/projects/${id}/overview`)
                      }
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        padding="none"
                        sx={{ paddingLeft: "16px" }}
                      >
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <Typography variant="body2" noWrap>
                              {project_name.length > 32 ? (
                                <div>
                                  {project_name.substring(0, 32) + "..."}
                                </div>
                              ) : (
                                project_name
                              )}
                            </Typography>
                          </Stack>
                        </Stack>
                      </TableCell>
                      {/* <TableCell align="left">{expected_timeline}</TableCell> */}

                      <TableCell
                        align="left"
                        sx={{ paddingLeft: "8px!important" }}
                      >
                        {" "}
                        <Chip
                          className={css`
                            font-size: 12px;
                            border: none;
                            text-transform: capitalize;
                          `}
                          size="small"
                          variant="outlined"
                          label={project_status}
                          sx={{
                            color:
                              project_status === "active"
                                ? "#11A54A"
                                : "#909090",
                            // backgroundColor:
                            //   project_status == "active"
                            //     ? "#eff8f1"
                            //     : "#fdd8de",
                          }}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          {services.length > 0
                            ? services?.map((ele) => ele.service_name).join(",")
                            : "--"}
                        </Stack>
                      </TableCell>

                      <TableCell align="left">
                        <Box> {tcv_client ? `₹ ${tcv_client}` : "--"}</Box>
                      </TableCell>
                      <TableCell align="left">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          spacing={2}
                        >
                          <Typography
                            className={css`
                              font-size: 14px;
                            `}
                          >
                            {DateTime.fromISO(created_at).toFormat("DD")}
                          </Typography>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </div>
  );
};

export default ProjectTable;
