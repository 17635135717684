import React from "react";
import Skeleton from "react-loading-skeleton";

function AllProjectSkeleton() {
  return (
    <div
      className="flex"
      style={{
        justifyContent: "space-between",
        borderRadius: "10px",
        padding: "50px",
        background:
          "linear-gradient(90deg, #c3d8f8 0%, rgba(195, 216, 248, 0) 131.61%)",
        flexDirection: "column",
      }}
    >
      <div style={{ width: "100%", marginBottom: "20px" }}>
        <Skeleton
          style={{
            backgroundColor: "#eee",
            display: "block",
            height: "12rem",
            borderRadius: "10px",
          }}
        />
      </div>
      <div style={{ width: "100%" }}>
        <Skeleton
          style={{
            backgroundColor: "#eee",
            display: "block",
            marginBottom: "1rem",
            lineHeight: "1.25rem",
          }}
          highlightColor="#ddd"
        />
        <Skeleton
          style={{
            backgroundColor: "#eee",
            display: "block",
            marginBottom: "1rem",
            lineHeight: "2rem",
          }}
          highlightColor="#ddd"
        />
        <Skeleton
          style={{
            backgroundColor: "#eee",
            display: "block",
            marginBottom: "0.5rem",
          }}
          highlightColor="#ddd"
          width="30%"
        />
        <Skeleton
          style={{ backgroundColor: "#eee" }}
          highlightColor="#ddd"
          width="20%"
        />
      </div>
    </div>
  );
}

export default AllProjectSkeleton;
