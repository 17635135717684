import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function TotalProjectLoader() {
  return (
    <SkeletonTheme baseColor="#edf2f9" highlightColor="#dde6f0" duration={0.7}>
      <div
        className="flex"
        style={{
          justifyContent: "space-between",
          borderRadius: "10px",
          padding: "10px 20px",
          background: "#f3f6f8",
          flexDirection: "column",
          borderRadius: "26px",

          position: "relative",
        }}
      >
        <Skeleton
          style={{
            width: "60px",
            height: "60px",
            borderRadius: "50%",
            marginTop: "10px",
          }}
        />
        <Skeleton
          style={{
            position: "absolute",
            top: 20,
            right: 30,
            backgroundColor: "#edf2f9",
            display: "flex",
            height: "4rem",
            width: "60%",
            borderRadius: "5px",
          }}
        />
      </div>
    </SkeletonTheme>
  );
}

export default TotalProjectLoader;
