import React from "react";
import { Badge, IconButton } from "@mui/material";
import { useNavigate } from "react-router";

export default function ChatPopover() {
  const navigate = useNavigate(); //ForNavigate

  return (
    <>
      <IconButton
        size="large"
        onClick={() => navigate({ pathname: "/dashboard/chat" })}
      >
        <Badge badgeContent={2} color="error">
          <img src="/static/vectors/message-icon.svg" width={23} height={23} />
        </Badge>
      </IconButton>
    </>
  );
}
