import React from "react";
import { inputType } from "../../utils/enum";
import { useStyles } from "./Form.theme";
import Input from "./Input";
import Dropdown from "./Dropdown";
import NumberInput from "./NumberInput";
import FileInput from "./FileInput";

function Element({
  eletype,
  label,
  errorText,
  inputProps,
  options,
  value,
  styles,
  icon,
  bottom,
  placeholder,
  ...rest
}) {
  const classes = useStyles();
  function renderInput() {
    return (
      <Input
        label={label}
        errorText={errorText}
        inputProps={inputProps}
        value={value}
        styles={styles}
        placeholder={placeholder}
      />
    );
  }

  function renderTextarea() {
    return (
      <div className={classes.formGroup} style={styles}>
        <label className={classes.formLabel}>{label}</label>
        <textarea
          className={classes.textareaFormControl}
          type="text"
          cols="4"
          rows="6"
          {...inputProps}
          value={value}
          autoComplete="off"
          placeholder={placeholder}
        />
        {errorText && (
          <span className={classes.formErrorStyle}>{errorText}</span>
        )}
      </div>
    );
  }

  function renderSelect() {
    return (
      <Dropdown
        label={label}
        errorText={errorText}
        options={options}
        inputProps={inputProps}
        value={value}
        styles={styles}
        icon={icon}
        bottomMargin={bottom}
        {...rest}
      />
    );
  }

  function renderNumberInput() {
    return (
      <NumberInput
        label={label}
        errorText={errorText}
        inputProps={inputProps}
        value={value}
        {...rest}
      />
    );
  }

  function renderAutoComplete() {
    return (
      <div className={classes.formGroup}>
        <label className={classes.formLabel}>Give your project name here</label>
        <input className={classes.formControl} type="text" autoComplete="off" />
      </div>
    );
  }
  function renderFileInput() {
    return (
      <FileInput
        label={label}
        {...inputProps}
        errorText={errorText}
        value={value}
        {...rest}
      />
    );
  }

  return (
    <>
      {eletype === inputType.input && renderInput()}
      {eletype === inputType.number && renderNumberInput()}
      {eletype === inputType.select && renderSelect()}
      {eletype === inputType.textarea && renderTextarea()}
      {eletype === inputType.autocomplete && renderAutoComplete()}
      {eletype === inputType.file && renderFileInput()}
    </>
  );
}

export default Element;
