import Input from "./Input";
import { useStyles } from "./Form.theme";
import { useMemo, useRef, useState } from "react";
import { getVirtualElement } from "../../utils/global/global";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const Dropdown = ({
  label,
  options = [],
  inputProps,
  value,
  errorText,
  styles,
  icons,
  bottomMargin,
}) => {
  const classes = useStyles();
  const [showMenuItem, setShowMenuItem] = useState(false);

  const refElement = useMemo(() => {
    return getVirtualElement({ ...inputProps, type: "input" });
  }, []);

  function handleItemClick(value) {
    refElement.setValue(value);
    setShowMenuItem(false);
  }

  var dropdownRef = useRef();

  function renderMenu() {
    return (
      <div className={classes.menuWrapper} ref={dropdownRef}>
        <ul className={classes.dropDown}>
          {options.map((option) => {
            return (
              <li
                className={classes.dropdownItem}
                onClick={() => handleItemClick(option.value)}
              >
                {option.label}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  useOnClickOutside(dropdownRef, () => setShowMenuItem(false));

  function getLabelFromValue() {
    const selectedOption = options.find((option) => option.value === value);
    return selectedOption?.label;
  }

  return (
    <div className={classes.dropdownWrapper}>
      <Input
        label={label}
        styles={styles}
        inputProps={{
          onClick: () => setShowMenuItem(true),
          readOnly: true,
        }}
        value={getLabelFromValue()}
        errorText={errorText}
        icon={
          icons ? (
            <ArrowDropDownIcon
              sx={{
                position: "absolute",
                right: 20,
                bottom: bottomMargin ? bottomMargin : 24,
                cursor: "pointer",
              }}
              onClick={() => setShowMenuItem(true)}
            />
          ) : (
            ""
          )
        }
      />
      {showMenuItem && renderMenu()}
    </div>
  );
};

export default Dropdown;
