import {
  chatCommentsApi,
  chooseVendorApi,
  commentsCharterApi,
  createChatCommentApi,
  createProjectApi,
  createProjectTimelineApi,
  createSupportApi,
  fetchAllProjectsApi,
  fetchProjectByIdApi,
  fetchProjectCharterByProjectIdApi,
  fetchProjectTimelineByProjectIdApi,
  filesCharterApi,
  getVendorListApi,
  getVendorsCharterListApi,
  proposalApi,
  pushLeadsToZohoApi,
  ratingQuestionApi,
  submitReviewQuestionsApi,
  updateCharterApi,
  uploadProjectAttachmentApi,
  getAllMilestonesApi,
} from "../../../utils/apis.utils";
import { listViewType } from "../../../utils/enum";
import { createQueryString } from "../../../utils/global/global";
import {
  startDashboardLoader,
  stopDashboardLoader,
} from "../dashboard/dashboard.slice";
import { showFailureToast, showSuccessToast } from "../toast/toast.slice";
import { reportedIssueApi } from "./../../../utils/apis.utils";
import { fetchProjectTimelineThunk } from "./project.slice";

export const fetchAllProjectsHandler = async (__, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(fetchAllProjectsApi);
  return response.data;
};

export const createProjectHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(createProjectApi, {
      data,
    });

    const responseData = response.data;
    await user.post(pushLeadsToZohoApi, {
      project_id: responseData.id,
    });
    thunkApi.dispatch(
      showSuccessToast({ message: "Project created successfully" })
    );
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject();
  }
};

export const getAllProposalsHandler = async (id, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(proposalApi + "/" + id);
  return response.data;
};

export const changeProposalStatusHandler = async () => {};

export const getRatingQuestions = async (id, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const query = createQueryString({
      project_id: id,
    });
    const response = await user.get(ratingQuestionApi + query);
    const responseData = response.data;
    return responseData;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getReportedIssue = async (id, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const query = createQueryString({
    project_id: id,
  });
  const response = await user.get(reportedIssueApi + query);
  const responseData = response.data;
  return responseData;
};

export const createReportedIssue = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(createSupportApi, data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (error) {
    thunkApi.dispatch(showFailureToast({ message: error }));
  }
};

export const fetchProjectByIdHandler = async (id, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(fetchProjectByIdApi + "/" + id);
  return response.data;
};

export const uploadProjectAttachmentHandler = async (data, thunkApi) => {
  try {
    const { id, file } = data;
    const { user } = thunkApi.extra.apiService;
    file.append("attachment_type", "project_attachment");
    const response = await user.post(
      uploadProjectAttachmentApi + "/" + id,
      file
    );
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (error) {
    thunkApi.dispatch(showFailureToast({ message: error }));
  }
};

export const createSupportTicketCommentHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(createChatCommentApi, data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({ message: err, vertical: "top", horizontal: "right" })
    );
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const acceptProposalHandler = async (data, thunkApi) => {
  try {
    const { lead_id, proposal_id } = data;
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(
      `${proposalApi}/${lead_id}/${proposal_id}`,
      {
        action: "accepted",
      }
    );
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return data;
  } catch (error) {
    thunkApi.dispatch(showFailureToast({ message: error }));
  }
};

export const rejectProposalHandler = async (data, thunkApi) => {
  try {
    const { lead_id, proposal_id, rejectionReason } = data;
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(
      `${proposalApi}/${lead_id}/${proposal_id}`,
      {
        action: "rejected",
        rejection_reason: rejectionReason,
      }
    );
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return data;
  } catch (error) {
    thunkApi.dispatch(showFailureToast({ message: error }));
  }
};

export const fetchProjectCharterByProjectIdHandler = async (id, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const queryString = createQueryString({
    type: listViewType.charter,
    project_id: id,
  });
  const response = await user.get(
    fetchProjectCharterByProjectIdApi + queryString
  );
  return response.data;
};

export const fetchAllCommentsForTimelineHandler = async (data, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const queryString = createQueryString(data);
  const response = await user.get(commentsCharterApi + queryString);
  return response.data;
};

export const fetchAllCollectionsByProjectHandler = async (id, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(getAllMilestonesApi + "?project_id=" + id);
  return response.data;
};

export const fetchAllFilesForTimelineHandler = async (data, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const queryString = createQueryString(data);
  const response = await user.get(filesCharterApi + queryString);
  return response.data;
};
export const addCommentHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const queryString = createQueryString(data);
    const response = await user.post(commentsCharterApi + queryString);
    thunkApi.dispatch(fetchProjectTimelineThunk(data.project_id));
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const addFilesHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(filesCharterApi, data);
    thunkApi.dispatch(stopDashboardLoader());
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const fetchProjectTimelineHandler = async (id, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(
    fetchProjectTimelineByProjectIdApi + "/" + id
  );
  return response.data;
};

export const updateProjectTimelineHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(createProjectTimelineApi, data);
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const getVendorsCharterListHandler = async (projectId, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const query = createQueryString({
    type: listViewType.charter,
    project_id: projectId,
  });
  const response = await user.get(getVendorsCharterListApi + query);
  return response.data;
};

export const acceptACharterHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const { vendor_id, project_id } = data;
    const response = await user.put(
      updateCharterApi + "/" + vendor_id + "/" + project_id,
      {
        action: "accepted",
      }
    );
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({ message: err, vertical: "top", horizontal: "right" })
    );
    return Promise.reject(err);
  }
};

export const rejectACharterHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const { vendor_id, project_id } = data;
    const response = await user.put(
      updateCharterApi + "/" + vendor_id + "/" + project_id,
      {
        action: "rejected",
        rejection_reason: "Not Appropraite ",
      }
    );
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({ message: err, vertical: "top", horizontal: "right" })
    );
    return Promise.reject(err);
  }
};

export const getAcceptedVendorsListHandler = async (projectId, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const query = createQueryString({
    project_id: projectId,
  });
  const response = await user.get(getVendorListApi + query);
  return response.data;
};

export const chooseAVendorHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const { vendor_id, project_id } = data;
    await user.put(chooseVendorApi + "/" + vendor_id + "/" + project_id);
    return vendor_id;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const submitReviewQuestionsHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(submitReviewQuestionsApi, data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({ message: err, vertical: "top", horizontal: "right" })
    );
    return Promise.reject(err);
  }
};

export const fetchAllTicketsDataByTicketIdHanldler = async (
  { projectId, ticketId },
  thunkApi
) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const query = createQueryString({
      project_id: projectId,
    });
    const response = await user.get(`${chatCommentsApi}/${ticketId}${query}`);
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};
