import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  iconStyle: {
    backgroundColor: "rgb(244, 246, 248)",
    borderRadius: "5px",
    maxWidth: "50px",
    width: "100%",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  avtarStyle: {
    marginTop: "10px",
    width: 144,
    height: 144,
    borderRadius: "50%",
    border: "1px dashed rgba(145, 158, 171, 0.32)",
    backgroundColor: "rgb(244, 246, 248)",
  },
  inputField: {
    width: "200px",
    "@media (max-width:600px)": {
      width: "300px",
    },
  },

  inputFieldAdd: {
    width: "150px",
    "@media (max-width:600px)": {
      width: "300px",
    },
  },
});
