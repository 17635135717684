import { Icon } from "@iconify/react";

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: "dashboard",
    path: "/dashboard/app",
    icon: (
      <img src={"/static/icons/dashboard.svg"} width={22} height={22} alt="" />
    ),
    activeIcon: (
      <img
        src={"/static/icons/dashboard_active.svg"}
        width={22}
        height={22}
        alt=""
      />
    ),
  },
  {
    title: "Projects",
    path: "/dashboard/projects",
    icon: (
      <img src={"/static/icons/projects.svg"} width={22} height={22} alt="" />
    ),
    activeIcon: (
      <img
        src={"/static/icons/projects_active.svg"}
        width={22}
        height={22}
        alt=""
      />
    ),
  },
  {
    title: "profile",
    path: "/dashboard/myProfile",
    icon: (
      <img src={"/static/icons/profile.svg"} width={22} height={22} alt="" />
    ),
    activeIcon: (
      <img
        src={"/static/icons/profile_active.svg"}
        width={22}
        height={22}
        alt=""
      />
    ),
  },
  {
    title: "FAQ's",
    path: "/dashboard/faq",
    icon: <img src={"/static/icons/faq.svg"} width={22} height={22} alt="" />,
    activeIcon: (
      <img src={"/static/icons/faq_active.svg"} width={22} height={22} alt="" />
    ),
  },
  {
    title: "Support",
    path: "/dashboard/contact_us",
    icon: (
      <img src={"/static/icons/contact.svg"} width={22} height={22} alt="" />
    ),
    activeIcon: (
      <img
        src={"/static/icons/contact_active.svg"}
        width={22}
        height={22}
        alt=""
      />
    ),
  },
];

export default sidebarConfig;
