import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { Link as RouterLink } from "react-router-dom";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import SearchNotFound from "../../NotFound/SearchNotFound";
import { useStyles } from "./ActiveProject.theme";
// material
import {
  Box,
  Stack,
  Link,
  Card,
  Button,
  Divider,
  Typography,
  CardHeader,
  Avatar,
} from "@mui/material";
// utils
import { useSelector } from "react-redux";
//
import Scrollbar from "../../Scrollbar";
import { projectStatus } from "../../../utils/enum";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { capitalCase } from "../../../utils/change-case";

NewsItem.propTypes = {
  news: PropTypes.object.isRequired,
};

function NewsItem({ news }) {
  const classes = useStyles();
  const { project_name, description, id } = news;
  function getInitials(description) {
    return capitalCase(description.substring(0, 2));
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={0}
      sx={{ pr: 3 }}
      justifyContent="space-between"
      className={classes.projects}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        <Avatar
          sx={{
            bgcolor: "#eff8f1",
            color: "#00aa55",
            fontSize: ".8rem",
            fontWeight: "600",
          }}
        >
          {getInitials(project_name)}
        </Avatar>
        <Box sx={{ minWidth: 220 }}>
          <Link
            to={`/dashboard/projects/${id}/overview`}
            color="inherit"
            sx={{ textDecoration: "none" }}
            component={RouterLink}
          >
            <Typography
              variant="subtitle2"
              noWrap
              className={classes.project_name}
            >
              {capitalCase(project_name)}
            </Typography>
          </Link>
          <Typography
            variant="body1"
            sx={{ color: "text.secondary", maxWidth: 550 }}
            noWrap
            className={classes.description}
          >
            {description}
          </Typography>
        </Box>
      </Stack>
      <Link
        to={`/dashboard/projects/${id}/overview`}
        color="inherit"
        sx={{ textDecoration: "none" }}
        component={RouterLink}
      >
        <ArrowForwardIcon className={classes.icon} />
      </Link>
    </Stack>
  );
}

export default function ActiveProject() {
  const classes = useStyles();
  const allProjectData = useSelector(({ project }) => project.projects);

  const allActiveProjects = allProjectData.filter(
    (project) => project.project_status === projectStatus.active
  );

  return (
    <Card sx={{ boxShadow: "none", border: "1px solid #E4E8EB" }}>
      <CardHeader
        title="Active Projects"
        className={classes.card_head}
        action={
          <Box
            sx={{
              float: "right",
              display: "inline-block",
              backgroundColor: "#f6f6f6",
              borderRadius: "8px",
              padding: "5px",
            }}
          >
            <Button
              to="/dashboard/projects"
              size="small"
              color="inherit"
              component={RouterLink}
              endIcon={<Icon icon={arrowIosForwardFill} />}
            >
              View all
            </Button>
          </Box>
        }
      />

      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {allActiveProjects.length === 0 ? (
            <SearchNotFound
              src="/static/No-found/project.png"
              title=""
              subtitle="No projects created yet."
            />
          ) : (
            allActiveProjects.map((news) => (
              <NewsItem key={news.title} news={news} />
            ))
          )}
        </Stack>
      </Scrollbar>

      <Divider />
    </Card>
  );
}
