import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import Element from "./../Form/Element";
import { inputType } from "../../utils/enum";
import { Formik } from "formik";
import SendIcon from "@mui/icons-material/Send";
import { green } from "@mui/material/colors";
import { ticketValidations } from "../../utils/validation";
import "../Form/upload.css";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const TicketModal = ({ onSubmit }) => {
  return (
    <>
      <Formik
        initialValues={{ title: "", description: "", files: [] }}
        onSubmit={onSubmit}
        validationSchema={ticketValidations}
      >
        {({
          values,
          handleChange,
          handleBlur,
          touched,
          handleSubmit,
          errors,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ marginTop: "20px" }}>
              <Element
                eletype={inputType.input}
                placeholder="Title"
                value={values.title}
                inputProps={{
                  name: "title",
                  onChange: handleChange,
                  onBlur: handleBlur,
                }}
                errorText={touched.title && errors.title}
                styles={{
                  backgroundColor: "#fff",
                  border: "1px solid #ced4da",
                }}
              />
            </Box>

            <Box sx={{ marginTop: "-20px" }}>
              <Element
                eletype={inputType.textarea}
                placeholder="Description"
                value={values.description}
                inputProps={{
                  name: "description",
                  onChange: handleChange,
                  onBlur: handleBlur,
                }}
                errorText={touched.description && errors.description}
                styles={{ marginBottom: 0, marginTop: 0 }}
              />
            </Box>
            <Box sx={{ marginTop: "20px", marginBottom: "20px" }}>
              <Element
                eletype={inputType.file}
                label={
                  <Typography variant="body1">
                    <AttachFileIcon
                      sx={{ fontSize: "1rem", transform: "rotate(45deg)" }}
                    />
                    Attach File
                  </Typography>
                }
                inputProps={{
                  name: "files",
                  onChange: handleChange,
                  onBlur: handleBlur,
                }}
                errorText={touched.files && errors.files}
              />
            </Box>

            <Stack
              direction="row"
              spacing={2}
              sx={{ display: "block", textAlign: "center" }}
            >
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                sx={{
                  background: green[500],
                  ":hover": { background: green[300] },
                }}
              >
                Raise Ticket
              </Button>
            </Stack>
          </form>
        )}
      </Formik>
    </>
  );
};

export default TicketModal;
