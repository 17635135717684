import React from "react";

import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Stack,
  Avatar,
  Paper,
} from "@mui/material";
import clsx from "clsx";
import DescriptionIcon from "@mui/icons-material/Description";
import MyDropzone from "../../../../components/_dashboard/app/DropZone";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { DateTime } from "luxon";
import { useStyles } from "./Overview.theme";
import { useDispatch, useSelector } from "react-redux";
import { uploadProjectAttachmentThunk } from "../../../../store/slices/project/project.slice";
import { useParams } from "react-router-dom";
import { capitalCase } from "../../../../utils/change-case";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ScrollToTop from "../../../../components/ScrollToTop";
import OverViewLoader from "../../../../components/Skeletons/OverViewLoader";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

function OverView() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const fileList = useSelector(
    ({ project }) => project.selectedProject.overview.attachments
  );
  function addAttachment(file) {
    const formData = new FormData();
    file.file.forEach((ele) => {
      formData.append("file", ele);
    });

    dispatch(
      uploadProjectAttachmentThunk({
        id,
        file: formData,
        media_type: file.ext,
      })
    );
  }

  function getFileIcon(ext) {
    ext = ext.toLowerCase();
    if (["jpg", "png", "gif", "jpeg"].includes(ext))
      return <PhotoLibraryIcon />;
    if (["pdf"].includes(ext)) return <PictureAsPdfIcon />;
    if (["doc", "docx"].includes(ext)) return <DescriptionIcon />;
  }

  const { overview } = useSelector(({ project }) => project.selectedProject);

  function getInitials(project_name) {
    if (project_name?.toLowerCase() === "crm") return project_name;
    return capitalCase(project_name.substring(0, 2));
  }

  const classes = useStyles();
  return (
    <>
      <ScrollToTop />
      <Grid item xs={12} sm={12} md={12}>
        <Card mb={5}>
          <CardContent>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <Typography
                gutterBottom
                variant="h5"
                sx={{ marginBottom: 0, fontSize: { xs: "16px", sm: "16px" } }}
              >
                Project Details
              </Typography>
              <Box
                component="span"
                display="inline"
                className={clsx(classes.badge_blue, classes.badge)}
                sx={{ fontSize: { xs: "16px", sm: "16px" } }}
              >
                {capitalCase(overview?.project_status)}
              </Box>
            </Stack>
            <Typography
              variant="body1"
              sx={{
                color: "#a4b0be",
                fontWeight: "600",
                paddingBottom: "10px",
              }}
            >
              Description
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
                textAlign: "justify",
                fontSize: { xs: "14px", sm: "14px" },
              }}
            >
              {overview?.description}
            </Typography>

            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#a4b0be",
                    fontWeight: "600",
                    paddingBottom: "10px",
                  }}
                  mt={2}
                >
                  Services
                </Typography>
              </Grid>
              <Grid xs={12}>
                <Stack direction="row" mb={1} spacing={1}>
                  {overview?.services?.map((item) => {
                    return (
                      <Paper
                        sx={{
                          // background: "#f3f6f8",
                          color: "text.secondary",
                          borderRadius: "5px",
                          border: "2px solid #f3f6f8",
                          fontWeight: "400",
                          fontSize: "0.875rem",
                          lineHeight: 1.43,
                          letterSpacing: "0.01071em",
                          padding: "8px",
                          textAlign: "center",
                        }}
                      >
                        {item?.service_name}
                      </Paper>
                    );
                  })}
                </Stack>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={6} md={12}>
                <Box
                  component="div"
                  mt={3}
                  display="flex"
                  sx={{
                    flexDirection: "row",
                  }}
                  className={classes.dateInMobile}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{ width: "300px" }}
                    justifyContent="space-start"
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: { xs: "16px" },
                        color: "#a4b0be",
                        fontWeight: "600",
                      }}
                    >
                      <AccessTimeIcon sx={{ fontSize: "14px" }} /> Created Date:
                    </Typography>
                    <Typography
                      variant="body2"
                      ml={1}
                      sx={{
                        paddingRight: "1rem",
                        color: "text.secondary",
                        fontSize: { xs: "14px" },
                      }}
                    >
                      {" "}
                      {DateTime.fromISO(overview?.created_at).toFormat("DD")}
                    </Typography>
                  </Stack>

                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{ width: "300px" }}
                    justifyContent="space-start"
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: { xs: "16px" },
                        color: "#a4b0be",
                        fontWeight: "600",
                      }}
                    >
                      <AccessTimeIcon sx={{ fontSize: "14px" }} /> Due Date:
                    </Typography>
                    <Typography
                      variant="body2"
                      ml={1}
                      sx={{ color: "text.secondary", fontSize: { xs: "14px" } }}
                    >
                      {" "}
                      {overview.expected_closing_date
                        ? DateTime.fromISO(
                            overview.expected_closing_date
                          ).toFormat("DD")
                        : "-"}
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
            <Typography
              variant="body1"
              display="block"
              mt={5}
              sx={{
                fontSize: { xs: "16px" },
                color: "#a4b0be",
                fontWeight: "600",
              }}
            >
              Attachment
            </Typography>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{ margin: "20px 50px 50px 0" }}
              >
                <MyDropzone addAttachment={addAttachment} />
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              {fileList &&
                fileList.map((file, id) => (
                  <Grid item key={id} xs={12} sm={6} md={4}>
                    <Box component="div" className={classes.attachFile}>
                      <Box component="div" className={classes.attachMedia}>
                        <Box component="span" className={classes.attachIcon}>
                          {getFileIcon("png")}
                        </Box>
                        <Box
                          component="div"
                          ml={2}
                          sx={{ position: "relative" }}
                        >
                          <a
                            href={file.url}
                            target="blank"
                            style={{ textDecoration: "none" }}
                          >
                            <Typography
                              variant="body2"
                              component="h4"
                              className={classes.attachFileHeading}
                              sx={{ fontWeight: "600" }}
                            >
                              {file.filename}
                            </Typography>
                          </a>

                          <Typography
                            variant="caption"
                            sx={{ color: "text.secondary" }}
                          >
                            {DateTime.fromISO(file.timestamp).toFormat("DD")}
                          </Typography>
                          {/* <Box
                        varient="span"
                        component="span"
                        className={classes.closeFile}
                      >
                        <HighlightOffIcon sx={{ fontSize: "17px" }} />
                      </Box> */}
                        </Box>
                        <Box>
                          <LightTooltip title={file.name} placement="top" arrow>
                            <Avatar
                              sx={{
                                fontSize: ".8rem",
                                fontWeight: "600",
                                backgroundColor: "#f3f6f8",
                                color: "#637281",
                              }}
                            >
                              {getInitials(file.name)}
                            </Avatar>
                          </LightTooltip>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </CardContent>
        </Card>
        {/* <OverViewLoader /> */}
      </Grid>
    </>
  );
}

export default OverView;
