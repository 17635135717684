import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import useModal from "../../hooks/useModal";
import Modal from "../../components/Modal/Modal";
// material
import { Stack, Button, Divider, Typography } from "@mui/material";
import MobileInput from "../MobileInput";
import { Formik } from "formik";
import { mobileValidation } from "../../utils/validation";
import { signInWithPhoneAsycnThunk } from "../../store/slices/auth/auth.slice";
import { useDispatch } from "react-redux";

// ----------------------------------------------------------------------

export default function AuthMobile() {
  const { isModalOpen, showModal, hideModal } = useModal();
  const dispatch = useDispatch();

  function handleSubmit(data) {
    dispatch(signInWithPhoneAsycnThunk({ ...data.mobile }));
  }

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        closeModal={hideModal}
        contentStyle={{ height: "600px", width: "500px" }}
        content={
          <div>
            <Formik
              initialValues={{
                mobile: "",
              }}
              validationSchema={mobileValidation}
              onSubmit={handleSubmit}
            >
              {({
                values,
                handleChange,
                handleBlur,
                touched,
                errors,
                handleSubmit,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div>
                      <div>
                        <img
                          src="/static/vectors/otp.svg"
                          alt="user-icon"
                          style={{ paddingBottom: "30px" }}
                        />
                      </div>
                      <MobileInput
                        label="Mobile Number"
                        name="mobile"
                        value={values.mobile}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorText={
                          touched.mobile && errors.mobile?.mobile_number
                        }
                      />
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <Button
                        size="large"
                        type="submit"
                        variant="contained"
                        sx={{ mt: 2, width: "50%" }}
                      >
                        Login
                      </Button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        }
      />
      <Stack direction="row" spacing={2}>
        <Button
          variant="outlined"
          onClick={showModal}
          sx={{
            width: "100%",
            color: "rgba(0, 0, 0, 0.54)",
            fontFamily: "Roboto, sans-serif",
            fontSize: "14px",
            fontWeight: "500",
            border: "1px solid rgba(0, 0, 0, .125)",
            borderRadius: "0",
            height: "50px",
            "&:hover": {
              background: "none",
              border: "1px solid rgba(0, 0, 0, .125)",
            },
          }}
        >
          <PhoneAndroidIcon sx={{ color: "#222", mr: 2 }} />
          {/* <img
            src="https://cdn-icons-png.flaticon.com/512/1773/1773508.png"
            width="30px"
          /> */}
          Login With Mobile
        </Button>
      </Stack>
      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          Or Sign in with Email
        </Typography>
      </Divider>
    </>
  );
}
