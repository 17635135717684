import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function AppWeeklyLoader() {
  return (
    <SkeletonTheme baseColor="#edf2f9" highlightColor="#dde6f0" duration={0.7}>
      <div
        className="flex"
        style={{
          position: "relative",
          borderRadius: "10px",
          padding: "30px 30px",
          background: "#f3f6f8",
          height: "250px",
        }}
      >
        <div>
          <Skeleton
            style={{
              backgroundColor: "#edf2f9",
              display: "flex",
              width: "40%",
              borderRadius: "5px",
              height: "4rem",
            }}
          />
          <Skeleton
            style={{
              backgroundColor: "#edf2f9",
              display: "flex",
              width: "50%",
              borderRadius: "5px",
              height: "2rem",
            }}
          />
          <Skeleton
            style={{
              backgroundColor: "#edf2f9",
              display: "flex",
              width: "20%",
              borderRadius: "5px",
              height: "3rem",
            }}
          />
        </div>
        <div>
          <Skeleton
            style={{
              position: "absolute",
              top: 30,
              right: 40,
              backgroundColor: "#edf2f9",
              display: "flex",
              width: "40%",
              borderRadius: "5px",
              height: "12rem",
            }}
          />
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default AppWeeklyLoader;
